export default {
  namespaced: true,
  state: () => ({
    jobData: null,
    jobSpotList: []
  }),
  getters: {
    jobData: state => state.jobData,
    jobSpotList: state => state.jobSpotList,
    files: state => state.jobData?.files || [],
    isFilesHasBleed: (state, getters) => getters.files?.some(f => f?.preflight_info?.bleed),
    detectedBleed: (state, getters) => getters.files?.[0]?.preflight_info?.bleed || getters.files?.find(file => Boolean(file?.preflight_info?.bleed))?.preflight_info?.bleed,
  },
  mutations: {
    SET_MAESTRO_JOB (state, jobData) {
      state.jobData = jobData
      if(jobData) {
        state.jobSpotList = jobData?.files?.reduce((acc,file) => [...acc, ...(Boolean(file.pantone) && !acc.includes(file.pantone)) ? [...file.pantone?.split?.(';')] : []], []) || []
      } else {
        state.jobSpotList = []
      }
    },
    UPDATE_JOB_FILE (state, fileData) {
      const file = state.jobData?.files.find(f => f.id === fileData.id)

      if(!file) return

      Object.assign(file, fileData)

      state.jobSpotList = state.jobData?.files?.reduce((acc,file) => [...acc, ...Boolean(file.pantone) ? [...file.pantone?.split?.(';')] : []], []) || []
    },
    ADD_JOB_FILES (state, files) {
      state.jobData.files.push(...files)

      state.jobSpotList = state.jobData?.files?.reduce((acc,file) => [...acc, ...Boolean(file.pantone) ? [...file.pantone?.split?.(';')] : []], []) || []
    }
  }
}
