export default {
  namespaced: true,
  state: {
    endpoint: "/accounting/currency_rates",
    key: "accounting_currency_rate",
    fetchKey: "currency_rates", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: ""
  },
  mutations: {},
  actions: {},
  getters: {}
};
