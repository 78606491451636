import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/inventory/products/order_advisor",
    order_request_status_list: [
      {
        id: "active",
        name: "active",
        label: i18n.t("pages.inventory.products.order_request_status_list.active"),
        text: i18n.t("pages.inventory.products.order_request_status_list.active"),
        default: true
      },
      {
        id: "completed",
        name: "completed",
        label: i18n.t("pages.inventory.products.order_request_status_list.completed"),
        text: i18n.t("pages.inventory.products.order_request_status_list.completed"),
        default: false
      },
      {
        id: "ordered",
        name: "ordered",
        label: i18n.t("pages.inventory.products.order_request_status_list.ordered"),
        text: i18n.t("pages.inventory.products.order_request_status_list.ordered"),
        default: false
      }
    ]
  },
  getters: {
    orderRequestStatusList: state => state.order_request_status_list
  }
};
