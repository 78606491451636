import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/accounting/invoices/",
    taxes_endpoint: "/accounting/taxes/get_tax_rate",
    key: "accounting_invoice",
    fetchKey: "invoices", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: "",
    typeList: [
      {
        name: "final",
        id: "final",
        text: i18n.t("pages.accounting.invoices.typeList.final"),
        label: i18n.t("pages.accounting.invoices.typeList.final")
      },
      {
        name: "proforma",
        id: "proforma",
        text: i18n.t("pages.accounting.invoices.typeList.proforma"),
        label: i18n.t("pages.accounting.invoices.typeList.proforma")
      },
      {
        name: "quote",
        id: "quote",
        text: i18n.t("pages.accounting.invoices.typeList.quote"),
        label: i18n.t("pages.accounting.invoices.typeList.quote")
      }
    ],
    tradeTypeList: [
      {
        name: "sale",
        id: "sale",
        text: i18n.t("pages.accounting.invoices.tradeTypeList.sale"),
        label: i18n.t("pages.accounting.invoices.tradeTypeList.sale")
      },
      {
        name: "purchase",
        id: "purchase",
        text: i18n.t("pages.accounting.invoices.tradeTypeList.purchase"),
        label: i18n.t("pages.accounting.invoices.tradeTypeList.purchase")
      }
    ],
    invoicedStatusList: [
      {
        name: "completed",
        id: "completed",
        text: i18n.t("pages.accounting.invoices.invoicedStatusList.completed"),
        color: "success",
        label: i18n.t("pages.accounting.invoices.invoicedStatusList.completed")
      },
      {
        name: "partial",
        id: "partial",
        text: i18n.t("pages.accounting.invoices.invoicedStatusList.partial"),
        label: i18n.t("pages.accounting.invoices.invoicedStatusList.partial"),
        color: "info"
      },
      {
        name: "none",
        id: "none",
        text: i18n.t("pages.accounting.invoices.invoicedStatusList.none"),
        label: i18n.t("pages.accounting.invoices.invoicedStatusList.none"),
        color: "info"
      },
      {
        name: "any",
        id: "any",
        text: i18n.t("pages.accounting.invoices.invoicedStatusList.any"),
        label: i18n.t("pages.accounting.invoices.invoicedStatusList.any"),
        color: "info"
      }
    ],
    statusList: [
      {
        name: "completed",
        id: "completed",
        text: i18n.t("pages.accounting.invoices.statusList.completed"),
        color: "success",
        label: i18n.t("pages.accounting.invoices.statusList.completed"),
        allow_to_change: false,
        available_for: [
          "accounting_invoice_final_sale",
          "accounting_invoice_proforma_sale",
          "accounting_invoice_final_purchase",
          "accounting_invoice_proforma_purchase"
        ]
      },
      {
        name: "pending",
        id: "pending",
        text: i18n.t("pages.accounting.invoices.statusList.pending"),
        color: "warning",
        label: i18n.t("pages.accounting.invoices.statusList.pending"),
        allow_to_change: true,
        available_for: [
          "accounting_invoice_final_sale",
          "accounting_invoice_proforma_sale",
          "accounting_invoice_final_purchase",
          "accounting_invoice_proforma_purchase"
        ]
      },
      {
        name: "pending_approval",
        id: "pending_approval",
        text: i18n.t("pages.accounting.invoices.statusList.pending_approval"),
        color: "outline-warning",
        label: i18n.t("pages.accounting.invoices.statusList.pending_approval"),
        allow_to_change: false,
        available_for: [
          "accounting_invoice_final_sale",
          "accounting_invoice_proforma_sale",
          "accounting_invoice_final_purchase",
          "accounting_invoice_proforma_purchase"
        ]
      },
      {
        name: "active",
        id: "active",
        text: i18n.t("pages.accounting.invoices.statusList.active"),
        color: "primary",
        label: i18n.t("pages.accounting.invoices.statusList.active"),
        allow_to_change: true,
        available_for: [
          "accounting_invoice_final_sale",
          "accounting_invoice_proforma_sale",
          "accounting_invoice_final_purchase",
          "accounting_invoice_proforma_purchase",
          "accounting_invoice_quote_sale"
        ]
      },
      {
        name: "draft",
        id: "draft",
        text: i18n.t("pages.accounting.invoices.statusList.draft"),
        color: "light",
        label: i18n.t("pages.accounting.invoices.statusList.draft"),
        allow_to_change: false,
        available_for: [
          "accounting_invoice_final_sale",
          "accounting_invoice_proforma_sale",
          "accounting_invoice_quote_sale",
          "accounting_invoice_final_purchase",
          "accounting_invoice_proforma_purchase",
        ]
      },
      {
        name: "cancelled",
        id: "cancelled",
        text: i18n.t("pages.accounting.invoices.statusList.cancelled"),
        color: "light",
        label: i18n.t("pages.accounting.invoices.statusList.cancelled"),
        allow_to_change: true,
        available_for: [
          "accounting_invoice_proforma_sale",
          "accounting_invoice_proforma_purchase"
        ]
      },
      {
        name: "rejected",
        id: "rejected",
        text: i18n.t("pages.accounting.invoices.statusList.rejected"),
        color: "danger",
        label: i18n.t("pages.accounting.invoices.statusList.rejected"),
        available_for: ["accounting_invoice_quote_sale"]
      },
      {
        name: "accepted",
        id: "accepted",
        text: i18n.t("pages.accounting.invoices.statusList.accepted"),
        color: "success",
        label: i18n.t("pages.accounting.invoices.statusList.accepted"),
        available_for: ["accounting_invoice_quote_sale"]
      },
      {
        name: "karavan_at_cart",
        id: "karavan_at_cart",
        text: i18n.t("pages.accounting.invoices.statusList.karavan_at_cart"),
        color: "karavan_at_cart",
        label: i18n.t("pages.accounting.invoices.statusList.karavan_at_cart"),
        allow_to_change: false,
        available_for: [
          "accounting_invoice_proforma_sale",
        ]
      },
      {
        name: "all",
        id: "all",
        text: i18n.t("pages.accounting.invoices.statusList.all"),
        color: "warning",
        label: i18n.t("pages.accounting.invoices.statusList.all"),
        allow_to_change: false,
        available_for: [
          "accounting_invoice_final_sale",
          "accounting_invoice_proforma_sale",
          "accounting_invoice_final_purchase",
          "accounting_invoice_proforma_purchase",
          "accounting_invoice_quote_sale"
        ]
      },
    ],
    statusPaymentList: [
      {
        name: "pending",
        id: "pending",
        text: i18n.t("pages.accounting.invoices.statusPaymentList.pending"),
        label: i18n.t("pages.accounting.invoices.statusPaymentList.pending"),
        color: "danger"
      },
      {
        name: "fully",
        id: "fully",
        text: i18n.t("pages.accounting.invoices.statusPaymentList.fully"),
        label: i18n.t("pages.accounting.invoices.statusPaymentList.fully"),
        color: "success"
      },
      {
        name: "processed",
        id: "processed",
        text: i18n.t("pages.accounting.invoices.statusPaymentList.processed"),
        label: i18n.t("pages.accounting.invoices.statusPaymentList.processed"),
        color: "success"
      },
      {
        name: "partial",
        id: "partial",
        text: i18n.t("pages.accounting.invoices.statusPaymentList.partial"),
        label: i18n.t("pages.accounting.invoices.statusPaymentList.partial"),
        color: "warning"
      },
      {
        name: "failed",
        id: "failed",
        text: i18n.t("pages.accounting.invoices.statusPaymentList.failed"),
        label: i18n.t("pages.accounting.invoices.statusPaymentList.failed"),
        color: "danger"
      },
    ],
    amountTypeList: [
      {
        name: "percentage",
        id: "percentage",
        text: i18n.t("pages.accounting.invoices.amountTypeList.percentage"),
        label: i18n.t("pages.accounting.invoices.amountTypeList.percentage")
      },
      {
        name: "fixed",
        id: "fixed",
        text: i18n.t("pages.accounting.invoices.amountTypeList.fixed"),
        label: i18n.t("pages.accounting.invoices.amountTypeList.fixed")
      }
    ],
    approvalRequiredStatusList: [
      {
        name: "none",
        id: "none",
        text: i18n.t("pages.accounting.invoices.approvalRequiredStatusList.none"),
        label: i18n.t("pages.accounting.invoices.approvalRequiredStatusList.none")
      },
      {
        name: "customer_balance_limit",
        id: "customer_balance_limit",
        text: i18n.t("pages.accounting.invoices.approvalRequiredStatusList.customer_balance_limit"),
        label: i18n.t("pages.accounting.invoices.approvalRequiredStatusList.customer_balance_limit"),
        permission_key: "approve_from_balance_limit"
      },
      {
        name: "any",
        id: "any",
        text: i18n.t("pages.accounting.invoices.approvalRequiredStatusList.any"),
        label: i18n.t("pages.accounting.invoices.approvalRequiredStatusList.any")
      },
      {
        name: "approved",
        id: "approved",
        text: i18n.t("pages.accounting.invoices.approvalRequiredStatusList.approved"),
        label: i18n.t("pages.accounting.invoices.approvalRequiredStatusList.approved")
      },
    ],
  },
  mutations: {},
  actions: {},
  getters: {
    typeList: state => state.typeList,
    tradeTypeList: state => state.tradeTypeList,
    statusList: state => state.statusList,
    statusPaymentList: state => state.statusPaymentList,
    approvalRequiredStatusList: state => state.approvalRequiredStatusList,
    amountTypeList: state => state.amountTypeList,
    invoicedStatusList: state => state.invoicedStatusList,
    changeableStatuses: state => state.statusList.filter(s => s.allow_to_change)
  }
};
