<template>
  <div class="position-absolute card-top-buttons">
    <button
      v-if="wo_today_only_button === true"
      variant="primary"
      @click="changeTodayToggle"
      :delay="200"
      class="btn btn-header-light icon-button"
      :class="{ wo_today_togggle_button: wo_today_only_state }"
    >
      <i class="fa fa-calendar" />
    </button>
    <button v-if="searchIcon === true" class="btn btn-header-light icon-button" @click="$emit('searchEvent', true)">
      <i class="fa fa-search" />
    </button>
    <button class="btn btn-header-light icon-button" @click="$emit('click')">
      <i class="fa" :class="{ ['fa-' + icon]: true }" />
    </button>
  </div>
</template>
<script>
export default {
  props: {
    icon: {
      required: false,
      default: "refresh"
    },
    searchIcon: {
      required: false,
      default: false
    },
    wo_today_only_button: {
      required: false,
      default: false
    },
    wo_today_only_initial_value: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      wo_today_only_state: false
    };
  },
  created() {
    this.wo_today_only_state = this.wo_today_only_initial_value;
  },
  methods: {
    changeTodayToggle() {
      this.wo_today_only_state = !this.wo_today_only_state;
      this.$emit("todayOnlyEvent", this.wo_today_only_state);
    }
  }
};
</script>
<style scoped>
.wo_today_togggle_button {
  border-color: #d7d7d7;
  color: #212529;
  text-decoration: none;
  background-color: transparent;
}
</style>
