import { i18n } from "@/utils/i18n";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    endpoint: "/custom/fields/",
    key: "custom_field",
    fetchKey: "custom_fields",
    inputTypes: [
      {
        name: "textbox",
        text: i18n.t("pages.custom_fields.inputTypeList.textbox")
      },
      {
        name: "dropdownlist",
        text: i18n.t("pages.custom_fields.inputTypeList.dropdownlist")
      },
      {
        name: "smart_ddl",
        text: i18n.t("pages.custom_fields.inputTypeList.smart_ddl")
      },
      {
        name: "checkbox",
        text: i18n.t("pages.custom_fields.inputTypeList.checkbox")
      },
      {
        name: "text",
        text: i18n.t("pages.custom_fields.inputTypeList.text")
      },
      {
        name: "calendar",
        text: i18n.t("pages.custom_fields.inputTypeList.calendar")
      },
      {
        name: "radio_button",
        text: i18n.t("pages.custom_fields.inputTypeList.radio_button")
      },
      {
        name: "inventory_product",
        text: i18n.t("pages.custom_fields.inputTypeList.inventory_product")
      },
      {
        name: "contact_customer",
        text: i18n.t("pages.custom_fields.inputTypeList.contact_customer")
      },
      {
        name: "contact_supplier",
        text: i18n.t("pages.custom_fields.inputTypeList.contact_supplier")
      },
      {
        name: "calculator",
        text: i18n.t("pages.custom_fields.inputTypeList.calculator")
      }
    ],
    inputValidators: [
      {
        name: "none",
        text: i18n.t("pages.custom_fields.inputValidatorList.none")
      },
      {
        name: "email",
        text: i18n.t("pages.custom_fields.inputValidatorList.email")
      },
      {
        name: "integer",
        text: i18n.t("pages.custom_fields.inputValidatorList.integer")
      },
      {
        name: "number",
        text: i18n.t("pages.custom_fields.inputValidatorList.number")
      }
    ],
    typeInventoryProductList: [
      {
        name: "dropdownlist",
        text: i18n.t("pages.custom_fields.typeInventoryProductList.dropdownlist")
      },
      {
        name: "search",
        text: i18n.t("pages.custom_fields.typeInventoryProductList.search")
      }
    ],
    smartDDLTypeList: [
      {
        name: "dropdownlist",
        text: i18n.t("pages.custom_fields.smartDDLTypeList.dropdownlist")
      },
      {
        name: "multi_select",
        text: i18n.t("pages.custom_fields.smartDDLTypeList.multi_select")
      },
      {
        name: "multi_select_checkbox",
        text: i18n.t("pages.custom_fields.smartDDLTypeList.multi_select_checkbox")
      },
      {
        name: "radio_button",
        text: i18n.t("pages.custom_fields.smartDDLTypeList.radio_button")
      }
    ],
    smartDDLValueTypeList: [
      {
        name: "text",
        text: i18n.t("pages.custom_fields.smartDDLValueTypeList.text")
      },
      {
        name: "float",
        text: i18n.t("pages.custom_fields.smartDDLValueTypeList.float")
      }
    ],
    calculatorResultTypeList: [
      {
        name: "number",
        text: i18n.t("pages.custom_fields.calculatorResultTypeList.number")
      },
      {
        name: "currency",
        text: i18n.t("pages.custom_fields.calculatorResultTypeList.currency")
      },
      {
        name: "measurement",
        text: i18n.t("pages.custom_fields.calculatorResultTypeList.measurement")
      }
    ],
    calculator_round: [
      {
        name: "none",
        text: i18n.t("pages.custom_fields.calculator_round.none")
      },
      {
        name: "normal",
        text: i18n.t("pages.custom_fields.calculator_round.normal")
      },
      {
        name: "up",
        text: i18n.t("pages.custom_fields.calculator_round.up")
      },
      {
        name: "down",
        text: i18n.t("pages.custom_fields.calculator_round.down")
      }
    ],
    checkboxStyleList: [
      {
        name: "checkbox",
        text: i18n.t("pages.custom_fields.checkboxStyleList.checkbox")
      },
      {
        name: "toggle",
        text: i18n.t("pages.custom_fields.checkboxStyleList.toggle")
      }
    ],
    compareToTypeList: [
      {
        name: "none",
        text: i18n.t("pages.custom_fields.compareToTypeList.none")
      },
      {
        name: "custom_field",
        text: i18n.t("pages.custom_fields.compareToTypeList.custom_field")
      }
    ],

    activeInputType: "",
    activeInputValidator: "",
    permissionKey: "",

    customFieldItems: {}
  },
  mutations: {
    setActiveInputType(state, activeInputType) {
      let index = state.inputTypes.findIndex(it => {
        return it.name == activeInputType;
      });

      if (index > -1) {
        state.activeInputType = state.inputTypes[index];
      }
    },
    setActiveInputValidator(state, activeInputValidator) {
      let index = state.inputValidators.findIndex(iv => {
        return iv.name == activeInputValidator;
      });

      if (index > -1) {
        state.activeInputValidator = state.inputValidators[index];
      }
    },
    setCustomFieldItems(state, customFieldItems) {
      state.customFieldItems = customFieldItems;
    },
    setInit(state, isInit) {
      state.isCustomFieldsInit = isInit;
    }
  },
  actions: {
    fetchCustomField({ commit, dispatch, state }, cfId) {
      if (state.customFieldItems?.[cfId] && moment().unix() < state.customFieldItems[cfId].expire) {
        return Promise.resolve(state.customFieldItems?.[cfId])
      } else {
        return dispatch('fetchOne', {
          endpoint: state.endpoint,
          key: state.key,
          id: cfId
        }, { root: true }).then(custom_field => {
          commit('setCustomFieldItems', {
            ...state.customFieldItems,
            [cfId]: {
              ...custom_field,
              expire: moment().add(7, 'days').unix()
            },
          })

          return custom_field
        })
      }
    }
  },
  getters: {
    inputTypeList: state => state.inputTypes,
    inputValidatorList: state => state.inputValidators,
    typeInventoryProductList: state => state.typeInventoryProductList,
    smartDDLTypeList: state => state.smartDDLTypeList,
    smartDDLValueTypeList: state => state.smartDDLValueTypeList,
    checkboxStyleList: state => state.checkboxStyleList,
    calculatorResultTypeList: state => state.calculatorResultTypeList,
    compareToTypeList: state => state.compareToTypeList,
    calculatorRoundList: state => state.calculator_round
  }
};
