<script>
import { validationMixin } from "vuelidate";
import { cloneDeep, get, set } from 'lodash'

export default {
  name: "PFormBuilder",
  mixins: [validationMixin],
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Array,
      default: []
    },
    defaultColSize: {
      type: Number,
      default: 6
    },
    validateOnBlur: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: undefined
    }
  },
  watch: {
    '$v.value': {
      handler(v) {
        this.$emit('update:validations', v)
        this.$emit('update:isValid', !v.$invalid)
        this.$emit('update:isInvalid', v.$invalid)
        this.isValid = !v.$invalid
        this.isInvalid = v.$invalid
      },
      deep: true
    }
  },
  data() {
    return {
      isValid: false,
      isInvalid: true
    };
  },
  validations() {
    const validationsObj = {}
    this.fields.forEach(field => {
      if(Boolean(field.validations) && (!field.hasOwnProperty('hidden') || !field.hidden)) {
        set(validationsObj, field.id, field.validations)
      }
    })

    return {
      value: validationsObj
    }
  },
  methods: {
    fieldScopedSlots(field) {
      return Object.keys(this.$scopedSlots)
        .reduce((acc, key) => ({
          ...acc,
          ...key.startsWith(`${ field.id }-`) && {
            [key.slice(field.id.length + 1)]: this.$scopedSlots[key]
          }
      }), [])
    },
    fieldIsDefault(field) {
      return !field.custom
    },
    getFieldValidation(field) {
      return get(this.$v.value, field.id, {})
    },
    fieldGroupBind(field) {
      return {
        ...(this.getFieldValidation(field)?.$anyDirty) && {
          state: !this.getFieldValidation(field)?.$invalid
        },
        ...field.group,
      }
    },
    fieldCols(field) {
      return {
        cols: this.defaultColSize,
        ...field.cols
      }
    },
    fieldBind(field) {
      return {
        ...(this.getFieldValidation(field)?.$anyDirty) && {
          state: !this.getFieldValidation(field)?.$invalid
        },
        value: get(this.value, field.id, null),
        ...field.bind,
      }
    },
    fieldOn(field) {
      return {
        'input': e => {
          const newValue = cloneDeep(this.value)
          set(newValue, field.id, e)
          this.$emit('input', newValue)
        },
        ...field.on
      }
    },
    fieldOnBlur(field) {
      if(this.validateOnBlur && this.getFieldValidation(field)) this.$v.value[field.id].$touch()
    },
    fieldInvalidFeedback(field) {
      const params = {}
      Object.keys(this.getFieldValidation(field)?.$params || {}).forEach(key => {
        set(params, key, this.getFieldValidation(field)?.[key])
      })


      if(Object.keys(params).length && this.getFieldValidation(field)?.$anyDirty) {
        const param = Object.keys(params).filter(k => !params[k])[0]
        return this.$t(`validationErrors.${param}`, this.getFieldValidation(field)?.$params?.[param])
      }

      return ''
    },
    fieldIsShow(field) {
      return !field.hasOwnProperty('hidden') || !field.hidden
    }
  },
  mounted() {
    this.$root.$on('p::form::validate', (e) => {
      if(!e) this.$v.value.$touch()
      if(e && e === this.id) this.$v.value.$touch()
    })
  }
};
</script>

<template>
  <b-row>
    <template v-for="(field, index) in fields">
      <b-col :key="`field-${field.id}-${index}`" v-bind="fieldCols(field)" v-if="fieldIsShow(field)">
        <slot :name="`field(${field.id})`">
          <b-form-group
            v-if="fieldIsDefault(field)"
            :label="field.label"
            v-bind="fieldGroupBind(field)"
            :invalid-feedback="fieldInvalidFeedback(field)"
          >
            <component
              :is="field.component"
              v-bind="fieldBind(field)"
              v-on="fieldOn(field)"
              @blur="fieldOnBlur(field)"
            >
              <template v-for="(_, slotName) in fieldScopedSlots(field)" v-slot:[slotName]="slotProps">
                <slot :name="`${field.id}-${slotName}`" v-bind="slotProps" />
              </template>
            </component>
          </b-form-group>
          <template v-else>
            <component
              :is="field.component"
              v-model="value[field.id]"
              v-bind="field.bind"
              v-on="field.on"
            >
              <template v-for="(_, slotName) in fieldScopedSlots(field)" v-slot:[slotName]="slotProps">
                <slot :name="`${field.id}-${slotName}`" v-bind="slotProps" />
              </template>
            </component>
          </template>
        </slot>
      </b-col>
    </template>
  </b-row>
</template>
