// import LocationListPage from "locations/LocationListPage";
// import LocationUpdatePage from "locations/LocationUpdatePage";
// import LocationNewPage from "locations/LocationNewPage";
const routes = [
  {
    path: "/locations",
    name: "LocationsListPage",
    meta: { listPage: "locations", listPageTitleKey: "locations" },
    component: () => import("@/views/app/pages/locations/list")
    // component: LocationListPage,
  },
  {
    name: "LocationsNewPage",
    path: "/locations/new",
    component: () => import("@/views/app/pages/locations/new")
    // component: LocationNewPage
  },
  {
    name: "LocationsUpdatePage",
    path: "/locations/:id",
    component: () => import("@/views/app/pages/locations/update")
    // component: LocationUpdatePage
  }
];

export default routes;
