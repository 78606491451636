import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/hive/b2b/users",
    key: "user",
    fetchKey: "users",
    preferredLanguageList: [
      {
        id: "en",
        label: i18n.t("pages.users.preferredLanguageList.en")
      },
      {
        id: "fr",
        label: i18n.t("pages.users.preferredLanguageList.fr")
      }
    ],
  },
  mutations: {
    setUsersNewResponse(state, payload) {
      state.usersNewResponse = payload;
    }
  },
  actions: {
  },
  getters: {
    preferredLanguageList: state => state.preferredLanguageList,
  }
};
