import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/accounting/credits/",
    key: "accounting_credit",
    fetchKey: "credits", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: "",
    status_list: [
      {
        name: "completed",
        id: "completed",
        text: i18n.t("pages.accounting.credits.status_list.completed"),
        label: i18n.t("pages.accounting.credits.status_list.completed"),
        color: "success",
      },
      {
        name: "pending",
        id: "pending",
        text: i18n.t("pages.accounting.credits.status_list.pending"),
        label: i18n.t("pages.accounting.credits.status_list.pending"),
        color: "primary",
      },
      {
        name: "all",
        id: "all",
        text: i18n.t("pages.accounting.credits.status_list.all"),
        label: i18n.t("pages.accounting.credits.status_list.all"),
        color: "all",
      },
    ],
  },
  mutations: {},
  actions: {},
  getters: {
    status_list: (state) => state.status_list,
  },
};
