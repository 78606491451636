import { i18n } from "@/utils/i18n";

const routes = [
  // Stores
  {
    name: "KaravanStoresListPage",
    path: "/karavan/stores",
    meta: {
      page: "stores",
      listPage: "karavan_stores",
      listPageTitleKey: "stores"
    },
    component: () => import("@/views/app/pages/karavan/stores/list")
  },
  {
    path: "/karavan/stores/new",
    component: () => import("@/views/app/pages/karavan/stores/new")
  },
  {
    path: "/karavan/stores/:id",
    component: () => import("@/views/app/pages/karavan/stores/update")
  },

  // Shipment Services
  {
    name: "Karavan,ShipmentServicesListPage",
    path: "/karavan/shipment_services",
    meta: {
      page: "stores",
      listPage: "karavan_shipment_services",
      listPageTitleKey: "shipment_services"
    },
    component: () => import("@/views/app/pages/karavan/shipment_services/list")
  },
  {
    path: "/karavan/shipment_services/new",
    component: () => import("@/views/app/pages/karavan/shipment_services/new")
  },
  {
    path: "/karavan/shipment_services/:id",
    component: () => import("@/views/app/pages/karavan/shipment_services/update")
  },

  {
    name: "KaravanOrdersListPage",
    path: "/karavan/orders",
    meta: { listPage: "orders", listPageTitleKey: "orders", title : i18n.t('menu.karavan_orders') },
    component: () => import("@/views/app/pages/karavan/orders/list")
  },
  {
    name: "KaravanOrdersUpdatePage",
    path: "/karavan/orders/:id",
    component: () => import("@/views/app/pages/karavan/orders/update")
  },
  {
    name: "KaravanFulfillItemsPage",
    path: "/karavan/orders/:id/fulfillment_orders",
    component: () => import("@/views/app/pages/karavan/orders/fulfill")
  }
];

export default routes;
