import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/custom/form_models/",
    key: "custom_form_model",
    fetchKey: "custom_form_models",
    attachToList: [
      {
        name: "logistic_fulfillment",
        text: i18n.t("pages.custom_form_models.attachToList.logistic_fulfillment")
      },
      {
        name: "logistic_shipment",
        text: i18n.t("pages.custom_form_models.attachToList.logistic_shipment")
      },
      {
        name: "logistic_transfer",
        text: i18n.t("pages.custom_form_models.attachToList.logistic_transfer")
      },
      {
        name: "accounting_invoice_sale_final",
        text: i18n.t("pages.custom_form_models.attachToList.accounting_invoice_sale_final")
      },
      {
        name: "accounting_invoice_sale_proforma",
        text: i18n.t("pages.custom_form_models.attachToList.accounting_invoice_sale_proforma")
      },
      {
        name: "accounting_invoice_purchase_final",
        text: i18n.t("pages.custom_form_models.attachToList.accounting_invoice_purchase_final")
      },
      {
        name: "accounting_invoice_purchase_proforma",
        text: i18n.t("pages.custom_form_models.attachToList.accounting_invoice_purchase_proforma")
      }
    ],
    permissionKey: ""
  },
  mutations: {},
  actions: {},
  getters: {
    attachToList: state => state.attachToList
  }
};
