const routes = [
  {
    path: "/hive/b2b/:customer/users",
    name: 'HiveB2BUsersListPage',
    meta: { listPage: "users", listPageTitleKey: "b2b_users" },
    component: () => import("@/views/app/pages/hive/b2b/users/list"),
  },
  {
    path: "/hive/b2b/:customer/users/new",
    name: 'HiveB2BUsersCreatePage',
    component: () => import("@/views/app/pages/hive/b2b/users/new"),
  },
  {
    path: "/hive/b2b/users/:id/edit",
    name: 'HiveB2BUsersUpdatePage',
    component: () => import("@/views/app/pages/hive/b2b/users/update"),
  },


];

export default routes;
