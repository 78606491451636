import { i18n } from "@/utils/i18n";

const routes = [
  /********** Layouts ************** */
  {
    name : "LamcomLayoutUpdatePage",
    path: "/hive/projects/work_orders/lamcom/layouts/:id",
    component: () => import("@/views/app/pages/hive/layouts")
  },
  /********** Layouts ************** */
  {
    meta : {
      title : i18n.t("pages.client.custom.lamcom.hive.steps.forms.edit_steps")
    },
    path: "/hive/project/:project_id/work_order/:work_order_id/steps",
    component: () => import("@/views/app/pages/client/custom/lamcom/hive/work_orders/steps")
  }
];

export default routes;
