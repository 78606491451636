<template>
  <div class="labelled-container">
    <div class="labelled-header" :style="labelStyle">{{ label }}</div>
    <div class="labelled-body">
      <slot name="body"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    bgColor: {
      type: String,
      required: false,
      default: "#fff"
    }
  },
  computed: {
    labelStyle() {
      return {
        "background-color": this.bgColor
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.labelled-container {
  position: relative;
  border: 1px solid #ddd;
  padding: 20px 10px;
  border-radius: 5px;
  .labelled-header {
    position: absolute;
    top: -10px;
    font-weight: bold;
    color: #909090;
  }
}
</style>
