import axios from "axios";
import { tpaApiUrl, tpaToken } from "@/constants/config";
import Vue from "vue";

const tpaSecuredAxios = axios.create({
  baseURL: tpaApiUrl,
  withCredentials: false, //XXXXXX
  headers: {
    "Content-Type": "application/json"
  }
});

const plainAxiosInstance = axios.create({
  baseURL: tpaApiUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  }
});

tpaSecuredAxios.interceptors.request.use(config => {
  const regex = /((?<p1>([0-9]+)),(?<p2>([0-9]+)))/gim;
  if (config.data) {
    let config_data = JSON.stringify(config.data);

    let exclude_fields = {};
    // Number format olmayan bir field varsa onu exclude et!
    Object.keys(config.data).forEach(key => {
      if (key.includes("file")) {
        exclude_fields[key] = config.data[key];
      }
    });

    config_data.match(regex)?.forEach(match => {
      config_data = config_data.replace(match, `${match.split(",")[0]}.${match.split(",")[1]}`);
    });
    config.data = JSON.parse(config_data);
    config.data = {
      ...config.data,
      ...exclude_fields
    };
  }

  const method = config.method.toUpperCase();
  if (method !== "OPTIONS") {
    config.headers = {
      ...config.headers,
      "tpa-tknk": tpaToken,
      app: "tpa"
    };
  }
  return config;
});

tpaSecuredAxios.interceptors.response.use(null, error => {
  if (
    error.response &&
    error.response.config &&
    error.response.status === 401 /// XXXXX We will fill this
  ) {
    Vue.$router.push("/user/login");

    return;

    return plainAxiosInstance
      .post("/refresh_token", {}, { headers: { tknk: localStorage.token } })
      .then(response => {
        localStorage.token = response.data.token;
        // LocalStorage Setting Here...
        localStorage.signedIn = true;

        let retryConfig = error.response.config;
        retryConfig.headers.tknk = localStorage.token;
        return plainAxiosInstance.request(retryConfig);
      })
      .catch(error => {
        delete localStorage.token;
        delete localStorage.signedIn;

        //location.replace("/");
        return Promise.reject(error);
      });
  } else {
    return Promise.reject(error);
  }
});

export { tpaSecuredAxios };
