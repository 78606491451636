export const defaultMenuType = "menu-sub-hidden"; // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const defaultStartPath = "/app/dashboards/default";
export const searchPath = "/app/pages/search";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English" },
  { id: "fr", name: "French" }
];

let BASE_URL = process.env.VUE_APP_BASE_URL;
let DELTA_BASE_URL = process.env.VUE_APP_DELTA_URL;

export const isDevelopment = process.env.NODE_ENV === "development";
let REPORT_SERVER_BASE_URL = process.env.VUE_APP_REPORT_SERVER_BASE_URL;
if (window.location.hostname.includes("banjhakri")) BASE_URL = process.env.VUE_APP_BANJHAKRI_BASE_URL;
if (window.location.hostname.includes("development")) BASE_URL = process.env.VUE_APP_DEVELOPMENT_BASE_URL;
if (window.location.hostname.includes("switch")) BASE_URL = process.env.VUE_APP_SWITCH_BASE_URL;

if (window.location.hostname.includes("puresol.puresol")) BASE_URL = process.env.VUE_APP_PURESOL_BASE_URL;
if (window.location.hostname.includes("lamcom.banjhakri")) {
  BASE_URL = process.env.VUE_APP_B2B_API_URL;
  REPORT_SERVER_BASE_URL = process.env.VUE_APP_B2B_API_URL;
} else if (location.host.includes("development.banjhakri.com")) {
  REPORT_SERVER_BASE_URL = BASE_URL;
}

const stable3 = ["cl3pl.puresol", "paperevo.puresol", "lamcom.puresol"];

stable3?.forEach(domain => {
  if (window.location.hostname.includes(domain)) BASE_URL = process.env.VUE_APP_LAMCOM_STABLE_BASE_URL;
})

if (process.env.VUE_APP_OVERWRITE_BASE_URL) BASE_URL = process.env.VUE_APP_OVERWRITE_BASE_URL;

let VUE_APP_SWITCH_IP = process.env.VUE_APP_SWITCH_IP;

export const signinApiUrl = BASE_URL;
export const reportApiUrl = `${REPORT_SERVER_BASE_URL}/api/${process.env.VUE_APP_API_VERSION}`;
export const apiUrl = `${BASE_URL}/api/${process.env.VUE_APP_API_VERSION}`;
export const publicApiUrl = `${BASE_URL}/public/api/${process.env.VUE_APP_API_VERSION}`;
export const tpaApiUrl = `${BASE_URL}/tpa/${process.env.VUE_APP_TPA_API_VERSION}`;
export const tpaToken = process.env.VUE_APP_TPA_TOKEN;
export const deltaApiUrl = `${DELTA_BASE_URL}/api/v1`;

//! ===================> Switch Variables <=======================================
export const switchServer = `${process.env.VUE_APP_SWITCH_PROTOCOL}://${VUE_APP_SWITCH_IP}:${process.env.VUE_APP_SWITCH_PORT}`;
export const switchJobServer = `${switchServer}/api/v1/job`;
let switchUserName = "administrator";
let switchPasswordHash =
  "!@$HO4HW/kc5nd3p3W9COKW9CRNHTLoJfdAslGb2Ol/LznemWs5gjueA+Y+lqo7x+Dwb2dc3wGkehxQlfGjzyPu5GSrFclnl2h3zycSCzd5cgYYaK3LkNyZY/XrpTAHx67UBZPxpE+S8Kk9LAKvxcb+g7jjhDnNh4tZpb2a9M5pA+A=";

if (window.location.hostname.includes("switch") || window.location.hostname.includes("mediatech.banjhakri")) {
  switchUserName = "puresol";
  switchPasswordHash =
    "ICmrfPKf1bIjQrmMRGvUjSyNiHlkcfTDL+E23AjmRRSR33XsRHDoWBOVAIr7MgaDOgT/a74dqmz32OjRj5yQtVd9s+K5t+rrsz67UXMHHMJsLkMv/cYg8LyWpwigU+SEV9NBaGCr8HKuq55eD420R5mtHTnFGRLnbCCETFovqM8=";
}
export const switchLoginCredentials = `${switchServer}/login?username=${switchUserName}&password=${switchPasswordHash}`;
//! ===================> Switch Variables <=======================================

export const colors = [
  "light.purple",
  "dark.purple",
  "light.blue",
  "dark.blue",
  "light.green",
  "dark.green",
  "light.orange",
  "dark.orange",
  "light.red",
  "dark.red"
];

export const templateMap = {
  custom_form: "custom_form",
  inventory_product_var: "inventory_product_var",
  logistic_fulfillment: "logistic_fulfillment",
  logistic_shipment: "logistic_shipment",
  logistic_transfer: "logistic_transfer",
  accounting_invoice_sale_final: "accounting_invoice_sale_final",
  accounting_invoice_sale_proforma: "accounting_invoice_sale_proforma",
  accounting_invoice_sale_quote: "accounting_invoice_sale_quote",
  accounting_invoice_purchase_final: "accounting_invoice_purchase_final",
  accounting_invoice_purchase_proforma: "accounting_invoice_purchase_proforma",
  accounting_credit: "accounting_credit",
  hive_work_order: "hive_work_order",
  hive_project: "hive_project",
  hive_job: "hive_job",
  hive_job_service: "hive_job_service",
  hive_quote: "hive_quote",
  hive_lamcom_layout_printing: "hive_lamcom_layout_printing",
  hive_lamcom_layout_cutting: "hive_lamcom_layout_cutting",
  hive_project_shipping_address: "hive_project_shipping_address",
  hive_project_billing_address: "hive_project_billing_address",
  customer: "customer",
  supplier: "supplier",
  maestro: "maestro"
};
