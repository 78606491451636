import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/hive/jobs/services",
    key: "job",
    fetchKey: "jobs",
    statusList: [
      {
        name: "pending",
        id: "pending",
        text: i18n.t("pages.hive.jobs.statusList.pending"),
        label: i18n.t("pages.hive.jobs.statusList.pending"),
        color: "warning",
      },
      {
        name: "active",
        id: "active",
        text: i18n.t("pages.hive.jobs.statusList.active"),
        label: i18n.t("pages.hive.jobs.statusList.active"),
        color: "primary",
      },
      {
        name: "completed",
        id: "completed",
        text: i18n.t("pages.hive.jobs.statusList.completed"),
        label: i18n.t("pages.hive.jobs.statusList.completed"),
        color: "success",
      },
      // {
      //   name: "cancelled",
      //   id: "cancelled",
      //   text: i18n.t("pages.hive.jobs.statusList.cancelled"),
      //   label: i18n.t("pages.hive.jobs.statusList.cancelled"),
      //   color: "cancelled",
      // },
      // {
      //   name: "deleted",
      //   id: "deleted",
      //   text: i18n.t("pages.hive.jobs.statusList.deleted"),
      //   label: i18n.t("pages.hive.jobs.statusList.deleted"),
      //   color: "danger",
      // },
    ],
    typeList: [
      {
        name: "printing",
        text: i18n.t("pages.hive.jobs.typeList.printing"),
      },
      {
        name: "installation",
        text: i18n.t("pages.hive.jobs.typeList.installation"),
      },
    ],
    belongsToList: [
      {
        name: "project",
        text: i18n.t("pages.hive.jobs.belongsToList.project"),
      },
      {
        name: "work_order",
        text: i18n.t("pages.hive.jobs.belongsToList.work_order"),
      },
      {
        name: "template",
        text: i18n.t("pages.hive.jobs.belongsToList.template"),
      },
    ],
    projectTypeList: [
      {
        name: "production",
        id: "production",
        text: i18n.t("pages.hive.job_services.enums.projectTypeList.production"),
        label: i18n.t("pages.hive.job_services.enums.projectTypeList.production"),
      },
      {
        name: "quote",
        id: "quote",
        text: i18n.t("pages.hive.job_services.enums.projectTypeList.quote"),
        label: i18n.t("pages.hive.job_services.enums.projectTypeList.quote"),
      },
    ],
  },
  mutations: {},
  actions: {},
  getters: {
    statusList: (state) => state.statusList,
    typeList: (state) => state.typeList,
    belongsToList: (state) => state.belongsToList,
    stepList: (state) => state.stepList,
    lamcomFileAssignStatusList: (state) => state.lamcomFileAssignStatusList,

    projectTypeList: (state) => state.projectTypeList,
  },
};
