export const UI = {
  lamcom_v2: {
    inventory_product: {
      redirect_list_page_after_update: true,
      redirect_list_page_after_insert: false,
      redirect_update_page_after_insert: true
    }
  },
  mediaon: {
    inventory_product: {
      redirect_list_page_after_update: false,
      redirect_list_page_after_insert: false,
      redirect_update_page_after_insert: true
    }
  }
};
