const routes = [
  {
    name : "TaxonsListPage",
    path: "/taxons",
    // listPage (For Listing Component)
    // listPageTitleKey (For ListPage <h1> Title)
    meta: { listPage: "taxons", listPageTitleKey: "taxons" },
    component: () => import("@/views/app/pages/taxons/list")
  },
  {
    name: "ParentTaxonListPage",
    path: "/taxons/tree/:parent_taxon_id",
    // listPage (For Listing Component)
    // listPageTitleKey (For ListPage <h1> Title)
    meta: { listPage: "taxons", listPageTitleKey: "taxons" },
    component: () => import("@/views/app/pages/taxons/list")
  },
  {
    name : "TaxonsNewPage",
    path: "/taxons/new",
    component: () => import("@/views/app/pages/taxons/new")
  },
  {
    name : "TaxonsNewPageWithTaxon",
    path: "/taxons/new/:parent_taxon_id",
    component: () => import("@/views/app/pages/taxons/new")
  },
  {
    name : "TaxonsUpdatePage",
    path: "/taxons/:id",
    component: () => import("@/views/app/pages/taxons/update")
  }
];

export default routes;
