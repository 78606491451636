import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/accounting/commission_rules",
    key: "accounting_commission_rule",
    fetchKey: "commission_rules", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: "",
    amountTypeList: [
      {
        name: "percentage",
        id: "percentage",
        text: i18n.t("pages.accounting.commission_rules.enums.amountTypeList.percentage"),
        label: i18n.t("pages.accounting.commission_rules.enums.amountTypeList.percentage")
      },
      // {
      //   name: "money",
      //   id: "money",
      //   text: i18n.t("pages.accounting.commission_rules.enums.amountTypeList.money"),
      //   label: i18n.t("pages.accounting.commission_rules.enums.amountTypeList.money")
      // }
    ],
    commissionApplyOnList: [
      {
        name: "all_sales",
        id: "all_sales",
        text: i18n.t("pages.accounting.commission_rules.enums.commissionApplyOnList.all_sales"),
        label: i18n.t("pages.accounting.commission_rules.enums.commissionApplyOnList.all_sales")
      },
      {
        name: "inventory_products",
        id: "inventory_products",
        text: i18n.t("pages.accounting.commission_rules.enums.commissionApplyOnList.inventory_products"),
        label: i18n.t("pages.accounting.commission_rules.enums.commissionApplyOnList.inventory_products")
      },
      {
        name: "inventory_product_types",
        id: "inventory_product_types",
        text: i18n.t("pages.accounting.commission_rules.enums.commissionApplyOnList.inventory_product_types"),
        label: i18n.t("pages.accounting.commission_rules.enums.commissionApplyOnList.inventory_product_types")
      },
      {
        name: "invoice_custom_items",
        id: "invoice_custom_items",
        text: i18n.t("pages.accounting.commission_rules.enums.commissionApplyOnList.invoice_custom_items"),
        label: i18n.t("pages.accounting.commission_rules.enums.commissionApplyOnList.invoice_custom_items")
      },
      {
        name: "invoice_shipment_items",
        id: "invoice_shipment_items",
        text: i18n.t("pages.accounting.commission_rules.enums.commissionApplyOnList.invoice_shipment_items"),
        label: i18n.t("pages.accounting.commission_rules.enums.commissionApplyOnList.invoice_shipment_items")
      },
      {
        name: "hive_projects",
        id: "hive_projects",
        text: i18n.t("pages.accounting.commission_rules.enums.commissionApplyOnList.hive_projects"),
        label: i18n.t("pages.accounting.commission_rules.enums.commissionApplyOnList.hive_projects")
      }
    ],
    calculateFromSourceList: [
      {
        name: "same_as_master",
        id: "same_as_master",
        text: i18n.t("pages.accounting.commission_rules.enums.calculateFromSourceList.same_as_master"),
        label: i18n.t("pages.accounting.commission_rules.enums.calculateFromSourceList.same_as_master")
      },
      {
        name: "total_sale_price",
        id: "total_sale_price",
        text: i18n.t("pages.accounting.commission_rules.enums.calculateFromSourceList.total_sale_price"),
        label: i18n.t("pages.accounting.commission_rules.enums.calculateFromSourceList.total_sale_price")
      },
      {
        name: "profit",
        id: "profit",
        text: i18n.t("pages.accounting.commission_rules.enums.calculateFromSourceList.profit"),
        label: i18n.t("pages.accounting.commission_rules.enums.calculateFromSourceList.profit")
      }
    ],
    applyOnList: [
      {
        name: "all",
        id: "all",
        text: i18n.t("pages.accounting.commission_rules.enums.applyOnList.all"),
        label: i18n.t("pages.accounting.commission_rules.enums.applyOnList.all")
      },
      {
        name: "product",
        id: "product",
        text: i18n.t("pages.accounting.commission_rules.enums.applyOnList.product"),
        label: i18n.t("pages.accounting.commission_rules.enums.applyOnList.product")
      },
      {
        name: "type",
        id: "type",
        text: i18n.t("pages.accounting.commission_rules.enums.applyOnList.type"),
        label: i18n.t("pages.accounting.commission_rules.enums.applyOnList.type")
      }
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    amountTypeList: state => state.amountTypeList,
    commissionApplyOnList: state => state.commissionApplyOnList,
    calculateFromSourceList: state => state.calculateFromSourceList,
    applyOnList: state => state.applyOnList
  }
};
