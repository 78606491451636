import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/hive/work_orders/",
    key: "work_order",
    fetchKey: "work_orders",
    statusList: [
      {
        name: "pending",
        id: "pending",
        text: i18n.t("pages.hive.work_orders.statusList.pending"),
        label: i18n.t("pages.hive.work_orders.statusList.pending"),
        color: "warning",
      },
      {
        name: "completed",
        id: "completed",
        text: i18n.t("pages.hive.work_orders.statusList.completed"),
        label: i18n.t("pages.hive.work_orders.statusList.completed"),
        color: "success",
        permission_key: "wo_complete",
      },
      {
        name: "pending_approval",
        id: "pending_approval",
        text: i18n.t("pages.hive.work_orders.statusList.pending_approval"),
        label: i18n.t("pages.hive.work_orders.statusList.pending_approval"),
        color: "outline-danger",
      },
      {
        name: "at_production",
        id: "at_production",
        text: i18n.t("pages.hive.work_orders.statusList.at_production"),
        label: i18n.t("pages.hive.work_orders.statusList.at_production"),
        color: "secondary",
      },
      {
        name: "on_hold",
        id: "on_hold",
        text: i18n.t("pages.hive.work_orders.statusList.on_hold"),
        label: i18n.t("pages.hive.work_orders.statusList.on_hold"),
        color: "dark",
        permission_key: "wo_put_on_hold",
      },
      {
        name: "declined",
        id: "declined",
        text: i18n.t("pages.hive.work_orders.statusList.declined"),
        label: i18n.t("pages.hive.work_orders.statusList.declined"),
        color: "danger",
        permission_key: "wo_decline",
      },
      {
        name: "cancelled",
        id: "cancelled",
        text: i18n.t("pages.hive.work_orders.statusList.cancelled"),
        label: i18n.t("pages.hive.work_orders.statusList.cancelled"),
        color: "cancel",
      },
    ],
    archiveTypeList: [
      {
        name: "none",
        text: i18n.t("pages.hive.work_orders.archiveTypeList.none"),
      },
      {
        name: "all",
        text: i18n.t("pages.hive.work_orders.archiveTypeList.all"),
      },
      {
        name: "only_graphic",
        text: i18n.t("pages.hive.work_orders.archiveTypeList.only_graphic"),
      },
      {
        name: "only_courbes",
        text: i18n.t("pages.hive.work_orders.archiveTypeList.only_courbes"),
      },
      // {
      //   name: "only_graphic_as_reference",
      //   text: i18n.t("pages.hive.work_orders.archiveTypeList.only_graphic_as_reference")
      // }
      {
        name: "only_original_files",
        text: i18n.t("pages.hive.work_orders.archiveTypeList.archive_file_to_modify"),
      },
    ],
    invoicedStatus: [
      {
        name: "pending",
        text: i18n.t("pages.hive.work_orders.invoicedStatus.pending"),
        color: "warning",
      },
      {
        name: "completed",
        text: i18n.t("pages.hive.work_orders.invoicedStatus.completed"),
        color: "success",
      },
      {
        name: "partial",
        text: i18n.t("pages.hive.work_orders.invoicedStatus.partial"),
        color: "outline-success",
      },
    ],
    permissionKey: "",
  },
  mutations: {},
  actions: {},
  getters: {
    statusList: (state) => state.statusList,
    archiveTypeList: (state) => state.archiveTypeList,
    invoicedStatus: (state) => state.invoicedStatus,
  },
};
