import { i18n } from "@/utils/i18n";

const routes = [
  /************** File Manager *************** */
  {
    name: "HiveProjectFileManager",
    meta: {
      module: "hive",
      key: null,
      backRouteName: "HiveProjectsUpdatePage",
      title: i18n.t("pages.hive.file_manager.file_manager")
    },
    path: "/hive/file_manager/:project_id",
    component: () => import("@/views/app/pages/hive/file_manager")
  },
  {
    name: "HiveProjectQAFileManager",
    meta: {
      module: "quality_assurance",
      key: null,
      backRouteName: "HiveProjectsUpdatePage",
      title: i18n.t("general.quality_assurance")
    },
    path: "/hive/file_manager/qa/:project_id",
    component: () => import("@/views/app/pages/hive/file_manager")
  },
  {
    name: "HiveQuoteFileManager",
    meta: {
      module: "hive",
      key: null,
      backRouteName: "HiveQuoteUpdatePage",
      title: i18n.t("pages.hive.file_manager.file_manager")
    },
    path: "/hive/file_manager/:project_id/:quote_id",
    component: () => import("@/views/app/pages/hive/file_manager")
  },
  {
    name: "AccountingInvoiceFileManager",
    meta: {
      module: "accounting_invoice",
      key: "accounting_invoice",
      backRouteName: "AccountingInvoicesUpdatePage",
      title: i18n.t("pages.hive.file_manager.file_manager")
    },
    path: "/accounting/invoices/:item_id/file_manager/",
    component: () => import("@/views/app/pages/hive/file_manager")
  },
  {
    name: "AccountingCreditFileManager",
    meta: {
      module: "accounting_credit",
      key: "accounting_credit",
      backRouteName: "CreditPage",
      title: i18n.t("pages.hive.file_manager.file_manager")
    },
    path: "/accounting/credits/:item_id/file_manager/",
    component: () => import("@/views/app/pages/hive/file_manager")
  },
  {
    name: "LogisticShipmentFileManager",
    meta: {
      module: "logistic_shipment",
      key: "logistic_shipment",
      backRouteName: "Logistic[:logistic_type]UpdatePage",
      title: i18n.t("pages.hive.file_manager.file_manager")
    },
    path: "/logistics/:logistic_type/:item_id/file_manager/",
    component: () => import("@/views/app/pages/hive/file_manager")
  },
  {
    name: "ContactFileManager",
    meta: {
      module: "contact",
      key: "contact",
      backRouteName: "Contact[:contact_type]UpdatePage",
      title: i18n.t("pages.hive.file_manager.file_manager")
    },
    path: "/contacts/:contact_type/:item_id/file_manager/",
    component: () => import("@/views/app/pages/hive/file_manager")
  },
  {
    name: "InventoryProductFileManager",
    meta: {
      module: "inventory_product",
      key: "inventory_product",
      backRouteName: "ProductUpdatePage",
      title: i18n.t("pages.hive.file_manager.file_manager")
    },
    path: "/inventory/products/:item_id/file_manager/",
    component: () => import("@/views/app/pages/hive/file_manager")
  },
  {
    name: "ClientFileManager",
    meta: {
      module: "client",
      key: null,
      backRouteName: "default",
      title: i18n.t("pages.hive.file_manager.file_manager")
    },
    path: "/client/file_manager",
    component: () => import("@/views/app/pages/hive/file_manager")
  }
];

export default routes;
