export default {
  namespaced: true,
  state: {
    endpoint: "/inventory/product/pictures",
    key: "inventory_product_image",
    fetchKey: "images", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: ""
  },
  mutations: {},
  actions: {},
  getters: {}
};
