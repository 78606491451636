const alertMixin = {
  methods: {
    // delete, activate, deactivate
    confirmAlert(messages, buttonType, cancelButtonText) {
      return this.$swal.fire({
        title: this.$t("alert-box.confirm.title"),
        html: messages,
        type: "warning",
        showCancelButton: true,
        cancelButtonText: cancelButtonText || this.$t("alert-box.cancel-button-text"),
        confirmButtonText: this.$t("alert-box.confirm.confirm-button." + buttonType + "-button-text")
      });
    },
    async textInputAlert(title, placeholder) {
      const { value: text } = await this.$swal.fire({
        title: title,
        input: "text",
        inputPlaceHolder: placeholder,
        showCancelButton: true,
        cancelButtonText: this.$t("alert-box.cancel-button-text"),
        inputValidator: value => {
          if (!value || value.match(/^[0-9]+$/) == null) {
            return this.$t("form-errors.integer");
          }
        }
      });
      if (text) {
        return text;
      }
    },
    infoAlert(message, buttonText) {
      return this.$swal.fire({
        title: this.$t("alert-box.infoAlert.title"),
        html: message,
        type: "warning",
        confirmButtonText: this.$t("alert-box.ok-button-text")
      });
    },
    errorAlert(message, buttonText) {
      return this.$swal.fire({
        title: this.$t("alert-box.infoAlert.title"),
        html: message,
        type: "error",
        confirmButtonText: this.$t("alert-box.ok-button-text")
      });
    }
  }
};

export default alertMixin;

// this.$t("alert-box.confirm.text");
