<template>
  <div>
    <treeselect
      v-if="taxonsLoaded"
      v-model="selected_taxons"
      :async="async"
      :options="founded_taxon_options"
      :load-options="loadOptions"
      :placeholder="$t('general.type_to_search')"
      :auto-load-root-options="false"
      valueFormat="object"
      :cacheOptions="false"
      :multiple="multiple"
      @input="setSelectedItem"
      @open="$emit('focus')"
      @deselect="$emit('focusout')"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { isArray } from "lodash";
import { ASYNC_SEARCH } from "@riophae/vue-treeselect";
import _ from "lodash";

export default {
  props: {
    value: {
      required: true
    },
    taxon_key: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true
    },
    async: {
      type: Boolean,
      required: false,
      default: true
    },
    filterTaxons: {
      type: Function,
      required: false,
      default: () => true
    }
  },
  data() {
    return {
      didSearch: false,
      founded_taxon_options: [],
      taxon_options: [],
      selected_taxons: {},
      taxonsLoaded: false
    };
  },
  methods: {
    loadOptions: _.debounce(function({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        let params = {
          search_text: searchQuery,
          type: this.getTaxonKey(this.taxon_key),
          page_size: 99999
        };

        this.founded_taxon_options = [];
        this.$store
          .dispatch("fetch", {
            endpoint: this.$store.state.taxons.endpoint + "quick_search",
            params: {
              params
            }
          })
          .then((response) => {
            this.founded_taxon_options = response.taxons?.filter(this.filterTaxons)?.map((t) => {
              return {
                ...t,
                label: t.name || t.name
              };
            });
            callback(null, this.founded_taxon_options);
          });
      }
    }),
    setSelectedItem() {
      if (this.multiple) {
        this.$emit(
          "input",
          this.selected_taxons?.map((t) => t.id)
        );
      } else {
        this.$emit("input", this.selected_taxons?.id);
      }
    }
  },
  mounted() {
    if (!this.async) {
      this.fetchTaxons(this.getTaxonKey(this.taxon_key)).then((taxons) => {
        this.founded_taxon_options = taxons?.filter(this.filterTaxons)?.map((t) => {
          return {
            ...t,
            label: t.name || t.name
          };
        });
      });
    }
  },
  created() {
    if (this.multiple) this.selected_taxons = [];
    this.fetchTaxons(this.taxon_key).then((taxons) => {
      const taxonList = taxons?.map((taxon) => {
        return {
          ...taxon,
          label: taxon?.name
        };
      });
      if (this.multiple) {
        this.selected_taxons = this.value?.map((t) => taxonList?.find((s) => s.id === t));
        this.$emit(
          "input",
          this.selected_taxons?.map((t) => t.id)
        );
      } else {
        this.selected_taxons = taxonList?.find((s) => s.id === this.value);
        this.$emit("input", this.selected_taxons?.id);
      }
      this.taxonsLoaded = true;
    });
  }
};
</script>
