import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/inventory/product/types/",
    key: "inventory_product_type",
    fetchKey: "product_types",
    permissionKey: "",
    conversationTable: {
      // Area
      mm2_cm2: 0.01,
      mm2_m2: 0.000001,
      mm2_sqyd: 0.0000012,
      mm2_sqin: 0.0015500031,
      mm2_km2: 0.000000000001,
      mm2_sqft: 0.0000107639104,
      mm2_acre: 0.000000000247105,
      mm2_sqmi: 0.000000000000386,

      cm2_mm2: 100,
      cm2_m2: 0.0001,
      cm2_sqyd: 0.0001,
      cm2_sqin: 0.15500031,
      cm2_km2: 0.0000000001,
      cm2_sqft: 0.00107639104,
      cm2_acre: 0.000000024710538,
      cm2_sqmi: 0.00000000003861,

      m2_mm2: 1000000,
      m2_cm2: 10000,
      m2_sqyd: 1.1959900463011,
      m2_sqin: 1550.0031000062,
      m2_km2: 0.000001,
      m2_sqft: 10.76391041671,
      m2_acre: 0.00024710538146717,
      m2_sqmi: 0.000000386102159,

      km2_mm2: 1000000000000,
      km2_cm2: 10000000000,
      km2_m2: 1000000,
      km2_sqyd: 1195990.0463011,
      km2_sqin: 1550003100.0062,
      km2_sqft: 10763910.41671,
      km2_acre: 247.10538146717,
      km2_sqmi: 0.38610215854245,

      acre_mm2: 4046856422.4,
      acre_cm2: 40468564.224,
      acre_m2: 4046.8564224,
      acre_km2: 0.0040468564224,
      acre_sqyd: 4840,
      acre_sqin: 6272640,
      acre_sqft: 43560,
      acre_sqmi: 0.0015625,

      sqmi_mm2: 2589988110336,
      sqmi_cm2: 25899881103.36,
      sqmi_m2: 2589988.110336,
      sqmi_km2: 2.589988110336,
      sqmi_sqyd: 3097600,
      sqmi_sqin: 4014489600,
      sqmi_sqft: 27878400,
      sqmi_acre: 640,

      sqft_mm2: 92903.04,
      sqft_cm2: 929.0304,
      sqft_m2: 0.09290304,
      sqft_km2: 0.00000009290304,
      sqft_sqyd: 0.11111111111111,
      sqft_sqin: 144,
      sqft_sqmi: 3.58700643e-8,
      sqft_acre: 0.000022956841139,

      sqin_mm2: 645.16,
      sqin_cm2: 6.4516,
      sqin_m2: 0.00064516,
      sqin_km2: 0.00000000064516,
      sqin_sqyd: 0.0007716049382716,
      sqin_sqft: 144,
      sqin_sqmi: 0.0000000002,
      sqin_acre: 0.0000001,

      sqyd_mm2: 836127.36,
      sqyd_cm2: 8361.2736,
      sqyd_m2: 0.83612736,
      sqyd_km2: 0.0000008,
      sqyd_sqin: 1296,
      sqyd_sqft: 9,
      sqyd_sqmi: 0.0000003,
      sqyd_acre: 0.00020661157024793,

      // Width, Length

      mm_cm: 0.1,
      mm_m: 0.001,
      mm_ft: 0.0032808399,
      mm_mi: 0.00000062137,

      cm_mm: 10,
      cm_m: 0.01,
      cm_ft: 0.032808399,
      cm_mi: 0.0000062137,

      in_mm: 25.4,
      in_m: 0.0254,
      in_ft: 0.0833333333,
      in_mi: 0.0000157828,

      m_mm: 1000,
      m_cm: 100,
      m_in: 39.3700787,
      m_ft: 3.2808399,
      m_mi: 0.000621371192,

      ft_mm: 304.8,
      ft_cm: 30.48,
      ft_in: 12,
      ft_m: 0.3048,
      ft_mi: 0.000189393939,

      mi_mm: 1609344,
      mi_cm: 160934.4,
      mi_m: 1609.344,
      mi_in: 60360,
      mi_ft: 5280,

      // Weight
      kg_g: 1000,
      kg_ston: 0.15747304441777,
      kg_lb: 2,
      kg_mton: 1,

      g_kg: 0.001,
      g_ston: 0.00015747304441777,
      g_lb: 0.002,
      g_mton: 0.001,

      lb_g: 453.59237 || 500,
      lb_kg: 0.45359237 || 0.5,
      lb_ston: 0.0714285714 || 0.078736522208885,
      lb_mton: 0.00045359237 || 0.5,

      ston_g: 6350.29318,
      ston_kg: 6.35029318,
      ston_lb: 12.70058636,
      ston_mton: 6.35029318,

      mton_g: 1000,
      mton_kg: 1,
      mton_lb: 2.2046223302272,
      mton_ston: 0.15747304441777,

      // Thickness
      micron_mm: 0.001,
      micron_cm: 0.0001,
      micron_in: 3.93700787e-5,
      micron_mil: 6.21371192e-10,

      mm_micron: 1000,
      mm_in: 0.039370078740157,

      mm_mil: 0.0000006,

      cm_micron: 10000,
      cm_in: 0.39370078740157,
      cm_mil: 6.21371192e-6,

      in_cm: 2.54,
      in_micron: 25400,
      in_mil: 1.57828283e-5,

      mil_mm: 1609344,
      mil_cm: 160934.4,
      mil_micron: 1609344000,
      mil_in: 63360,

      oz_gsm: 28.349523125,
      oz_lbl: 0.0625 || 0.05669904625,

      gsm_lbl: 0.002,
      gsm_oz: 0.03527396194958,

      lbl_oz: 17.63698097479,
      lbl_gsm: 500,

      // Volume
      ml_m3: 0.000001,
      ml_l: 0.001,
      ml_cm3: 1,
      ml_gal: 0.00026417205235815,
      ml_bbl: 0.000006,
      ml_ft3: 0.00003,

      m3_ml: 1000000,
      m3_l: 1000,
      m3_cm3: 1000000,
      m3_gal: 264.17205235815,
      m3_bbl: 6.2898105697751,
      m3_ft3: 35.314666721489,

      l_ml: 1000,
      l_m3: 0.001,
      l_cm3: 1000,
      l_gal: 0.26417205235815,
      l_bbl: 0.0062898105697751,
      l_ft3: 0.035314666721489,

      cm3_ml: 1,
      cm3_m3: 0.000001,
      cm3_l: 0.001,
      cm3_gal: 0.00026417205235815,
      cm3_bbl: 0.000006,
      cm3_ft3: 0.00003,

      gal_ml: 3785.411784,
      gal_m3: 0.003785411784,
      gal_l: 3.785411784,
      gal_cm3: 3785.411784,
      gal_bbl: 0.023809523049954,
      gal_ft3: 0.13368055555556,

      bbl_ml: 158987.3,
      bbl_m3: 0.1589873,
      bbl_l: 158.9873,
      bbl_cm3: 158987.3,
      bbl_gal: 42.000001339881,
      bbl_ft3: 5.6145835124493,

      ft3_ml: 28316.846592,
      ft3_m3: 0.028316846592,
      ft3_l: 28.316846592,
      ft3_cm3: 28316.846592,
      ft3_gal: 7.4805194805195,
      ft3_bbl: 0.17810760099706,
    },
    measurements: [
      {
        name: "area",
        id: "area",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.area"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.area"),
        units: ["mm2", "cm2", "m2", "km2", "acre", "sqmi", "sqft", "sqin", "sqyd"],
        factors: {
          mm2: "mm",
          cm2: "cm",
          m2: "m",
          km2: "km",
          acre: "m",
          sqmi: "mi",
          sqft: "ft",
          sqin: "in",
          sqyd: "yd",
        },
        default_unit: "sqft",
      },
      {
        name: "height",
        id: "height",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.height"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.height"),
        units: ["ft", "mi", "m", "mm", "in", "cm"],
        default_unit: "in",
      },

      {
        name: "width",
        id: "width",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.width"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.width"),
        units: ["ft", "mi", "m", "mm", "in", "cm"],
        default_unit: "in",
      },
      {
        name: "length",
        id: "length",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.length"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.length"),
        units: ["ft", "mi", "m", "mm", "in", "cm"],
        default_unit: "in",
      },
      {
        name: "diameter",
        id: "diameter",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.diameter"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.diameter"),
        units: ["ft", "m", "mm", "in", "cm"],
        default_unit: "cm",
      },
      {
        name: "core_diameter",
        id: "core_diameter",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.core_diameter"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.core_diameter"),
        units: ["mm", "in", "cm"],
        default_unit: "cm",
      },
      {
        name: "weight",
        id: "weight",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.weight"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.weight"),
        units: ["kg", "g", "lb", "ston", "mton"],
        default_unit: "kg",
      },
      {
        name: "thickness",
        id: "thickness",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.thickness"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.thickness"),
        units: ["micron", "mm", "cm", "in", "mil"],
        default_unit: "mil",
      },
      {
        name: "basis_weight",
        id: "basis_weight",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.basis_weight"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.basis_weight"),
        units: ["oz", "gsm", "lbl"],
        default_unit: "oz",
      },
      {
        name: "volume",
        id: "volume",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.volume"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.volume"),
        units: ["ml", "m3", "l", "cm3", "gal", "bbl", "ft3"],
      },
      {
        name: "package_height",
        id: "package_height",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_height"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_height"),
        units: ["ft", "mi", "m", "mm", "in", "cm"],
        default_unit: "in",
      },

      {
        name: "package_width",
        id: "package_width",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_width"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_width"),
        units: ["ft", "mi", "m", "mm", "in", "cm"],
        default_unit: "in",
      },
      {
        name: "package_length",
        id: "package_length",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_length"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_length"),
        units: ["ft", "mi", "m", "mm", "in", "cm"],
        default_unit: "in",
      },

      {
        name: "package_weight",
        id: "package_weight",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_weight"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_weight"),
        units: ["kg", "g", "lb", "ston", "mton"],
        default_unit: "lb",
      },

      {
        name: "unit_count",
        id: "unit_count",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.unit_count"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.unit_count"),
        units: ['unit'],
        default_unit: 'unit',
      },
      {
        name: "box_count",
        id: "box_count",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.box_count"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.box_count"),
        units: ['unit'],
        default_unit: 'unit',
      },
    ],
    activeMeasurement: [],
    measurementTypes: [],
    activeMeasurementType: {},
    sold_as_one_pricing: [
      {
        name: "per_unit",
        text: i18n.t("pages.clients.properties.inventory_settings.product.sold_as_one_pricing-items.per_unit"),
      },
      {
        name: "per_bundle",
        text: i18n.t("pages.clients.properties.inventory_settings.product.sold_as_one_pricing-items.per_bundle"),
      },
    ],
    stock_model: [
      {
        name: "m_1_unit",
        text: i18n.t("pages.inventory.product_types.stock_model.m_1_unit"),
      },
      {
        name: "m_2_unit_metage",
        text: i18n.t("pages.inventory.product_types.stock_model.m_2_unit_metage"),
      },
    ],
  },
  mutations: {
    // Global V-Select ActiveItem Selector
    setActiveItem(state, { activeItem, sub_state_object_name, options_state_property, active_state_property }) {
      // if list and active properties are in Parent Property
      if (sub_state_object_name) {
        let index = state[sub_state_object_name][options_state_property].findIndex((item) => {
          return item.name == activeItem;
        });
        if (index > -1) {
          state[sub_state_object_name][active_state_property] = state[sub_state_object_name][options_state_property][index];
        }
      } else {
        let index = state[options_state_property].findIndex((item) => {
          return item.name == activeItem;
        });
        if (index > -1) {
          state[active_state_property] = state[options_state_property][index];
        }
      }
    },
    unsetActiveItem(state, { sub_state_object_name, active_state_property }) {
      // if list and active properties are in Parent Property
      if (sub_state_object_name) {
        state[sub_state_object_name][active_state_property] = {};
      } else {
        state[active_state_property] = {};
      }
    },
  },
  actions: {},
  getters: {
    stock_model_list: (state) => state.stock_model,
    measurement_list: (state) => state.measurements,
    conversationTable: (state) => state.conversationTable,
  },
};
