import { mapGetters } from "vuex";
import { isArray } from "lodash";

const permissionMixin = {
  methods: {
    getPermission(moduleName, permission, wholeModules) {
      if (wholeModules === undefined) {
        let hasPermission = null;
        if (moduleName != "" && moduleName !== undefined) {
          try {
            hasPermission = [this.$store.state.user.permissions].concat(moduleName.split(".")).reduce(function(a, b) {
              return a[b];
            });

            if (permission) {
              return hasPermission[permission] === undefined ? false : hasPermission[permission];
            } else {
              return hasPermission === undefined ? false : hasPermission;
            }
          } catch (error) {
            if (permission) {
              return true;
            } else {
              return {
                all: false,
                to_view: true,
                to_update: true,
                to_create: true,
                to_deactivate: true,
                to_activate: true
              };
            }
          }
        } else {
          if (permission) {
            return true;
          } else {
            return {
              all: false,
              to_view: true,
              to_update: true,
              to_create: true,
              to_deactivate: true,
              to_activate: true
            };
          }
        }
      } else {
        // Per Module Restirict This is for SideBar Module Show/Hide
        // We need to Check All to_view permissions in sections of module
        return Object.values(this.$store.state.user.permissions[moduleName]).some(p => p.to_view == true);
        // return this.$store.state.user.permissions[moduleName][subModule][permission];
      }
    },
    ableToView(moduleName, subModule) {
      return this.$store.state.user.permissions[moduleName][subModule]["to_view"] || this.$store.state.user.permissions[moduleName][subModule]["all"];
    },
    ableToCreate(moduleName, subModule) {
      return this.$store.state.user.permissions[moduleName][subModule]["to_create"] || this.$store.state.user.permissions[moduleName][subModule]["all"];
    },
    ableToUpdate(moduleName, subModule) {
      return this.$store.state.user.permissions[moduleName][subModule]["to_update"] || this.$store.state.user.permissions[moduleName][subModule]["all"];
    },
    ableToActivate(moduleName, subModule) {
      return this.$store.state.user.permissions[moduleName][subModule]["to_activate"] || this.$store.state.user.permissions[moduleName][subModule]["all"];
    },
    ableToDeActivate(moduleName, subModule) {
      return this.$store.state.user.permissions[moduleName][subModule]["to_deactivate"] || this.$store.state.user.permissions[moduleName][subModule]["all"];
    },
    ableToPermissionWithTaxon(taxonID, excludeAdmin) {
      if (excludeAdmin) {
        if (isArray(this?.activeUser?.user?.group_ids) && this.activeUser?.user?.group_ids?.indexOf(taxonID) > -1) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this?.activeUser?.user?.admin || (isArray(this?.activeUser?.user?.group_ids) && this.activeUser?.user?.group_ids?.indexOf(taxonID) > -1)) {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      activeUser: "activeUser"
    }),
    isWebMaster() {
      return this.activeUser.user.webmaster;
    }
  }
};

export default permissionMixin;
