import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/custom/field_groups/",
    key: "custom_field_group",
    fetchKey: "custom_field_groups",
    fieldGroups: [
      {
        name: "inventory_product_var",
        text: i18n.t("pages.custom_field_groups.fieldGroups.inventory_product_var"),
      },
      {
        name: "contact_customer",
        text: i18n.t("pages.custom_field_groups.fieldGroups.contact_customer"),
      },
      {
        name: "contact_supplier",
        text: i18n.t("pages.custom_field_groups.fieldGroups.contact_supplier"),
      },
      {
        name: "accounting_invoice",
        text: i18n.t("pages.custom_field_groups.fieldGroups.accounting_invoice"),
      },
      {
        name: "custom_form",
        text: i18n.t("pages.custom_field_groups.fieldGroups.custom_form"),
      },
      {
        name: "logistic_fulfillment",
        text: i18n.t("pages.custom_field_groups.fieldGroups.logistic_fulfillment"),
      },
      {
        name: "logistic_shipment",
        text: i18n.t("pages.custom_field_groups.fieldGroups.logistic_shipment"),
      },
      {
        name: "accounting_purchase_order",
        text: i18n.t("pages.custom_field_groups.fieldGroups.accounting_purchase_order"),
      },
      {
        name: "accounting_proforma_invoice",
        text: i18n.t("pages.custom_field_groups.fieldGroups.accounting_proforma_invoice"),
      },
      {
        name: "accounting_quote_invoice",
        text: i18n.t("pages.custom_field_groups.fieldGroups.accounting_quote_invoice"),
      },
      {
        name: "accounting_invoice_final_purchase",
        text: i18n.t("pages.custom_field_groups.fieldGroups.accounting_invoice_final_purchase"),
      },
      {
        name: "hive_project",
        text: i18n.t("pages.custom_field_groups.fieldGroups.hive_project"),
      },
      {
        name: "hive_quote",
        text: i18n.t("pages.custom_field_groups.fieldGroups.hive_quote"),
      },
      {
        name: "hive_work_order",
        text: i18n.t("pages.custom_field_groups.fieldGroups.hive_work_order"),
      },
      {
        name: "hive_p_job",
        text: i18n.t("pages.custom_field_groups.fieldGroups.hive_p_job"),
      },
      {
        name: "hive_p_job_field",
        text: i18n.t("pages.custom_field_groups.fieldGroups.hive_p_job_field"),
      },
      {
        name: "hive_wo_job",
        text: i18n.t("pages.custom_field_groups.fieldGroups.hive_wo_job"),
      },
      {
        name: "hive_job_service",
        text: i18n.t("pages.custom_field_groups.fieldGroups.hive_job_service"),
      },
      {
        name: "hive_wo_job_field",
        text: i18n.t("pages.custom_field_groups.fieldGroups.hive_wo_job_field"),
      },
      {
        name: "karavan_product",
        text: i18n.t("pages.custom_field_groups.fieldGroups.karavan_product"),
      },
      {
        name: "user",
        text: i18n.t("pages.custom_field_groups.fieldGroups.user"),
      },
      {
        name: "prepress_action_preset",
        text: i18n.t("pages.custom_field_groups.fieldGroups.prepress_action_preset"),
      },
      {
        name: "",
        text: i18n.t("general.all"),
      },
    ],
    activeFieldGroup: "",
    permissionKey: "",
  },
  mutations: {
    setActiveFieldGroup(state, activeFieldGroup) {
      let index = state.fieldGroups.indexOf(activeFieldGroup);
      if (index > -1) {
        state.activeFieldGroup = state.fieldGroups[index];
      }
    },
  },
  actions: {},
  getters: {
    fieldGroups: (state) => state.fieldGroups.sort((a, b) => ("" + a.text).localeCompare(b.text)),
  },
};
