import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/accounting/commission/payments/",
    key: "accounting_commission_payment",
    fetchKey: "accounting_commission_payments", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: "",
    statusList: [
      {
        name: "pending",
        text: i18n.t("pages.accounting.commission_payments.enums.statusList.pending")
      },
      {
        name: "completed",
        text: i18n.t("pages.accounting.commission_payments.enums.statusList.completed")
      }
    ],
    belongedObjectList: [
      {
        name: "contact",
        text: i18n.t("pages.accounting.commission_payments.enums.belongedObjectList.contact")
      },
      {
        name: "user",
        text: i18n.t("pages.accounting.commission_payments.enums.belongedObjectList.user")
      }
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    statusList: state => state.statusList,
    belongedObjectList: state => state.belongedObjectList
  }
};
