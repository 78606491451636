import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(['afterCreateRedirect', 'afterUpdateRedirect', 'afterErrorRedirect']),
    isNewPage() {
      return this.$route.fullPath.includes("new");
    },
    saveAndClose() {
      if(this.isNewPage) return this.afterCreateRedirect
      return this.afterUpdateRedirect
    }
  },
}