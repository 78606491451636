import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    wo_field_list: [
      {
        name: "auto_id",
        id: "auto_id",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.auto_id"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.auto_id")
      },
      {
        name: "status",
        id: "status",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.status"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.status")
      },
      {
        name: "due_date",
        id: "due_date",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.due_date"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.due_date")
      },
      {
        name: "customer_auto_id",
        id: "customer_auto_id",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.customer_auto_id"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.customer_auto_id")
      },
      {
        name: "contact_name",
        id: "contact_name",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.contact_name"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.contact_name")
      },
      {
        name: "name",
        id: "name",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.name"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.name")
      },
      {
        name: "primary_job_material_name",
        id: "primary_job_material_name",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.material"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.material")
      },
      {
        name: "current_step",
        id: "current_step",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.current_step"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.current_step")
      },
      {
        name: "next_step",
        id: "next_step",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.next_step"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.next_step")
      },
      {
        name: "total_sale_price",
        id: "total_sale_price",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.total_price"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.total_price")
      },
      {
        name: "sales_rep",
        id: "sales_rep",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.sales_rep"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.sales_rep")
      },
      {
        name: "project_managers",
        id: "project_managers",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.project_managers"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.project_managers")
      },
      {
        name: "user_reps",
        id: "user_reps",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.user_reps"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.user_reps")
      },
      {
        name: "earliest_installation_date",
        id: "earliest_installation_date",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.installation_date"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.installation_date")
      },
      {
        name: "note",
        id: "note",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.note"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.note")
      },
      {
        name: "assigned",
        id: "assigned",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.assigned"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.assigned")
      },
      {
        name: "wo_po",
        id: "wo_po",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.wo_po"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.wo_po")
      },
      {
        name: "total_estimated_print_time_mins",
        id: "total_estimated_print_time_mins",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.total_estimated_print_time_mins"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.total_estimated_print_time_mins")
      },
      {
        name: "total_estimated_cut_time_mins",
        id: "total_estimated_cut_time_mins",
        text: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.total_estimated_cut_time_mins"),
        label: i18n.t("pages.client.custom.lamcom.dashboard.work_order_list.total_estimated_cut_time_mins")
      }
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    getWOFieldList: state => state.wo_field_list
  }
};
