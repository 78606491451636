import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/marketing/rewards/",
    key: "reward",
    fetchKey: "rewards",
    programList: [
      {
        name: "discount",
        text: i18n.t("pages.marketing.rewards.enums.programList.discount")
      },
      {
        name: "gift_card",
        text: i18n.t("pages.marketing.rewards.enums.programList.gift_card")
      }
    ],
    discountTypeList: [
      {
        name: "regular",
        text: i18n.t("pages.marketing.rewards.enums.discountTypeList.regular")
      }
    ],
    triggerList: [
      {
        name: "any_sale",
        text: i18n.t("pages.marketing.rewards.enums.triggerList.any_sale")
      },
      {
        name: "product_sale",
        text: i18n.t("pages.marketing.rewards.enums.triggerList.product_sale")
      },
      {
        name: "product_type_sale",
        text: i18n.t("pages.marketing.rewards.enums.triggerList.product_type_sale")
      }
    ],
    applyOrderList: [
      {
        name: "default",
        text: i18n.t("pages.marketing.rewards.enums.applyOrderList.default")
      },
      {
        name: "additional_to_default",
        text: i18n.t("pages.marketing.rewards.enums.applyOrderList.additional_to_default")
      },
      {
        name: "instead_of_default",
        text: i18n.t("pages.marketing.rewards.enums.applyOrderList.instead_of_default")
      }
    ],
    applyOnList: [
      {
        name: "all_invoice",
        text: i18n.t("pages.marketing.rewards.enums.applyOnList.all_invoice")
      },
      {
        name: "all_invoice_ex_shipping",
        text: i18n.t("pages.marketing.rewards.enums.applyOnList.all_invoice_ex_shipping")
      },
      {
        name: "on_shipping",
        text: i18n.t("pages.marketing.rewards.enums.applyOnList.on_shipping")
      },
      {
        name: "products",
        text: i18n.t("pages.marketing.rewards.enums.applyOnList.products")
      },
      {
        name: "product_types",
        text: i18n.t("pages.marketing.rewards.enums.applyOnList.product_types")
      }
    ],
    amountTypeList: [
      {
        name: "fixed",
        text: i18n.t("pages.marketing.rewards.enums.amountTypeList.fixed")
      },
      {
        name: "percentage",
        text: i18n.t("pages.marketing.rewards.enums.amountTypeList.percentage")
      },
      {
        name: "free",
        text: i18n.t("pages.marketing.rewards.enums.amountTypeList.free")
      }
    ],
    repetitionList: [
      {
        name: "one_time",
        text: i18n.t("pages.marketing.rewards.enums.repetitionList.one_time")
      },
      {
        name: "infinite",
        text: i18n.t("pages.marketing.rewards.enums.repetitionList.infinite")
      },
      {
        name: "specific_amount",
        text: i18n.t("pages.marketing.rewards.enums.repetitionList.specific_amount")
      }
    ],
    activeFieldType: "",
    permissionKey: ""
  },
  actions: {},
  getters: {
    programList: state => state.programList,
    discountTypeList: state => state.discountTypeList,
    triggerList: state => state.triggerList,
    applyOrderList: state => state.applyOrderList,
    applyOnList: state => state.applyOnList,
    amountTypeList: state => state.amountTypeList,
    repetitionList: state => state.repetitionList
  }
};
