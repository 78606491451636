import { i18n } from "@/utils/i18n";

const routes = [
  {
    name: "ReportListPage",
    path: "/reports",
    meta : {
      title : i18n.t('menu.reports')
    },
    component: () => import("@/views/app/pages/reports/distributor")
  },
  {
    name: "ReportShowPage",
    path: "/reports/:report_id",
    component: () => import("@/views/app/pages/reports/report")
  }
];

export default routes;
