import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/custom/files/",
    key: "custom_file",
    fetchKey: "custom_files",
    permissionKey: "",
    hive_job_process_status: [
      {
        name: "success",
        text: i18n.t("pages.custom_files.hive_job_process_status.success"),
        color: "success"
      },
      {
        name: "error",
        text: i18n.t("pages.custom_files.hive_job_process_status.error"),
        color: "danger"
      }
    ],
    hiveTypeList: [
      {
        name: "image",
        text: i18n.t("pages.custom_files.hiveTypeList.image")
      },
      {
        name: "pdf",
        text: i18n.t("pages.custom_files.hiveTypeList.pdf")
      },
      {
        name: "excel",
        text: i18n.t("pages.custom_files.hiveTypeList.excel")
      },
      {
        name: "document",
        text: i18n.t("pages.custom_files.hiveTypeList.document")
      },
      {
        name: "slide",
        text: i18n.t("pages.custom_files.hiveTypeList.slide")
      },
      {
        name: "zip",
        text: i18n.t("pages.custom_files.hiveTypeList.zip")
      },
      {
        name: "audio",
        text: i18n.t("pages.custom_files.hiveTypeList.audio")
      },
      {
        name: "video",
        text: i18n.t("pages.custom_files.hiveTypeList.video")
      },
      {
        name: "other",
        text: i18n.t("pages.custom_files.hiveTypeList.other")
      }
    ],
    hiveAssignedToList: [
      {
        name: "hive_job_service",
        text: i18n.t("pages.custom_files.hiveAssignedToList.hive_job_service")
      },
      {
        name: "hive_project",
        text: i18n.t("pages.custom_files.hiveAssignedToList.hive_project")
      },
      {
        name: "inventory_product",
        text: i18n.t("pages.custom_files.hiveAssignedToList.inventory_product")
      },
      {
        name: "contact",
        text: i18n.t("pages.custom_files.hiveAssignedToList.contact")
      },
      {
        name: "logistic_shipment",
        text: i18n.t("pages.custom_files.hiveAssignedToList.logistic_shipment")
      },
      {
        name: "accounting_invoice",
        text: i18n.t("pages.custom_files.hiveAssignedToList.accounting_invoice")
      },
      {
        name: "accounting_credit",
        text: i18n.t("pages.custom_files.hiveAssignedToList.accounting_credit")
      },
      {
        name: "client",
        text: i18n.t("pages.custom_files.hiveAssignedToList.client")
      }
    ],
    preparedByList: [
      {
        name: "pre_flight",
        id: "pre_flight",
        text: i18n.t("pages.custom_files.preparedByList.pre_flight"),
        label: i18n.t("pages.custom_files.preparedByList.pre_flight")
      },
      {
        name: "customer",
        id: "customer",
        text: i18n.t("pages.custom_files.preparedByList.customer"),
        label: i18n.t("pages.custom_files.preparedByList.customer")
      }
    ],
    fileList: [],
    total_count: 0
  },
  mutations: {
    updateFileList(state, fileResponse) {
      state.fileList = JSON.parse(JSON.stringify(fileResponse.items));
      state.total_count = fileResponse.total_count;
    }
  },
  actions: {},
  getters: {
    hiveJobProcessStatusList: state => state.hive_job_process_status,
    hiveTypeList: state => state.hiveTypeList,
    hiveAssignedToList: state => state.hiveAssignedToList,
    preparedByList: state => state.preparedByList,
    fileList: state => state.fileList,
    total_count: state => state.total_count
  }
};
