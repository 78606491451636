import { i18n } from "@/utils/i18n";
import { pick } from "lodash";

export default {
  namespaced: true,
  state: {
    endpoint: "/inventory/products/",
    key: "inventory_product",
    fetchKey: "products",
    permissionKey: "",
    statusList: [
      {
        name: "in_stock",
        text: i18n.t("general.inventory_product_status_list.in_stock"),
        color: "outline-success",
        id: "in_stock",
        label: i18n.t("general.inventory_product_status_list.in_stock")
      },
      // {
      //   name: "pending",
      //   id: "pending",
      //   text: i18n.t("general.inventory_product_status_list.pending"),
      //   label: i18n.t("general.inventory_product_status_list.pending"),
      //   color: "outline-warning"
      // },
      {
        name: "sold_out",
        id: "sold_out",
        text: i18n.t("general.inventory_product_status_list.sold_out"),
        label: i18n.t("general.inventory_product_status_list.sold_out"),
        color: "outline-warning"
      },
      // {
      //   name: "template",
      //   id: "template",
      //   text: i18n.t("general.inventory_product_status_list.template"),
      //   label: i18n.t("general.inventory_product_status_list.template"),
      //   color: "outline-info"
      // },
      // {
      //   name: "re_packed",
      //   id: "re_packed",
      //   text: i18n.t("general.inventory_product_status_list.re_packed"),
      //   label: i18n.t("general.inventory_product_status_list.re_packed"),
      //   color: "outline-warning"
      // },
      {
        name: "inactive",
        id: "inactive",
        text: i18n.t("general.inventory_product_status_list.inactive"),
        label: i18n.t("general.inventory_product_status_list.inactive"),
        color: "light"
      },
      {
        name: "pre_stock",
        id: "pre_stock",
        text: i18n.t("general.inventory_product_status_list.pre_stock"),
        label: i18n.t("general.inventory_product_status_list.pre_stock"),
        color: "warning"
      },
      {
        name: "all",
        id: "all",
        text: i18n.t("general.inventory_product_status_list.all"),
        label: i18n.t("general.inventory_product_status_list.all"),
        color: "dark"
      }
    ],
    activeStatus: {},
    measurements: [
      {
        name: "area",
        id: "area",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.area"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.area"),
        units: ["mm2", "cm2", "m2", "km2", "acre", "sqmi", "sqft", "sqin", "sqyd"],
        default_unit: "m2"
      },
      {
        name: "height",
        id: "height",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.height"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.height"),
        units: ["ft", "mi", "m", "mm", "in", "cm"],
        default_unit: "cm"
      },

      {
        name: "width",
        id: "width",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.width"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.width"),
        units: ["ft", "mi", "m", "mm", "in", "cm"],
        default_unit: "in"
      },
      {
        name: "length",
        id: "length",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.length"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.length"),
        units: ["ft", "mi", "m", "mm", "in", "cm"],
        default_unit: "ft"
      },
      {
        name: "diameter",
        id: "diameter",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.diameter"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.diameter"),
        units: ["ft", "m", "mm", "in", "cm"],
        default_unit: "cm"
      },
      {
        name: "core_diameter",
        id: "core_diameter",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.core_diameter"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.core_diameter"),
        units: ["mm", "in", "cm"],
        default_unit: "cm"
      },
      {
        name: "weight",
        id: "weight",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.weight"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.weight"),
        units: ["kg", "g", "lb", "ston", "mton"],
        default_unit: "kg"
      },
      {
        name: "thickness",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.thickness"),
        units: ["micron", "mm", "cm", "in", "mil"],
        default_unit: "mil"
      },
      {
        name: "basis_weight",
        id: "basis_weight",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.basis_weight"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.basis_weight"),
        units: ["oz", "gsm", "lbl"],
        default_unit: "oz"
      },
      {
        name: "volume",
        id: "volume",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.volume"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.volume"),
        units: ["ml", "m3", "l", "cm3", "gal", "bbl", "ft3"]
      },
      {
        name: "package_height",
        id: "package_height",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_height"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_height"),
        units: ["ft", "mi", "m", "mm", "in", "cm"],
        default_unit: "cm"
      },

      {
        name: "package_width",
        id: "package_width",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_width"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_width"),
        units: ["ft", "mi", "m", "mm", "in", "cm"],
        default_unit: "in"
      },
      {
        name: "package_length",
        id: "package_length",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_length"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_length"),
        units: ["ft", "mi", "m", "mm", "in", "cm"],
        default_unit: "ft"
      },

      {
        name: "package_weight",
        id: "package_weight",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_weight"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.package_weight"),
        units: ["kg", "g", "lb", "ston", "mton"],
        default_unit: "lb"
      },

      {
        name: "unit_count",
        id: "unit_count",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.unit_count"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.unit_count"),
        units: ['unit'],
        default_unit: 'unit'
      },
      {
        name: "box_count",
        id: "box_count",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.box_count"),
        label: i18n.t("pages.clients.properties.filter_items_attributes.measurements.box_count"),
        units: ['unit'],
        default_unit: 'unit'
      },
    ],
    activeMeasurement: [],
    measurementTypes: [],
    activeMeasurementType: {},
    sold_as_one_pricing: [
      {
        name: "per_unit",
        text: i18n.t("pages.clients.properties.inventory_settings.product.sold_as_one_pricing-items.per_unit")
      },
      {
        name: "per_bundle",
        text: i18n.t("pages.clients.properties.inventory_settings.product.sold_as_one_pricing-items.per_bundle")
      }
    ],
    calculate_value_types: [
      {
        name: "static",
        id: "static",
        text: i18n.t("pages.inventory.products.prices-attributes.calculate_value_types.static"),
        label: i18n.t("pages.inventory.products.prices-attributes.calculate_value_types.static"),
      },
      {
        name: "custom_field",
        id: "custom_field",
        text: i18n.t("pages.inventory.products.prices-attributes.calculate_value_types.custom_field"),
        label: i18n.t("pages.inventory.products.prices-attributes.calculate_value_types.custom_field"),
      },
      {
        name: "measurement",
        id: "measurement",
        text: i18n.t("pages.inventory.products.prices-attributes.calculate_value_types.measurement"),
        label: i18n.t("pages.inventory.products.prices-attributes.calculate_value_types.measurement"),
      }
    ],
    calculate_functions: [
      {
        name: "add",
        id: "add",
        text: i18n.t("pages.inventory.products.prices-attributes.calculate_functions.add"),
        label: i18n.t("pages.inventory.products.prices-attributes.calculate_functions.add"),
      },
      {
        name: "subtract",
        id: "subtract",
        text: i18n.t("pages.inventory.products.prices-attributes.calculate_functions.subtract"),
        label: i18n.t("pages.inventory.products.prices-attributes.calculate_functions.subtract"),
      },
      {
        name: "multiply",
        id: "multiply",
        text: i18n.t("pages.inventory.products.prices-attributes.calculate_functions.multiply"),
        label: i18n.t("pages.inventory.products.prices-attributes.calculate_functions.multiply"),
      },
      {
        name: "divide",
        id: "divide",
        text: i18n.t("pages.inventory.products.prices-attributes.calculate_functions.divide"),
        label: i18n.t("pages.inventory.products.prices-attributes.calculate_functions.divide"),
      },
      {
        name: "percentage",
        id: "percentage",
        text: i18n.t("pages.inventory.products.prices-attributes.calculate_functions.percentage"),
        label: i18n.t("pages.inventory.products.prices-attributes.calculate_functions.percentage"),
      }
    ],
    adjustment_type_list: [
      {
        name: "reduce",
        id: "reduce",
        text: i18n.t("pages.inventory.products.adjustment_type_list.reduce"),
        label: i18n.t("pages.inventory.products.adjustment_type_list.reduce")
      },
      {
        name: "add",
        id: "add",
        text: i18n.t("pages.inventory.products.adjustment_type_list.add"),
        label: i18n.t("pages.inventory.products.adjustment_type_list.add")
      }
    ],
    stock_type_list: [
      {
        name: "default",
        id: "default",
        text: i18n.t("pages.inventory.products.stock_type_list.default"),
        label: i18n.t("pages.inventory.products.stock_type_list.default")
      },
      {
        name: "unit",
        id: "unit",
        text: i18n.t("pages.inventory.products.stock_type_list.unit"),
        label: i18n.t("pages.inventory.products.stock_type_list.unit")
      },
      {
        name: "metage",
        id: "metage",
        text: i18n.t("pages.inventory.products.stock_type_list.metage"),
        label: i18n.t("pages.inventory.products.stock_type_list.metage")
      }
    ],
    stock_rule_list: [
      {
        name: "percentage",
        id: "percentage",
        text: i18n.t("pages.inventory.products.stock_rule_list.percentage"),
        label: i18n.t("pages.inventory.products.stock_rule_list.percentage")
      },
      {
        name: "amount",
        id: "amount",
        text: i18n.t("pages.inventory.products.stock_rule_list.amount"),
        label: i18n.t("pages.inventory.products.stock_rule_list.amount")
      }
    ],
    settings_source_list: [
      {
        name: "global",
        id: "global",
        text: i18n.t("pages.inventory.products.settings_source_list.global"),
        label: i18n.t("pages.inventory.products.settings_source_list.global")
      },
      {
        name: "product_type",
        id: "product_type",
        text: i18n.t("pages.inventory.products.settings_source_list.product_type"),
        label: i18n.t("pages.inventory.products.settings_source_list.product_type")
      },
      {
        name: "individual",
        id: "individual",
        text: i18n.t("pages.inventory.products.settings_source_list.individual"),
        label: i18n.t("pages.inventory.products.settings_source_list.individual")
      }
    ],
    orderPriceTypes: [],
    orderPageLoading: false,
    orderTemplates: []
  },
  mutations: {
    //! Order Page Items ***********************************
    setOrderPageItems(state, list_page_info) {
      const orderTemplate = state.orderTemplates.find(t => t.id === list_page_info.templateID);
      if (orderTemplate) {
        orderTemplate.list = list_page_info.list;
      }
    },
    //! Order Page Filters ********************************
    setOrderPageFilterItems(state, { filter_items, templateID }) {
      const orderTemplate = state.orderTemplates.find(t => t.id === templateID);
      if (orderTemplate) {
        orderTemplate.filter_items = filter_items;
      }
    },
    //! Order Templates ***********************************
    newOrderTemplate(state, template) {
      state.orderTemplates.push(template);
    },
    deleteOrderTemplate(state, templateID) {
      state.orderTemplates = state.orderTemplates.filter(t => t.id !== templateID);
    },
    deleteAllOrderTemplates(state) {
      state.orderTemplates = [];
    },
    //! Order Cart ****************************************
    pushOrderCart(state, orderItem) {
      const orderTemplate = state.orderTemplates.find(t => t.id === orderItem.templateID);
      if (orderTemplate) {
        delete orderItem.templateID;

        const orderCartItemObject = {
          ...orderItem?.variation,
          quantity: orderItem?.quantity || 1,
          cartItemID: new Date().getTime(),
          selected: false,
          product: orderItem.product,
          price: orderItem.price,
          note: null,
          description: null,
          ...pick(orderItem, ["selected_unit", "recorded_in_metage", "recorded_metage_price", "recorded_metage_quantity"])
        };

        const matchedOrderCartItem = orderTemplate.orderCart.find(i => i?.id === orderItem?.variation?.id);
        if (matchedOrderCartItem) {
          matchedOrderCartItem.quantity += orderItem?.quantity;
          matchedOrderCartItem.price = orderItem?.price;
        } else {
          orderTemplate.orderCart.push(orderCartItemObject);
        }
      }
    },
    deleteOrderCartItem(state, orderItem) {
      const orderTemplate = state.orderTemplates.find(t => t.id === orderItem.templateID);
      if (orderTemplate) {
        orderTemplate.orderCart = orderTemplate.orderCart.filter(cartItem => cartItem.cartItemID !== orderItem.item.cartItemID);
      }
    },
    changeCartItemQuantity(state, { templateID, cartItemID, quantity }) {
      const cartItem = state.orderTemplates.find(t => t.id === templateID)?.orderCart?.find(o => o.cartItemID === cartItemID);
      if (cartItem) {
        cartItem.quantity = quantity;
      }
    },
    deleteCartItems(state, templateID) {
      const orderTemplate = state.orderTemplates.find(t => t.id === templateID);
      if (orderTemplate) {
        orderTemplate.orderCart = [];
      }
    },
    //! Order Page Contact
    setOrderPageContact(state, contact) {
      const orderTemplate = state.orderTemplates.find(t => t.id === contact.templateId);
      if (orderTemplate) {
        delete contact.templateId;
        orderTemplate.orderPageContact = contact;
        orderTemplate.title = contact?.company_name || orderTemplate.title;
        state.orderPageLoading = false;
      }
    },
    setOrderPageTmpContact(state, contact) {
      const orderTemplate = state.orderTemplates.find(t => t.id === contact.templateId);
      if (orderTemplate) {
        delete contact.templateId;
        orderTemplate.tmpContact = contact;
      }
    },
    deleteOrderPageContact(state, templateId) {
      const orderTemplate = state.orderTemplates.find(t => t.id === templateId);
      if (orderTemplate) {
        orderTemplate.orderPageContact = null;
        orderTemplate.tmpContact = null;
        orderTemplate.title = "Unnamed Order Template";
      }
    },
    addNewContactInventoryPrice(state, { contact_inventory_price, templateID }) {
      const orderTemplate = state.orderTemplates.find(t => t.id === templateID);
      if (orderTemplate) {
        const contactInventoryPrices = orderTemplate?.orderPageContact?.contact_inventory_prices?.find(p => p.id === contact_inventory_price.id);
        if (contactInventoryPrices) {
          orderTemplate.orderPageContact.contact_inventory_prices = [
            ...orderTemplate?.orderPageContact?.contact_inventory_prices?.filter(p => p.id !== contact_inventory_price.id),
            contact_inventory_price
          ];
        } else {
          orderTemplate?.orderPageContact?.contact_inventory_prices.push(contact_inventory_price);
        }
      }
    },
    //! Price Types
    fillPriceTypes(state, priceTypes) {
      state.orderPriceTypes = priceTypes;
    },
    //! Utils
    setOrderPageLoader(state, status) {
      state.orderPageLoading = status;
    }
  },
  actions: {},
  getters: {
    statusList: state => state.statusList,
    statusListWithoutAll: state => state.statusList?.filter(s => s.name !== "all"),
    calculateValueTypeList: state => state.calculate_value_types,
    calculateFunctionList: state => state.calculate_functions,
    measurementList: state => state.measurements,
    adjustmentTypeList: state => state.adjustment_type_list,
    stockTypeList: state => state.stock_type_list,
    stockRuleList: state => state.stock_rule_list,
    settingsSourceList: state => state.settings_source_list,

    orderPriceTypes: state => state.orderPriceTypes,
    orderPageLoading: state => state.orderPageLoading,

    orderTemplates: state => state.orderTemplates,
    activeTabContact: state => state.orderTemplates[state.activeTemplateIndex]?.orderPageContact || null
  }
};
