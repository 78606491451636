export default {
  namespaced: true,
  state: {
    endpoint: "/hive/prepress/files",
    key: "file",
    fetchKey: "files",
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  }
};
