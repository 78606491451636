export default [
  {
    name: 'PrepressFileUploader',
    path: '/prepress/file-uploader',
    component: () => import('@/views/app/pages/prepress/uploader'),
    children: []
  },
  {
    name: 'PrepressJobListPage',
    path: '/prepress/jobs',
    meta: { listPage: 'jobs', listPageTitleKey: 'jobs' },
    component: () => import('@/views/app/pages/prepress/jobs/JobListPage')
  },
  {
    name: 'PrepressLayoutSearchPage',
    path: '/prepress/layouts',
    meta: { listPage: 'layouts', listPageTitleKey: 'layouts' },
    component: () => import('@/views/app/pages/prepress/layouts/Search.vue')
  },
  {
    name: "PrepressLayoutsDetailPage",
    path: "/prepress/layouts/:work_order_id",
    component: () => import("@/views/app/pages/prepress/layouts/LayoutPages")
  },
  {
    name: 'PrepressGraph1UpSearchPage',
    path: '/prepress/graph1up',
    component: () => import('@/views/app/pages/prepress/graph_1_up/Search.vue')
  },
  {
    name: "PrepressGraph1UpDetailPage",
    path: "/prepress/graph1up/:work_order_id",
    component: () => import("@/views/app/pages/prepress/graph_1_up/index.vue")
  },
  {
    name: 'PrepressGraph1UpFinalisationSearchPage',
    path: '/prepress/graph1up-finalisation',
    component: () => import('@/views/app/pages/prepress/graph_1_up_finalisation/Search.vue')
  },
  {
    name: "PrepressGraph1UpFinalisationDetailPage",
    path: "/prepress/graph1up-finalisation/:work_order_id",
    component: () => import("@/views/app/pages/prepress/graph_1_up_finalisation/index.vue")
  },
]
