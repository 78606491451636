import { securedAxios } from "@/utils/securedAxios";
import CryptoJS from "crypto-js";

export default {
  namespaced: true,
  state: {
    endpoint: "/locations/",
    rackEndpoint: "location/racks",
    key: "location",
    fetchKey: "locations", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: "general.settings.locations",
    saltRounds: 10,
    cryptPassword: "pur350l**//w3ae8",
    locations: []
  },
  mutations: {
    setLocations(state, locations) {
      state.locations = locations;
    }
  },
  actions: {
    fetchLocations({ commit, state  }) {
      return securedAxios.get(`${state.endpoint}quick_search`).then(response => {
        let locations = CryptoJS.AES.encrypt(JSON.stringify(response.data[state.fetchKey]), state.cryptPassword);
        localStorage.locations = locations.toString();
        commit("setLocations", response.data[state.fetchKey]);
        return state.locations?.map(l => ({ ...l, label: l?.name }));
      });
    },
    initModules({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        let isFetchedLocations = localStorage.locations;
        if (isFetchedLocations !== undefined) {
          let bytes = CryptoJS.AES.decrypt(localStorage.locations.toString(), state.cryptPassword);
          let locations = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          if (locations?.length === 0) {
            dispatch("fetchLocations").then(locations => resolve(locations));
          } else {
            commit("setLocations", locations);
            resolve(state.locations?.map(l => ({ ...l, label: l?.name })));
          }
        } else {
          dispatch("fetchLocations").then(locations => resolve(locations));
        }
      });
    },
    refreshCache({ state, commit }) {
      return securedAxios.get(state.endpoint).then(response => {
        let locations = CryptoJS.AES.encrypt(JSON.stringify(response.data[state.fetchKey]), state.cryptPassword);
        localStorage.locations = locations.toString();
        commit("setLocations", response.data[state.fetchKey]);
        return state.locations;
      });
    }
  },
  getters: {
    getLocations: state => state.locations
  }
};
