var render, staticRenderFns
import script from "./PTreeselect.vue?vue&type=script&lang=js&"
export * from "./PTreeselect.vue?vue&type=script&lang=js&"
import style0 from "./PTreeselect.vue?vue&type=style&index=0&id=5401bdb2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5401bdb2",
  null
  
)

export default component.exports