import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/karavan/stores/",
    key: "karavan_store",
    fetchKey: "karavan_stores",
    permissionKey: ""
  },
  actions: {},
  mutations: {},
  getters: {}
};
