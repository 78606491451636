import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/lamcom/enfocus_switch/files/",
    key: "files",
    fetchKey: "files",
    permissionKey: "",
    preparedByList: [
      {
        name: "pre_flight",
        text: i18n.t("pages.files.preparedByList.pre_flight")
      },
      {
        name: "customer",
        text: i18n.t("pages.files.preparedByList.customer")
      }
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    preparedByList: state => state?.preparedByList
  }
};
