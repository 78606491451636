const routes = [
  {
    name: "core",
    path: "/dashboard",
    component: () => import("@/views/app/dashboard/Start")
  },
  {
    path: "/dashboard/result/work_orders",
    component: () => import("@/views/app/dashboard/wo_search_t1_pages/wo_list")
  },

  {
    name: "core",
    path: "app/second-menu",
    component: () => import(/* webpackChunkName: "second-menu" */ "@/views/app/secondMenu"),
    redirect: "/app/second-menu/second",
    children: [
      {
        name: "core",
        path: "second",
        component: () => import(/* webpackChunkName: "second-menu" */ "@/views/app/secondMenu/Second")
      }
    ]
  },
  {
    name: "core",
    path: "app/single",
    component: () => import(/* webpackChunkName: "single" */ "@/views/app/single")
  },
  // ! Sadece test için burada, sonradan silinecek
  {
    path: 'form-builder-test',
    component: () => import('@/views/app/pages/form-builder-test')
  },
  {
    path: 'discount-form',
    component: () => import('@/views/app/pages/form-builder-test/discount-form.vue')
  },
];

export default routes;
