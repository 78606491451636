export default {
  namespaced: true,
  state: {
    endpoint: "/inventory/product/var/option/types/",
    key: "inventory_option_type",
    fetchKey: "option_types", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: ""
  },
  actions: {},
  getters: {}
};
