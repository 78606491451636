const routes = [
  {
    name: "RewardsListPage",
    path: "/marketing/rewards",
    meta: { listPage: "rewards", listPageTitleKey: "rewards" },
    component: () => import("@/views/app/pages/marketing/rewards/list")
  },
  {
    name: "RewardsNewPage",
    path: "/marketing/rewards/new",
    component: () => import("@/views/app/pages/marketing/rewards/new")
  },
  {
    name: "RewardsUpdatePage",
    path: "/marketing/rewards/:id",
    component: () => import("@/views/app/pages/marketing/rewards/update")
  }
];

export default routes;
