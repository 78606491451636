export default {
  namespaced: true,
  state: {
    endpoint: "/webmasters/",
    key: "user",
    fetchKey: "webmasters" // for getting all records from API. clients : [{...},{...},{...}]
  },
  mutations: {},
  actions: {},
  getters: {}
};
