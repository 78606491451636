export default {
  namespaced: true,
  state: {
    endpoint: "/i18n_docs",
    key: "i18n_doc",
    fetchKey: "i18n_docs" // for getting all records from API. clients : [{...},{...},{...}]
  },
  mutations: {},
  actions: {},
  getters: {
    getLocations: state => state.locations
  }
};
