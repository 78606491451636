export default {
  namespaced: true,
  state: {
    endpoint: "/karavan/products/",
    key: "karavan_product",
    fetchKey: "karavan_products",
    permissionKey: ""
  },
  actions: {},
  mutations: {},
  getters: {}
};
