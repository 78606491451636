import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/inventory/product/categories/",
    key: "inventory_category",
    fetchKey: "categories", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: "",
    statusList: [
      {
        name: "cancelled",
        text: i18n.t("pages.inventory.product_categories.statusList.cancelled"),
        color: "danger",
      },
      {
        name: "pending",
        text: i18n.t("pages.inventory.product_categories.statusList.pending"),
        color: "primary",
      },
      {
        name: "started",
        text: i18n.t("pages.inventory.product_categories.statusList.started"),
        color: "info",
      },
      {
        name: "completed",
        text: i18n.t("pages.inventory.product_categories.statusList.completed"),
        color: "success",
      },
    ],
    availableForList: [
      {
        name: "puresol",
        id: "puresol",
        text: i18n.t("pages.inventory.product_categories.availableForList.puresol"),
        label: i18n.t("pages.inventory.product_categories.availableForList.puresol"),
      },
      {
        name: "karavan",
        id: "karavan",
        text: i18n.t("pages.inventory.product_categories.availableForList.karavan"),
        label: i18n.t("pages.inventory.product_categories.availableForList.karavan"),
      },
    ],
  },
  mutations: {},
  actions: {},
  getters: {
    statusList: (state) => state.statusList,
    availableForList: (state) => state.availableForList,
  },
};
