import { i18n } from "@/utils/i18n";

const routes = [
  /******** Projects  ***************/

  {
    path: "/hive",
    component: () => import("@/views/app/pages/hive/dashboard")
  },

  {
    name: "HiveProjectsListPage",
    path: "/hive/projects",
    meta: { listPage: "hive_projects", listPageTitleKey: "projects", title: i18n.t("menu.projects") },
    component: () => import("@/views/app/pages/hive/projects/list")
  },
  {
    name: "HiveProjectsNewPage",
    path: "/hive/projects/new",
    meta: {
      title: `${i18n.t("general.new_suffix", { suffix: i18n.t("pages.hive.projects.forms.module") })}`
    },
    component: () => import("@/views/app/pages/hive/projects/new")
  },
  {
    path: "/hive/projects/work_order_assign",
    meta: {
      title: `${i18n.t("pages.hive.work_orders.forms.work_order_assign")}`
    },
    component: () => import("@/views/app/pages/hive/work_orders/assign")
  },
  {
    name: "HiveProjectsUpdatePage",
    meta: {
      module: "hive"
    },
    path: "/hive/projects/:id",
    component: () => import("@/views/app/pages/hive/projects/update")
  },
  {
    name: "HiveProjectsUpdateFromServicesPage",
    meta: {
      module: "hive"
    },
    path: "/hive/projects/:id",
    component: () => import("@/views/app/pages/hive/projects/update")
  },
  {
    name: "HiveProjectsUpdateWithWorkOrderPage",
    meta: {
      module: "hive"
    },
    path: "/hive/projects/:id/:work_order",
    component: () => import("@/views/app/pages/hive/projects/update")
  },
  {
    name: "HiveProjectsUpdateWithJobPage",
    meta: {
      module: "hive"
    },
    path: "/hive/projects/:id/:work_order/:job_id",
    component: () => import("@/views/app/pages/hive/projects/update")
  },

  /************* Work Orders ***********/
  {
    path: "/hive/:project_id/work_orders/new",
    component: () => import("@/views/app/pages/hive/work_orders/new"),
    meta: {
      title: `${i18n.t("general.new_suffix", { suffix: i18n.t("pages.hive.work_orders.forms.work_order") })}`
    }
  },
  {
    path: "/hive/work_orders/search",
    component: () => import("@/views/app/pages/hive/work_orders/search"),
    meta: {
      title: `${i18n.t("menu.work_orders")}`
    }
  },

  {
    path: "/hive/work_orders/:id",
    component: () => import("@/views/app/pages/hive/work_orders/update")
  },

  {
    path: "/hive/work_orders",
    redirect: "/hive/work_orders/search"
  },

  /******** Jobs  ***************/
  {
    path: "/hive/jobs/new/:projectId/:workOrderId",
    component: () => import("@/views/app/pages/hive/jobs/new"),
    meta: {
      title: i18n.t("pages.hive.jobs.forms.title-new"),
      backRouteName: "HiveProjectsUpdatePage",
      updateRouteName: "HiveJobsUpdatePage"
    }
  },
  {
    path: "/hive/jobs/new/quote/:projectId/:workOrderId",
    component: () => import("@/views/app/pages/hive/jobs/new"),
    meta: {
      title: i18n.t("pages.hive.jobs.forms.title-new"),
      backRouteName: 'HiveQuoteUpdatePage',
      updateRouteName: "HiveJobsUpdatePage"
    }
  },

  {
    name: "HiveJobsUpdatePage",
    path: "/hive/jobs/update/:id",
    component: () => import("@/views/app/pages/hive/jobs/update")
  },
  // Job Service - Installation

  /***************** Services *************** */
  {
    name: "ServiceJobListPage",
    path: "/hive/jobs/services",
    meta: {
      listPageTitleKey: "services",
      title: i18n.t("menu.services")
    },
    component: () => import("@/views/app/pages/hive/job_services/list")
  },

  {
    meta: {
      module: "hive",
      title: i18n.t("pages.hive.job_services.forms.title-new"),
      backRouteName: "HiveProjectsUpdatePage",
      updateRouteName: "ServiceJobUpdatePage"
    },
    path: "/hive/jobs/service/new/:projectId",
    component: () => import("@/views/app/pages/hive/job_services/new")
  },

  {
    meta: {
      module: "hive",
      title: i18n.t("pages.hive.job_services.forms.title-new"),
      backRouteName: "HiveQuoteUpdatePage",
      updateRouteName: "ServiceJobUpdatePage"
    },
    path: "/hive/quote/jobs/service/new/:projectId",
    component: () => import("@/views/app/pages/hive/job_services/new")
  },

  {
    name: "ServiceJobUpdatePage",
    meta: {
      module: "hive"
    },
    path: "/hive/jobs/service/:id",
    component: () => import("@/views/app/pages/hive/job_services/update")
  },
  {
    name: "HiveJobTemplatesListPage",
    path: "/hive/jobs/templates",
    meta: { listPage: "hive_job_templates", listPageTitleKey: "job_templates", title : i18n.t("menu.job_templates") },
    component: () => import("@/views/app/pages/hive/templates/list")
  },
  /******** Jobs  ***************/
  {
    name: "HiveJobTemplatesNewPage",
    path: "/hive/jobs/templates/new/",
    meta : {
      title : i18n.t('pages.hive.jobs.forms.template-title-new'),
      backRouteName: "HiveJobTemplatesListPage",
      updateRouteName: "HiveJobTemplatesUpdatePage"
    },
    component: () => import("@/views/app/pages/hive/jobs/new")
  },

  {
    name: "HiveJobTemplatesUpdatePage",
    path: "/hive/jobs/templates/:id",
    component: () => import("@/views/app/pages/hive/jobs/update")
  },

  /******** Quotes  ***************/
  {
    name: "HiveQuotesListPage",
    path: "/hive/quotes",
    meta: { listPage: "hive_quotes", listPageTitleKey: "quotes", title: i18n.t('menu.quotes') },
    component: () => import("@/views/app/pages/hive/quotes/list")
  },
  {
    name: "HiveQuotesNewPage",
    path: "/hive/quotes/new",
    meta : { title: i18n.t('pages.hive.quotes.forms.title-new') },
    component: () => import("@/views/app/pages/hive/projects/new")
  },
  {
    name: "HiveQuoteUpdatePage",
    meta: {
      module: "hive"
    },
    path: "/hive/quotes/:id",
    component: () => import("@/views/app/pages/hive/projects/update")
  },
  {
    path: "/hive/quotes/:id/:work_order",
    component: () => import("@/views/app/pages/hive/projects/update")
  }
];


export default routes;
