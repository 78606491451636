import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/lamcom/enfocus_switch/file_groups/",
    key: "file_group",
    fetchKey: "file_groups",
    permissionKey: ""
  },
  mutations: {},
  actions: {},
  getters: {}
};
