import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/inventory/product/re_packs/",
    key: "inventory_product_re_pack",
    fetchKey: "re_packs", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: "",
    statusList: [
      {
        name: "cancelled",
        text: i18n.t("pages.inventory.product_re_packs.statusList.cancelled"),
        color: "danger"
      },
      {
        name: "pending",
        text: i18n.t("pages.inventory.product_re_packs.statusList.pending"),
        color: "primary"
      },
      {
        name: "started",
        text: i18n.t("pages.inventory.product_re_packs.statusList.started"),
        color: "info"
      },
      {
        name: "completed",
        text: i18n.t("pages.inventory.product_re_packs.statusList.completed"),
        color: "success"
      }
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    statusList: state => state.statusList
  }
};
