import { i18n } from "@/utils/i18n";
import { securedAxios } from "@/utils/securedAxios";
import { isObject } from "lodash";
import localforage from "localforage";
import CryptoJS from "crypto-js";

export default {
  namespaced: true,
  state: {
    endpoint: "/templates/",
    endpoint_single: "/templates",
    key: "template",
    fetchKey: "templates",
    connect_to: [
      {
        name: "custom_form",
        id: "custom_form",
        text: i18n.t("pages.templates.connect_to.custom_form"),
        label: i18n.t("pages.templates.connect_to.custom_form"),
      },
      {
        name: "inventory_product_var",
        id: "inventory_product_var",
        text: i18n.t("pages.templates.connect_to.inventory_product_var"),
        label: i18n.t("pages.templates.connect_to.inventory_product_var")
      },
      {
        name: "logistic_fulfillment",
        id: "logistic_fulfillment",
        text: i18n.t("pages.templates.connect_to.logistic_fulfillment"),
        label: i18n.t("pages.templates.connect_to.logistic_fulfillment")
      },
      {
        name: "logistic_shipment",
        id: "logistic_shipment",
        text: i18n.t("pages.templates.connect_to.logistic_shipment"),
        label: i18n.t("pages.templates.connect_to.logistic_shipment")
      },
      {
        name: "logistic_transfer",
        id: "logistic_transfer",
        text: i18n.t("pages.templates.connect_to.logistic_transfer"),
        label: i18n.t("pages.templates.connect_to.logistic_transfer")
      },
      {
        name: "accounting_invoice_sale_final",
        id: "accounting_invoice_sale_final",
        text: i18n.t("pages.templates.connect_to.accounting_invoice_sale_final"),
        label: i18n.t("pages.templates.connect_to.accounting_invoice_sale_final")
      },
      {
        name: "accounting_invoice_sale_proforma",
        id: "accounting_invoice_sale_proforma",
        text: i18n.t("pages.templates.connect_to.accounting_invoice_sale_proforma"),
        label: i18n.t("pages.templates.connect_to.accounting_invoice_sale_proforma")
      },
      {
        name: "accounting_invoice_sale_quote",
        id: "accounting_invoice_sale_quote",
        text: i18n.t("pages.templates.connect_to.accounting_invoice_sale_quote"),
        label: i18n.t("pages.templates.connect_to.accounting_invoice_sale_quote")
      },
      {
        name: "accounting_invoice_purchase_final",
        id: "accounting_invoice_purchase_final",
        text: i18n.t("pages.templates.connect_to.accounting_invoice_purchase_final"),
        label: i18n.t("pages.templates.connect_to.accounting_invoice_purchase_final")
      },
      {
        name: "accounting_invoice_purchase_proforma",
        id: "accounting_invoice_purchase_proforma",
        text: i18n.t("pages.templates.connect_to.accounting_invoice_purchase_proforma"),
        label: i18n.t("pages.templates.connect_to.accounting_invoice_purchase_proforma")
      },
      {
        name: "hive_work_order",
        id: "hive_work_order",
        text: i18n.t("pages.templates.connect_to.hive_work_order"),
        label: i18n.t("pages.templates.connect_to.hive_work_order")
      },
      {
        name: "hive_b2b_new_user",
        id: "hive_b2b_new_user",
        text: i18n.t("pages.templates.connect_to.hive_b2b_new_user"),
        label: i18n.t("pages.templates.connect_to.hive_b2b_new_user")
      },
      {
        name: "hive_b2b_password_update",
        id: "hive_b2b_password_update",
        text: i18n.t("pages.templates.connect_to.hive_b2b_password_update"),
        label: i18n.t("pages.templates.connect_to.hive_b2b_password_update")
      },
      {
        name: "hive_b2b_password_reset",
        id: "hive_b2b_password_reset",
        text: i18n.t("pages.templates.connect_to.hive_b2b_password_reset"),
        label: i18n.t("pages.templates.connect_to.hive_b2b_password_reset")
      },
      {
        name: "hive_b2b_new_order",
        id: "hive_b2b_new_order",
        text: i18n.t("pages.templates.connect_to.hive_b2b_new_order"),
        label: i18n.t("pages.templates.connect_to.hive_b2b_new_order")
      },
      {
        name: "hive_b2b_order_complete",
        id: "hive_b2b_order_complete",
        text: i18n.t("pages.templates.connect_to.hive_b2b_order_complete"),
        label: i18n.t("pages.templates.connect_to.hive_b2b_order_complete")
      },

      {
        name: "hive_project",
        id: "hive_project",
        text: i18n.t("pages.templates.connect_to.hive_project"),
        label: i18n.t("pages.templates.connect_to.hive_project")
      },
      {
        name: "hive_job",
        id: "hive_job",
        text: i18n.t("pages.templates.connect_to.hive_job"),
        label: i18n.t("pages.templates.connect_to.hive_job")
      },
      {
        name: "hive_job_service",
        id: "hive_job_service",
        text: i18n.t("pages.templates.connect_to.hive_job_service"),
        label: i18n.t("pages.templates.connect_to.hive_job_service")
      },
      {
        name: "hive_quote",
        id: "hive_quote",
        text: i18n.t("pages.templates.connect_to.hive_quote"),
        label: i18n.t("pages.templates.connect_to.hive_quote")
      },
      {
        name: "hive_lamcom_layout_printing",
        id: "hive_lamcom_layout_printing",
        text: i18n.t("pages.templates.connect_to.hive_lamcom_layout_printing"),
        label: i18n.t("pages.templates.connect_to.hive_lamcom_layout_printing")
      },
      {
        name: "hive_lamcom_layout_cutting",
        id: "hive_lamcom_layout_cutting",
        text: i18n.t("pages.templates.connect_to.hive_lamcom_layout_cutting"),
        label: i18n.t("pages.templates.connect_to.hive_lamcom_layout_cutting")
      },
      {
        name: "hive_project_shipping_address",
        id: "hive_project_shipping_address",
        text: i18n.t("pages.templates.connect_to.hive_project_shipping_address"),
        label: i18n.t("pages.templates.connect_to.hive_project_shipping_address")
      },
      {
        name: "hive_project_billing_address",
        id: "hive_project_billing_address",
        text: i18n.t("pages.templates.connect_to.hive_project_billing_address"),
        label: i18n.t("pages.templates.connect_to.hive_project_billing_address")
      },
      {
        name: "customer",
        id: "customer",
        text: i18n.t("pages.templates.connect_to.customer"),
        label: i18n.t("pages.templates.connect_to.customer")
      },
      {
        name: "supplier",
        id: "supplier",
        text: i18n.t("pages.templates.connect_to.supplier"),
        label: i18n.t("pages.templates.connect_to.supplier")
      },
      {
        name: "accounting_credit",
        id: "accounting_credit",
        text: i18n.t("pages.templates.connect_to.accounting_credit"),
        label: i18n.t("pages.templates.connect_to.accounting_credit")
      },
      {
        name: "accounting_web_invoice",
        id: "accounting_web_invoice",
        text: i18n.t("pages.templates.connect_to.accounting_web_invoice"),
        label: i18n.t("pages.templates.connect_to.accounting_web_invoice")
      },
      {
        name: "accounting_web_invoice_confirmation",
        id: "accounting_web_invoice_confirmation",
        text: i18n.t("pages.templates.connect_to.accounting_web_invoice_confirmation"),
        label: i18n.t("pages.templates.connect_to.accounting_web_invoice_confirmation")
      },
      {
        name: "accounting_web_invoice_failure",
        id: "accounting_web_invoice_failure",
        text: i18n.t("pages.templates.connect_to.accounting_web_invoice_failure"),
        label: i18n.t("pages.templates.connect_to.accounting_web_invoice_failure")
      },
      {
        name: "karavan",
        id: "karavan",
        text: i18n.t("pages.templates.connect_to.karavan"),
        label: i18n.t("pages.templates.connect_to.karavan")
      },
      {
        name: "all",
        id: "all",
        text: i18n.t("pages.templates.connect_to.all"),
        label: i18n.t("pages.templates.connect_to.all"),
        default : true
      },
      {
        name: "maestro",
        id: "maestro",
        text: i18n.t("pages.templates.connect_to.maestro"),
        label: i18n.t("pages.templates.connect_to.maestro")
      }
    ],
    templateType: [
      {
        name: "email",
        id: "email",
        text: i18n.t("pages.templates.templateType.email"),
        label: i18n.t("pages.templates.templateType.email")
      },
      {
        name: "print",
        id: "print",
        text: i18n.t("pages.templates.templateType.print"),
        label: i18n.t("pages.templates.templateType.print")
      }
    ],

    karavan: {
      attachedToList: [
        {
          name: "accounting_invoice",
          id: "accounting_invoice",
          text: i18n.t("pages.templates.karavan.attachedToList.accounting_invoice"),
          label: i18n.t("pages.templates.karavan.attachedToList.accounting_invoice")
        },
        {
          name: "accounting_payment",
          id: "accounting_payment",
          text: i18n.t("pages.templates.karavan.attachedToList.accounting_payment"),
          label: i18n.t("pages.templates.karavan.attachedToList.accounting_payment")
        },
        {
          name: "gift_card",
          id: "gift_card",
          text: i18n.t("pages.templates.karavan.attachedToList.gift_card"),
          label: i18n.t("pages.templates.karavan.attachedToList.gift_card")
        },
        {
          name: "karavan_user",
          id: "karavan_user",
          text: i18n.t("pages.templates.karavan.attachedToList.karavan_user"),
          label: i18n.t("pages.templates.karavan.attachedToList.karavan_user")
        }
      ],
      actionAfterList: [
        {
          name: "create",
          id: "create",
          text: i18n.t("pages.templates.karavan.actionAfterList.create"),
          label: i18n.t("pages.templates.karavan.actionAfterList.create")
        },
        {
          name: "status_change",
          id: "status_change",
          text: i18n.t("pages.templates.karavan.actionAfterList.status_change"),
          label: i18n.t("pages.templates.karavan.actionAfterList.status_change")
        },
        {
          name: "other",
          id: "other",
          text: i18n.t("pages.templates.karavan.actionAfterList.other"),
          label: i18n.t("pages.templates.karavan.actionAfterList.other")
        }
      ],
      otherActionList: [
        {
          name: "non_processable",
          id: "non_processable",
          text: i18n.t("pages.templates.karavan.otherActionList.non_processable"),
          label: i18n.t("pages.templates.karavan.otherActionList.non_processable")
        },
        {
          name: "forgot_password",
          id: "forgot_password",
          text: i18n.t("pages.templates.karavan.otherActionList.forgot_password"),
          label: i18n.t("pages.templates.karavan.otherActionList.forgot_password")
        },
        {
          name: "failed_payment",
          id: "failed_payment",
          text: i18n.t("pages.templates.karavan.actionAfterList.failed_payment"),
          label: i18n.t("pages.templates.karavan.actionAfterList.failed_payment")
        },
      ]
    },
    activeType: "",
    permissionKey: "",
    saltRounds: 10,
    cryptPassword: "pur350l**//w3ae8"
  },
  actions: {
    fetchTemplates({ state }) {
      securedAxios.get("/templates?page_size=100", { params: { params: { connect_to: "" } } }).then(template_response => {
        if (isObject(template_response)) {
          const templateObject = {
            expire: new Date(new Date().getTime() + 86400000),
            templates: template_response?.data?.templates?.filter(template => template.active)
          };
          const cryptedTemplates = CryptoJS.AES.encrypt(JSON.stringify(templateObject), state.cryptPassword);
          localforage.setItem("print_templates", cryptedTemplates.toString());
        }
      });
    },
    initModules({ dispatch, state }) {
      localforage.getItem("print_templates").then(templates => {
        if (!templates) {
          dispatch("fetchTemplates");
        } else {
          const savedTemplates = JSON.parse(CryptoJS.AES.decrypt(templates?.toString(), state.cryptPassword).toString(CryptoJS.enc.Utf8));
          if (new Date(savedTemplates?.expire) <= new Date() || savedTemplates?.templates?.length === 0) {
            dispatch("fetchTemplates");
          }
        }
      });
    }
  },
  getters: {
    connectTo: (state, _, rootState) => state.connect_to
      .filter(i => {
        const B2B_KEYS = ["hive_b2b_new_user", "hive_b2b_password_update", "hive_b2b_password_reset", "hive_b2b_new_order"];
        const { user: { activeUser : { client }} } =  rootState ||{}
        const enabled = rootState?.hive_settings?.enabled
        const b2b_shop = rootState?.hive_settings?.b2b_shop
        if(enabled && b2b_shop) return i
        else return !B2B_KEYS.includes(i.id)
      })
      .sort((a, b) => ("" + a?.label).localeCompare(b?.label)),
    templateType: state => state.templateType,
    attachedToList: state => state.karavan.attachedToList,
    actionAfterList: state => state.karavan.actionAfterList,
    otherActionList: state => state.karavan.otherActionList
  }
};
