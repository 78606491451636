import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/accounting/payments/",
    key: "accounting_payment",
    fetchKey: "payments", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: "",
    typeList: [
      {
        name: "received",
        text: i18n.t("pages.accounting.payments.typeList.received")
      },
      {
        name: "sent",
        text: i18n.t("pages.accounting.payments.typeList.sent")
      }
    ],
    statusList: [
      {
        name: "processed",
        text: i18n.t("pages.accounting.payments.statusList.processed"),
        color: "success"
      },
      {
        name: "pending",
        text: i18n.t("pages.accounting.payments.statusList.pending"),
        color: "warning"
      },
      {
        name: "cancelled",
        text: i18n.t("pages.accounting.payments.statusList.cancelled"),
        color: "danger"
      }
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    typeList: state => state.typeList,
    statusList: state => state.statusList
  }
};
