import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    phoneTypeList: [
      {
        name: "home",
        text: i18n.t("components.phoneTypeList.home")
      },
      {
        name: "work",
        text: i18n.t("components.phoneTypeList.work")
      },
      {
        name: "mobile",
        text: i18n.t("components.phoneTypeList.mobile")
      }
    ],
    hiveStepsUnitList: [
      {
        name: "hour",
        text: i18n.t("components.hiveStepsUnitList.hours")
      },
      {
        name: "minute",
        text: i18n.t("components.hiveStepsUnitList.minute")
      }
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    phoneTypeList: state => state.phoneTypeList,
    hiveStepsUnitList: state => state.hiveStepsUnitList
  }
};
