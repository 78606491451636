import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/widgets/",
    key: "widget",
    fetchKey: "widgets", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: "",
    invoiced_total: {
      default_date_range: [
        {
          name: "this_month",
          id: "this_month",
          text: i18n.t("pages.widgets.enums.invoiced_total.default_date_range.this_month"),
          label: i18n.t("pages.widgets.enums.invoiced_total.default_date_range.this_month")
        },
        {
          name: "last_month",
          id: "last_month",
          text: i18n.t("pages.widgets.enums.invoiced_total.default_date_range.last_month"),
          label: i18n.t("pages.widgets.enums.invoiced_total.default_date_range.last_month")
        },
        {
          name: "last_year_this_month",
          id: "last_year_this_month",
          text: i18n.t("pages.widgets.enums.invoiced_total.default_date_range.last_year_this_month"),
          label: i18n.t("pages.widgets.enums.invoiced_total.default_date_range.last_year_this_month")
        }
      ],
    },
    logistic_shipment_calendar : {
      default_date_range: [
        {
          name: "today",
          id: "day",
          text: i18n.t("pages.widgets.enums.logistic_shipment_calendar.default_date_range.today"),
          label: i18n.t("pages.widgets.enums.logistic_shipment_calendar.default_date_range.today")
        },
        {
          name: "current_week",
          id: "week",
          text: i18n.t("pages.widgets.enums.logistic_shipment_calendar.default_date_range.current_week"),
          label: i18n.t("pages.widgets.enums.logistic_shipment_calendar.default_date_range.current_week")
        },
        {
          name: "current_month",
          id: "month",
          text: i18n.t("pages.widgets.enums.logistic_shipment_calendar.default_date_range.current_month"),
          label: i18n.t("pages.widgets.enums.logistic_shipment_calendar.default_date_range.current_month")
        },
      ]
    }
  },
  mutations: {},
  actions: {},
  getters: {
    invoiced_total_default_date_range: state => state.invoiced_total.default_date_range,
    logistic_shipment_calendar_default_date_range: state => state.logistic_shipment_calendar.default_date_range,
    sort_column_list: () => [
      {
        name: "created_at",
        id: "created_at",
        text: i18n.t("pages.widgets.enums.sort_column_list.created_at"),
        label: i18n.t("pages.widgets.enums.sort_column_list.created_at"),
      },
    ],
    sort_direction_list: () =>  [
      {
        name: "asc",
        id: "asc",
        text: i18n.t("pages.widgets.enums.sort_direction_list.asc"),
        label: i18n.t("pages.widgets.enums.sort_direction_list.asc"),
      },
      {
        name: "desc",
        id: "desc",
        text: i18n.t("pages.widgets.enums.sort_direction_list.desc"),
        label: i18n.t("pages.widgets.enums.sort_direction_list.desc"),
      },
    ],
  }
};
