import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/inventory/price/types/",
    key: "inventory_price_type",
    fetchKey: "types", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: "",
    types: [
      {
        name: "sale",
        id: "sale",
        text: i18n.t("pages.inventory.price-types.type.sale"),
        label: i18n.t("pages.inventory.price-types.type.sale")
      },
      {
        name: "purchase",
        id: "purchase",
        text: i18n.t("pages.inventory.price-types.type.purchase"),
        label: i18n.t("pages.inventory.price-types.type.purchase")
      },
      {
        name: "cost",
        id: "cost",
        text: i18n.t("pages.inventory.price-types.type.cost"),
        label: i18n.t("pages.inventory.price-types.type.cost")
      }
    ],
    price_source: [
      {
        name: "self",
        id: "self",
        text: i18n.t("pages.inventory.price-types.price_source.self"),
        label: i18n.t("pages.inventory.price-types.price_source.self")
      },
      {
        name: "last_invoice_price",
        id: "last_invoice_price",
        text: i18n.t("pages.inventory.price-types.price_source.last_invoice_price"),
        label: i18n.t("pages.inventory.price-types.price_source.last_invoice_price")
      }
    ],
    activeType: {},
    activeShippingAdditionType: {},
    activeOtherAdditionType: {}
  },
  actions: {},
  getters: {
    types: state => state.types,
    price_source: state => state.price_source
  }
};
