import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/accounting/taxes/",
    key: "accounting_tax",
    fetchKey: "taxes", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: "",
    tax_for: [
      {
        name: "purchase",
        text: i18n.t("pages.accounting.taxes.taxForList.purchase")
      },
      {
        name: "sale",
        text: i18n.t("pages.accounting.taxes.taxForList.sale")
      },
      {
        name: "all",
        text: i18n.t("pages.accounting.taxes.taxForList.all")
      }
    ],
    applicable_to: [
      {
        name: "country",
        text: i18n.t("pages.accounting.taxes.applicableToList.country")
      },
      {
        name: "state",
        text: i18n.t("pages.accounting.taxes.applicableToList.state")
      }
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    taxForList: state => state.tax_for,
    applicableToList: state => state.applicable_to
  }
};
