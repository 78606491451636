<template>
  <b-modal
    centered
    no-fade
    no-close-on-backdrop
    no-close-on-esc
    :title="$t('components.button_toolbar.tpa_stamps.title')"
    ref="modal"
    size="md"
    id="tpa_stamps"
    @close="resetData()"
    @hidden="resetData()"
    @show="showEvent"
  >
    <!-- main-content -->
    <b-row>
      <b-colxx sm="12" md="12" lg="12">
        <b-form-group :label="$t('components.button_toolbar.tpa_stamps.tpa_id')">
          <b-form-input :disabled="disabled" autocomplete="off" v-model="userData.tpa_id" size="sm" />
        </b-form-group>
      </b-colxx>
      <b-colxx sm="12" md="12" lg="12">
        <b-form-group :label="$t('components.button_toolbar.tpa_stamps.note')">
          <b-textarea :disabled="disabled" no-resize="" v-model="userData.note" size="sm"> </b-textarea>
        </b-form-group>
      </b-colxx>
    </b-row>
    <b-row v-if="show_confirmation_box">
      <b-colxx sm="12" md="12" lg="12">
        <b-alert show variant="warning">
          <span class="font-weight-bold">
            {{ $t("components.button_toolbar.tpa_stamps.are_you_sure") }}
          </span>
          <span class="text-danger font-weight-bolder">{{ confirmation_pin }}</span>
        </b-alert>
        <b-form-input
          v-model="confirmation_text"
          autocomplete="off"
          :placeholder="`${$t('components.button_toolbar.tpa_stamps.type')}${confirmation_pin}`"
          size="sm"
        />
      </b-colxx>
    </b-row>
    <!-- /main-content -->
    <template slot="modal-footer">
      <b-button class="default" variant="light" @click="cancel">{{ $t("controls.cancel_button") }}</b-button>
      <b-button v-if="!disabled" :disabled="show_confirmation_box && !confirmed" variant="primary" class="mr-1 default" @click="onSubmit">{{
        $t("controls.save_button")
      }}</b-button>
    </template>

    <template v-if="saveState">
      <div class="loading-container">
        <div class="loading"></div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import loadingMixin from "@/mixins/loadingMixin";
import { securedAxios } from "@/utils/securedAxios";

import { mapGetters } from "vuex";
import { isObject, isArray } from "lodash";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true
    },
    mainRoute: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    tpa_stamps: {
      type: [Object, null, undefined],
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mixins: [loadingMixin],
  data() {
    return {
      userData: {
        id: null,
        tpa_id: null,
        note: null
      },
      show_confirmation_box: false,
      confirmation_text: "",
      confirmation_pin: "iamawareofthat"
    };
  },

  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId == "tpa_stamps") {
        this.$emit("hideEvent", true);
      }
    });
  },
  methods: {
    showEvent() {},
    cancel() {
      this.resetData();
    },
    resetData() {
      this.userData = {
        id: null,
        tpa_id: null,
        note: null
      };
      this.show_confirmation_box = false;
      this.confirmation_text = "";

      this.$refs["modal"].hide();
    },
    onSubmit() {
      if (this.confirmed) {
        this.confirmAlert(this.$t("alert-box.confirm." + "wont-able-revert"), "continue").then(result => {
          if (result.value) {
            securedAxios
              .patch(`${this.mainRoute}${this.id}/update_tpa_stamp`, {
                // tpa_stamp_id: this.userData.id || null,
                tpa_sage_300: true,
                tpa_id: this.userData.tpa_id || null,
                note: this.userData.note || null
              })
              .then(tpa_update_response => {
                if (tpa_update_response.status == 200) {
                  this.createSuccessNotification("update");
                  setTimeout(() => {
                    window.location.reload();
                  }, 300);
                  this.resetData();
                } else {
                  this.createErrorNotification("update");
                }
              })
              .catch(e => {
                this.createErrorNotification("update");
              });
          }
        });
      } else {
        this.show_confirmation_box = true;
      }
    }
  },
  computed: {
    confirmed() {
      if (this.confirmation_pin == this.confirmation_text) {
        return true;
      }
      return false;
    }
  },
  watch: {
    show(value) {
      if (value) {
        if (isObject(this.tpa_stamps)) {
          this.userData = {
            id: this.tpa_stamps?.id,
            tpa_id: this.tpa_stamps?.tpa_id,
            note: this.tpa_stamps?.note
          };
        }
        this.$refs["modal"].show();
      }
    }
  }
};
</script>
