import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "../locales/en.json";
import fr from "../locales/fr.json";
import { defaultLocale, localeOptions } from "@/constants/config";

Vue.use(VueI18n);

const messages = { en: en, fr: fr };
const locale =
  localStorage.getItem("currentLanguage") && localeOptions.filter(x => x.id === localStorage.getItem("currentLanguage")).length > 0
    ? localStorage.getItem("currentLanguage")
    : defaultLocale;
export const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: "en",
  messages
});
