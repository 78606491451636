// ************ Custom Field Groups >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
import { i18n } from "@/utils/i18n";

const routes = [
  {
    path: "/custom/",
    redirect: {
      path: "/custom/field_groups"
    }
  },
  {
    name: "CustomFieldGroupListPage",

    path: "/custom/field_groups",
    meta: { listPage: "custom_field_groups", listPageTitleKey: "field_groups" },
    component: () => import("@/views/app/pages/custom/field_groups/list")
  },
  {
    name: "CustomFieldGroupNewPage",
    path: "/custom/field_groups/new",
    component: () => import("@/views/app/pages/custom/field_groups/new")
  },
  {
    name: "CustomFieldGroupUpdatePage",
    path: "/custom/field_groups/:id",
    component: () => import("@/views/app/pages/custom/field_groups/update")
  },
  {
    name: "CustomFieldTypeListPage",
    path: "/custom/field_groups/:groupId/types",
    meta: { listPage: "custom_field_types", listPageTitleKey: "field_types" },
    component: () => import("@/views/app/pages/custom/field_types/list")
  },

  // ************ Custom Field Types  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/custom/field_types",
    meta: { listPage: "custom_field_types", listPageTitleKey: "field_types" },
    component: () => import("@/views/app/pages/custom/field_types/list")
  },
  {
    name: "CustomFieldTypeNewPage",
    // path: "/custom/field_types/new",
    path: "/custom/field_types/:groupId/new",
    component: () => import("@/views/app/pages/custom/field_types/new")
  },
  {
    name: "CustomFieldTypeUpdatePage",
    path: "/custom/field_types/:id",
    component: () => import("@/views/app/pages/custom/field_types/update")
  },
  // ************ Custom Fields       >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    name: "CustomFieldListPage",
    path: "/custom/field_groups/:groupId/types/:typeId/fields",
    meta: { listPage: "custom_fields", listPageTitleKey: "fields" },
    component: () => import("@/views/app/pages/custom/fields/list")
  },
  {
    name: "CustomFieldNewPage",
    path: "/custom/fields/:groupId/:typeId/new",
    component: () => import("@/views/app/pages/custom/fields/new")
  },
  {
    name: "CustomFieldUpdatePage",
    path: "/custom/fields/:id",
    component: () => import("@/views/app/pages/custom/fields/update")
  },

  // ************ Custom Calculator   >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/custom/calculators",
    meta: { listPage: "custom_calculators", listPageTitleKey: "calculators" },
    component: () => import("@/views/app/pages/custom/calculators/list")
  },
  {
    path: "/custom/calculators/new",
    component: () => import("@/views/app/pages/custom/calculators/new")
  },
  {
    path: "/custom/calculators/:id",
    component: () => import("@/views/app/pages/custom/calculators/update")
  },

  // ************ Custom Form Models   >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/custom/form_models",
    meta: { listPage: "custom_form_models", listPageTitleKey: "form_models" },
    component: () => import("@/views/app/pages/custom/form_models/list")
  },
  {
    path: "/custom/form_models/new",
    component: () => import("@/views/app/pages/custom/form_models/new")
  },
  {
    path: "/custom/form_models/:id",
    component: () => import("@/views/app/pages/custom/form_models/update")
  },
  // ************ Custom Files   >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
];
export default routes;
