export default {
  namespaced: true,
  state: {
    endpoint: "/dashboards/",
    key: "dashboard",
    fetchKey: "dashboards", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: ""
  },
  mutations: {},
  actions: {},
  getters: {}
};
