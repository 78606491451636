<template>
  <b-button v-if="!useShortKey" class="default" :variant="variant" :to="to" :size="size">
    <i class="simple-icon-plus font-weight-bold"></i>
    {{ buttonText }}
  </b-button>
  <b-button v-else class="default" :variant="variant" :size="size" :to="to"
            v-shortkey="{ mac: ['meta', 'shift', 'd'], win: ['ctrl', 'shift', 'd'] }"
            @shortkey="$router.push(to);">
    <i class="simple-icon-plus font-weight-bold"></i>
    {{ buttonText }}
  </b-button>
</template>

<script>
export default {
  props: {
    to: {
      type: [String, Object],
      required: false
    },
    variant: {
      type: String,
      required: false,
      default: "primary"
    },
    size: {
      type: String,
      required: false,
      default: "sm"
    },
    text: {
      type: String,
      required: false,
      default: ""
    },
    callback: {
      type: Function,
      required: false
    },
    useShortKey: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    buttonText() {
      return this.text == "" ? this.$t("controls.add-new") : this.text;
    }
  }
};
</script>

<style></style>
