import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/client/custom/lamcom/hive/work_orders/",
    fetchKey: "work_orders",
    permissionKey: "",
    stepList: [
      {
        slug: "step_graphic_1_up",
        text: i18n.t("pages.client.custom.lamcom.hive.steps.stepStatusList.graphic_1_up"),
        color: "success"
      },
      {
        slug: "step_graphic_print_layout",
        text: i18n.t("pages.client.custom.lamcom.hive.steps.stepStatusList.graphic_print_layout"),
        color: "primary"
      }
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    stepList: state => state.stepList
  }
};
