const routes = [
  {
    path: "/users",
    name: 'UsersListPage',
    meta: { listPage: "users", listPageTitleKey: "users" },
    component: () => import("@/views/app/pages/users/list"),
  },
  {
    path: "/users/new",
    name: 'UsersCreatePage',
    component: () => import("@/views/app/pages/users/new"),
  },
  {
    path: "/users/:id",
    name: 'UsersUpdatePage',
    component: () => import("@/views/app/pages/users/update"),
  },
  {
    path: "/users/:id/dashboards",
    component: () => import("@/views/app/pages/users/dashboards"),
  },
  {
    name: "ForceToUpdatePasswordPage",
    path: "/update-password",
    component: () => import(/* webpackChunkName: "user" */ "@/views/app/pages/users/ForceToUpdatePassword"),
  },
];

export default routes;
