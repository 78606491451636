import { securedAxios } from "@/utils/securedAxios";
import CryptoJS from "crypto-js";
import localforage from "localforage";

export default {
  namespaced: true,
  state: {
    countryList: [],
    countries: [],
    states: [],
    endpoint: "/countries/",
    activeCountry: {},
    activeState: {},
    saltRounds: 10,
    cryptPassword: "pur350l**//w3ae8"
  },
  mutations: {
    setCountries(state, rows) {
      state.countries = rows;
    },
    setStates(state, states) {
      state.states = states;
    },
    setActiveCountry(state, activeCountry) {
      let index = state.countries.findIndex(c => {
        return c.code == activeCountry;
      });
      if (index >= 0) {
        state.activeCountry = state.countries[index];
      }
    },
    setActiveState(state, activeState) {
      let index = state.states.findIndex(s => {
        return s.code === activeState || s._id === activeState;
      });
      if (index >= 0) {
        state.activeState = state.states[index];
      }
    },
    setCountryList(state, countryList) {
      state.countryList = countryList;
    }
  },
  actions: {
    initCountryStateModule({ state, commit }) {
      return localforage.getItem("countryList").then(countryListValue => {
        //! Eğer Veri varsa ve Expire Süresi geçmemişse direk cache üzerinden veriyi gönder...
        if (new Date().getTime() < countryListValue?.expire) {
          commit("setCountryList", countryListValue?.items || []);
          return countryListValue?.items || [];
        } else {
          return securedAxios.get(state.endpoint).then(country_response => {
            let { countries } = country_response?.data;
            countries = countries?.map(country => ({ ...country, id: country._id, label: country.name }));
            //! Gelen Cevabı Cahce'e Yazdır..
            localforage.setItem("countryList", {
              expire: new Date().getTime() + 60 * 60 * 24 * 7 * 1000,
              items: countries || []
            });
            commit("setCountryList", countries || []);
            return countries || [];
          });
        }
      });
    },
    initModules({ state, commit }) {
      let isFetchedCountries = localStorage.countries;
      if (isFetchedCountries !== undefined) {
        let bytes = CryptoJS.AES.decrypt(localStorage.countries.toString(), state.cryptPassword);
        let countries = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        commit("setCountries", countries);
      } else {
        securedAxios.get(state.endpoint).then(response => {
          let countries = CryptoJS.AES.encrypt(JSON.stringify(response.data.countries), state.cryptPassword);
          localStorage.countries = countries.toString();
          commit("setCountries", response.data.countries);
        });
      }
    },
    setActiveCountry({ state, commit }, activeCountry) {
      commit("setActiveCountry", activeCountry);
      return securedAxios
        .get(state.endpoint + state.activeCountry._id)
        .then(response => {
          commit("setStates", response.data.states);
          return true;
        })
        .catch(e => {
          return e;
        });
    },
    setActiveState({ state, commit }, activeState) {
      commit("setActiveState", activeState);
    }
  },
  getters: {
    getRows(state) {
      return state.rows;
    },
    getTotalCount(state) {
      return state.totalCount;
    },
    stateList: state => state.states.sort((a, b) => ("" + a?.name).localeCompare(b?.name)),
    countryList: state => state.countries.sort((a, b) => ("" + a?.name).localeCompare(b?.name)),
    _countryList: state => state.countryList.sort((a, b) => ("" + a?.name).localeCompare(b?.name))
  }
};
