import { securedAxios } from "@/utils/securedAxios";
import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    tpaList: [],
    endpoint: "/tpa/apps",
    fetchKey : "tpa_apps",
    key : "app",
    statusList: [
      {
        name: "needs_update",
        id: "needs_update",
        text: i18n.t("pages.tpa.enums.statusList.needs_update"),
        label: i18n.t("pages.tpa.enums.statusList.needs_update"),
      },
      {
        name: "up_to_date",
        id: "up_to_date",
        text: i18n.t("pages.tpa.enums.statusList.up_to_date"),
        label: i18n.t("pages.tpa.enums.statusList.up_to_date"),
      },
      {
        name: "new_record",
        id: "new_record",
        text: i18n.t("pages.tpa.enums.statusList.new_record"),
        label: i18n.t("pages.tpa.enums.statusList.new_record"),
      }
    ],
    typeList: [
      {
        id: "enfocus_switch",
        label: i18n.t("pages.tpa.enums.typeList.enfocus_switch")
      },
      {
        id: "maestro_agent",
        label: i18n.t("pages.tpa.enums.typeList.maestro_agent")
      },
      {
        id: "qb_online",
        label: i18n.t("pages.tpa.enums.typeList.qb_online")
      },
      {
        id: "sage_300",
        label: i18n.t("pages.tpa.enums.typeList.sage_300")
      },
      {
        id: "pdf_approval",
        label: i18n.t("pages.tpa.enums.typeList.pdf_approval")
      },
      {
        id: "shopify",
        label: i18n.t("pages.tpa.enums.typeList.shopify")
      }
    ],
    qb_online: {
      syncList: [
        {
          id: "none",
          label: i18n.t("pages.tpa.enums.qb_online.syncList.none")
        },
        {
          id: "push",
          label: i18n.t("pages.tpa.enums.qb_online.syncList.push")
        },
        {
          id: "pull",
          label: i18n.t("pages.tpa.enums.qb_online.syncList.pull")
        },
        {
          id: "both_ways",
          label: i18n.t("pages.tpa.enums.qb_online.syncList.both_ways")
        }
      ],
      pushAtList: [
        {
          id: "create",
          label: i18n.t("pages.tpa.enums.qb_online.pushAtList.create"),
          available_for: [
            "contact_customer_push_at",
            "contact_supplier_push_at",
            "inventory_product_push_at",
            "accounting_invoice_sale_final_push_at",
            "accounting_invoice_purchase_final_push_at",
            "accounting_payment_send_push_at",
            "accounting_payment_receive_push_at"
          ]
        },
        {
          id: "status_in_stock",
          label: i18n.t("pages.tpa.enums.qb_online.pushAtList.status_in_stock"),
          available_for: ["inventory_product_push_at"]
        },
        {
          id: "status_completed",
          label: i18n.t("pages.tpa.enums.qb_online.pushAtList.status_completed"),
          available_for: [
            "accounting_invoice_sale_final_push_at",
            "accounting_invoice_purchase_final_push_at",
            "accounting_payment_send_push_at",
            "accounting_payment_receive_push_at"
          ]
        }
      ],
      deleteAtList: [
        {
          id: "de_activate",
          label: i18n.t("pages.tpa.enums.qb_online.deleteAtList.de_activate"),
          available_for: [
            "contact_customer_delete_at",
            "contact_supplier_delete_at",
            "inventory_product_delete_at"
          ]
        },
        {
          id: "status_sold_out",
          label: i18n.t("pages.tpa.enums.qb_online.deleteAtList.status_sold_out"),
          available_for: [
            "inventory_product_delete_at"
          ]
        }
      ],
      sourceList: [
        {
          id: "tpa",
          label: i18n.t("pages.tpa.enums.qb_online.sourceList.tpa"),
        },
        {
          id: "puresol",
          label: i18n.t("pages.tpa.enums.qb_online.sourceList.puresol"),
        }
      ]
    }
  },
  mutations: {
    setTPAList(state, pTPAList) {
      state.tpaList = pTPAList;
    }
  },
  actions: {
    initTpaList({ commit, state }) {
      if (state.tpaList?.length === 0) {
        return securedAxios.get(`${state.endpoint}/quick_search`).then(tpa_response => {
          const tpaList = tpa_response?.data?.tpa_apps || [];
          commit("setTPAList", tpaList);
          return new Promise((resolve, _) => resolve(tpaList));
        });
      } else {
        return new Promise((resolve, _) => resolve(state.tpaList));
      }
    }
  },
  getters: {
    tpaList: state => state.tpaList,
    hasQuickBook: state => Boolean(state.tpaList?.find(tpa => tpa.type === "qb_online")),
    statusList: state => state.statusList,
    typeList: state => state.typeList,
    qbOnlineSyncList : state => state.qb_online.syncList,
    qbOnlineSourceList : state => state.qb_online.sourceList,
    qbOnlinePushAtListForContact : state => state.qb_online.pushAtList.filter(pushAt => !!pushAt.available_for.filter(a => a.startsWith("contact_"))?.length),
    qbOnlineDeleteAtListForContact : state => state.qb_online.deleteAtList.filter(pushAt => !!pushAt.available_for.filter(a => a.startsWith("contact_"))?.length),
    qbOnlinePushAtListForInventoryProduct : state => state.qb_online.pushAtList.filter(pushAt => !!pushAt.available_for.filter(a => a.startsWith("inventory_product"))?.length),
    qbOnlineDeleteAtListForInventoryProduct : state => state.qb_online.deleteAtList.filter(pushAt => !!pushAt.available_for.filter(a => a.startsWith("inventory_product"))?.length),
    qbOnlinePushAtListForAccounting : state => state.qb_online.pushAtList.filter(pushAt => !!pushAt.available_for.filter(a => a.startsWith("accounting_"))?.length),
  }
};
