export default {
  namespaced: true,
  state: {
    endpoint: "/pigeon/notifications/",
    key: "notification",
    fetchKey: "notifications" // for getting all records from API. notifications : [{...},{...},{...}]
  },
  mutations: {},
  actions: {},
  getters: {}
};
