import Vue from "vue";
import Router from "vue-router";
import AuthRequired from "@/utils/AuthRequired";
// import helperMixin from "@/mixins/helperMixin";
import { store } from "@/store/index"
import { i18n } from "@/utils/i18n";

import PiafRoutes from "@/routes/Piaf";
/** Application Routes **/
import Webmasters from "@/routes/Webmasters";
import Clients from "@/routes/Clients";
import ClientSettings from "@/routes/ClientSettings";

import Users from "@/routes/Users";
import Locations from "@/routes/Locations";
import Custom from "@/routes/Custom";
import Contacts from "@/routes/Contacts";
import Accounting from "@/routes/Accounting";
import Inventory from "@/routes/Inventory";
import Taxons from "@/routes/Taxons";
import TpaApps from "@/routes/TpaApps";
import Templates from "@/routes/Templates";
import Logistics from "@/routes/Logistics";
import Dashboards from "@/routes/Dashboards";
import Activities from "@/routes/Activities";

import Marketing from "@/routes/Marketing";

import Karavan from "@/routes/Karavan";

// Routes...
import Reports from "@/routes/Reports";

/**** Hive *****/
import Hive from "@/routes/Hive";
import HiveB2BUsers from "@/routes/HiveB2BUsers";
/**** /Hive *****/
import Files from "@/routes/Files";

/*********** Client/Lamcom **************/
import Lamcom from "@/routes/Lamcom";
import PrepressRoutes from '@/routes/PrepressRoutes'
/*********** /Client/Lamcom **************/

Vue.use(Router);

let allRoutes = [];
let webMasterRoutes = [];
let clientRoutes = [];

webMasterRoutes = webMasterRoutes.concat(PiafRoutes, Webmasters, Clients);
clientRoutes = clientRoutes.concat(
  PiafRoutes,
  ClientSettings,
  Users,
  Locations,
  Custom,
  Contacts,
  Accounting,
  Inventory,
  Taxons,
  TpaApps,
  Logistics,
  Templates,
  Hive,
  HiveB2BUsers,
  Karavan,
  Reports
);

allRoutes = allRoutes.concat(
  PiafRoutes,
  Webmasters,
  Clients,
  ClientSettings,
  Users,
  Locations,
  Custom,
  Contacts,
  Accounting,
  Inventory,
  Taxons,
  TpaApps,
  Logistics,
  Dashboards,
  Templates,
  Hive,
  HiveB2BUsers,
  Files,
  Karavan,
  Lamcom,
  Activities,
  Reports,
  Marketing,
  PrepressRoutes
);

const routes = [
  {
    path: "/",
    name: "default",
    component: () => import(/* webpackChunkName: "appView" */ "./views/app"),
    redirect: "/dashboard",
    meta: {
      authRequired: true
    },
    children: allRoutes
  },
  {
    path: "/error",
    component: () => import(/* webpackChunkName: "error" */ "./views/Error")
  },
  {
    path: "/user",
    component: () => import(/* webpackChunkName: "user" */ "./views/auth"),
    redirect: { name: 'LoginPage' },
    children: [
      {
        name: "LoginPage",
        path: "login",
        component: () => import(/* webpackChunkName: "user" */ "./views/auth/Login"),
        meta: {
          guestRequired: true
        },
      },
      {
        name: "ForgotPasswordPage",
        path: "forgot-password",
        component: () => import(/* webpackChunkName: "user" */ "./views/auth/ForgotPassword"),
        meta: {
          guestRequired: true
        },
      },
      {
        name: "ResetPasswordPage",
        path: "/reset-password/:token",
        component: () => import(/* webpackChunkName: "user" */ "./views/auth/ResetPassword"),
        meta: {
          guestRequired: true
        },
      },
      {
        path: 'lwt',
        name: 'LoginWithToken',
        component: () => import(/* webpackChunkName: "user" */ './views/auth/LoginWithToken')
      },
    ]
  },
  {
    path: "/print_preview/:objectId/:templateId",
    component: () => import("./views/app/printing")
  },
  {
    name: "job_files",
    path: "/view_file_family/:file_id",
    component: () => import("@/views/app/view_files")
  },

  {
    path: "*",
    component: () => import(/* webpackChunkName: "error" */ "./views/Error")
  }
];

const router = new Router({
  linkActiveClass: "active",
  routes,
  mode: "hash"
});

router.beforeEach((to, from, next) => {
  document.title = "PureSOL";
  const savedDocs = store.getters.activeUser?.i18n_docs?.find(d => d.language === i18n.locale && d?.doc?.length > 10);
  if (savedDocs) {
    i18n.mergeLocaleMessage(i18n.locale, JSON.parse(savedDocs?.doc));
  }

  const userHasToChangePassword = localStorage.fup;
  if (userHasToChangePassword && userHasToChangePassword === "true" && to?.name !== "ForceToUpdatePasswordPage") {
    next({ name: "ForceToUpdatePasswordPage" });
    return false;
  }

  // Inventory Product Check
  if (to.fullPath.indexOf("inventory/products") > -1) {
    // localStorage.redirect_inventory_product_url = from.fullPath;
  }
  // Hive Projects Check
  if (to.fullPath.indexOf("hive/projects") > -1) {
    localStorage.redirect_project_url = from.fullPath;
  }

  if (to.fullPath.indexOf("/hive/jobs/templates") == -1) {
    delete localStorage.keep_browser;
  }

  AuthRequired(to, from, next);
});

export default router;
