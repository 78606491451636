export default {
  namespaced: true,
  state: {
    endpoint: "/custom/field_types/",
    key: "custom_field_type",
    fetchKey: "custom_field_types",
    fieldTypes: [
      "customer",
      "supplier",
      "inventory_product_var",
      "accounting_invoice",
      "custom_form",
      "fulfillment",
      "shipment",
      "accounting_purchase_order",
      "accounting_proforma_invoice",
      "accounting_invoice_purchase",
      ""
    ],
    activeFieldType: "",
    permissionKey: ""
  },
  mutations: {
    setActiveFieldType(state, activeFieldType) {
      let index = state.fieldTypes.indexOf(activeFieldType);
      if (index > -1) {
        state.activeFieldType = state.fieldTypes[index];
      }
    }
  },
  actions: {},
  getters: {
    getFieldTypes(state) {
      return state.fieldTypes;
    }
  }
};
