const loadingMixin = {
  data() {
    return {
      isLoad: false,
      saveState: false,
      allLoaded: false
    };
  },
  methods: {
    setInitialData() {
      setTimeout(() => {
        // initialData should equal to userData. Because beforeRouteLeave should use updated data.
        this.initialData = JSON.parse(JSON.stringify(this.userData));
        this.isLoad = true;
      }, 300);
    }
  },
  watch: {
    isLoad(isLoad) {
      if (isLoad) {
        this.setInitialData();
      }
    }
  }
};

export default loadingMixin;
