import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/client/custom/lamcom/accounting/",
    key: "",
    fetchKey: "",
    permissionKey: ""
  },
  mutations: {},
  actions: {},
  getters: {}
};
