export default {
  namespaced: true,
  state: {
    endpoint: "/client/custom/lamcom/hive/jobs/",
    key: "jobs",
    permissionKey: ""
  },
  mutations: {},
  actions: {},
  getters: {}
};
