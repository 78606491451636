import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/accounting/commissions/",
    key: "accounting_commission",
    fetchKey: "commissions", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: "",
    statusList: [
      {
        name: "pending",
        id: 'pending',
        text: i18n.t("pages.accounting.commissions.enums.statusList.pending"),
        label: i18n.t("pages.accounting.commissions.enums.statusList.pending"),
        variant: 'warning'
      },
      {
        name: "completed",
        id: 'completed',
        text: i18n.t("pages.accounting.commissions.enums.statusList.completed"),
        label: i18n.t("pages.accounting.commissions.enums.statusList.completed"),
        variant: 'success'
      }
    ],
    amountTypeList: [
      {
        name: "percentage",
        id: "percentage",
        text: i18n.t("pages.accounting.commissions.enums.amountTypeList.percentage"),
        label: i18n.t("pages.accounting.commissions.enums.amountTypeList.percentage")
      },
      {
        name: "money",
        id: "money",
        text: i18n.t("pages.accounting.commissions.enums.amountTypeList.money"),
        label: i18n.t("pages.accounting.commissions.enums.amountTypeList.money")
      }
    ],
    belongedObjectList: [
      {
        name: "contact",
        text: i18n.t("pages.accounting.commissions.enums.belongedObjectList.contact")
      },
      {
        name: "user",
        text: i18n.t("pages.accounting.commissions.enums.belongedObjectList.user")
      }
    ],
    connectToList: [
      {
        name: "accounting_invoice",
        text: i18n.t("pages.accounting.commissions.enums.connectToList.accounting_invoice")
      },
      {
        name: "hive_project",
        text: i18n.t("pages.accounting.commissions.enums.connectToList.hive_project")
      }
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    statusList: state => state.statusList,
    amountTypeList: state => state.amountTypeList,
    belongedObjectList: state => state.belongedObjectList,
    connectToList: state => state.connectToList
  }
};
