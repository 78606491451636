import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/karavan/shipment_services/",
    key: "shipment_service",
    fetchKey: "shipment_services",
    permissionKey: "",

    pricingTypeList: [
      {
        name: "fixed",
        text: i18n.t("pages.karavan.shipment_services.pricingTypeList.fixed")
      },
      {
        name: "calculated",
        text: i18n.t("pages.karavan.shipment_services.pricingTypeList.calculated")
      }
    ],
    eligibleToList: [
      {
        name: "price_range",
        text: i18n.t("pages.karavan.shipment_services.eligibleToList.price_range")
      },
      {
        name: "weight_range",
        text: i18n.t("pages.karavan.shipment_services.eligibleToList.weight_range")
      },
      {
        name: "all",
        text: i18n.t("pages.karavan.shipment_services.eligibleToList.all")
      }
    ],
    calculatorServiceList: [
      {
        name: "canada_post_expedited",
        text: i18n.t("pages.karavan.shipment_services.calculatorServiceList.canada_post_expedited")
      },
      {
        name: "canada_post_xpresspost",
        text: i18n.t("pages.karavan.shipment_services.calculatorServiceList.canada_post_xpresspost")
      }
    ],

    calculatorMarkupTypeList: [
      {
        name: "percentage",
        text: i18n.t("pages.karavan.shipment_services.calculatorMarkupType.percentage")
      },
      {
        name: "money",
        text: i18n.t("pages.karavan.shipment_services.calculatorMarkupType.money")
      }
    ]
  },
  actions: {},
  mutations: {},
  getters: {
    pricingTypeList: state => state.pricingTypeList,
    eligibleToList: state => state.eligibleToList,
    calculatorServiceList: state => state.calculatorServiceList,
    calculatorMarkupTypeList: state => state.calculatorMarkupTypeList
  }
};
