import { mapGetters } from "vuex";
import { isArray, isObject, isString } from "lodash";

const _permissionMixin = {
  methods: {
    passWithPermission(moduleName, action, createdByID, byPassAdminModeratorLevel){
      const matchedPermission = this.activeUser?.permissions_?.find(p => p.slug === `${moduleName}_${action}` && p.enabled === true);
      if (createdByID) {
        if (isObject(matchedPermission)) {
          if (matchedPermission?.permit_for === "all") return true;
          return matchedPermission?.permit_for === "only_by_me" && createdByID && this.activeUser.user.id === createdByID;
        }
      } else {
        return isObject(matchedPermission);
      }
    },
    _ableToPerformAction(moduleName, action, createdByID, byPassAdminModeratorLevel) {
      // return this.passWithPermission(moduleName, action, createdByID, byPassAdminModeratorLevel)
      const permissionCheck = !byPassAdminModeratorLevel && (this.activeUser.user.admin || this._isAdminRightForThisAction(moduleName) || this._isModeratorRightForThisAction(moduleName))
      if (permissionCheck)
        return true;
      else {
        return this.passWithPermission(moduleName, action, createdByID, byPassAdminModeratorLevel);
      }
    },
    _isAdminRightForThisAction(moduleName) {
      // return this.activeUser?.permissions_?.find((p) => p.slug === `${moduleName}_admin` && p.enabled === true);
      return this._isAdminOrWebMaster || this.activeUser?.permissions_?.find(p => p.slug === `${moduleName}_admin` && p.enabled === true);
    },
    _isModeratorRightForThisAction(moduleName) {
      // return this.activeUser?.permissions_?.find(p => p.slug === `${moduleName}_moderator` && p.enabled === true);
      return this._isAdminOrWebMaster || this.activeUser?.permissions_?.find(p => p.slug === `${moduleName}_moderator` && p.enabled === true);
    },
    _ableToView(modules) {
      return this._isAdminOrWebMaster || modules.some(module => {
        return (
          this.activeUser?.permissions_?.find(p => p.slug === `${module}_view_list` && p.enabled) ||
          this._isAdminRightForThisAction(module) ||
          this._isModeratorRightForThisAction(module)
        );
      });
    },
    _ableToEditSettings(modules) {
      if (this.activeUser.user.admin) {
        return true;
      }
      return modules.some(module => {
        return this.activeUser?.permissions_?.filter(p => p?.enabled)?.find(p => `${module}_admin` === p.slug);
      });
    },
    parseModule(moduleName, checkForClientSettings) {
      //! Burasi client_settings objesinin içindeki bölümleri yada root seviyesindeki belirli propertylerin kontrollerini yapmak için yapildi.
      const modules = moduleName?.split(".") || [];

      let clientSettings = this.activeUser?.client;
      modules?.forEach(module => {
        clientSettings = clientSettings?.[module];
      });

      //! Burasi cok tehlikeli. Bir Object üretilmemişse o zaman true dönüyor..
      if (checkForClientSettings && clientSettings === undefined) {
        return true;
      }
      // if (clientSettings === undefined) return true;

      if (clientSettings === true) return true;
      if (modules?.length > 1) return clientSettings?.enabled;
      else if (modules?.length === 1 && clientSettings?.enabled) return clientSettings?.enabled;
      else return modules?.length === 1 && clientSettings === true;
    },
    _isModuleEnabled(logic, moduleName, checkForClientSettings) {
      if (isString(moduleName)) {
        return Boolean(this.parseModule(moduleName, checkForClientSettings));
      } else if (isArray(moduleName)) {
        let enabledModules = [];
        moduleName?.forEach(module => {
          enabledModules.push(this.parseModule(module, checkForClientSettings));
        });

        if (logic === "every") {
          return enabledModules?.every(m => m);
        } else if (logic === "some") {
          return enabledModules?.some(m => m);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      activeUser: "activeUser"
    }),
    _permissionModuleMap() {
      return {
        hive_project: "hive_settings",
        hive_quote: "hive_settings",
        hive_template: "hive_settings",
        hive_B2B_users: "hive_settings",
        hive_prepress: "hive_settings",
        accounting: "accounting_settings",
        accounting_credit: ["accounting_settings.credit"],
        accounting_refund: ["accounting_settings.refund"],
        accounting_invoice_quote_sale: ["accounting_settings", "accounting_settings.quote"],
        accounting_invoice_final_sale: ["accounting_settings", "accounting_settings.invoice"],
        accounting_invoice_proforma_sale: ["accounting_settings", "accounting_settings.proforma_invoice"],
        accounting_invoice_final_purchase: ["accounting_settings", "accounting_settings.bill"],
        accounting_invoice_proforma_purchase: ["accounting_settings", "accounting_settings.purchase_order"],
        accounting_payment_sent: "accounting_settings",
        accounting_payment_receive: "accounting_settings",
        contact_customer: "contact_settings",
        contact_supplier: "contact_settings",
        logistic_shipment: ["logistic_settings", "logistic_settings.logistic_shipment"],
        logistic_fulfillment: ["logistic_settings", "logistic_settings.logistic_fulfillment"],
        logistic_transfer: ["logistic_settings", "logistic_settings.logistic_transfer"],
        tpa: "tpa_settings",
        inventory_product_type: "inventory_settings",
        inventory_product_category: "inventory_settings",
        inventory_product_var_option: "inventory_settings",
        inventory_product_price_type: "inventory_settings",
        inventory_product: "inventory_settings",
        maestro_taxon: "maestro_settings",
        maestro_file: "maestro_settings",
        maestro_job: "maestro_settings",
        maestro_layout: "maestro_settings",
        karavan_order: "karavan_settings",
        karavan_refund: "karavan_settings",
        client_custom_lamcom_reports: "client_custom_lamcom_reports",
        client_custom_lamcom_hive_project: "hive_settings"
      };
    },
    _isWebMasterUser() {
      const { webmaster } = this.activeUser?.user;
      return webmaster;
    },
    _isAdminOrWebMaster() {
      const { admin, webmaster } = this.activeUser?.user;
      return admin || webmaster;
    }
  }
};

export default _permissionMixin;
