import { i18n } from "@/utils/i18n";
import { securedAxios } from "@/utils/securedAxios";
import { isObject } from "lodash";
import localforage from "localforage";
import CryptoJS from "crypto-js";

export default {
  namespaced: true,
  state: {
    endpoint: "/users/",
    key: "user",
    fetchKey: "users", // for getting all records from API. clients : [{...},{...},{...}],
    permissionKey: "general.users",
    permission_group_mapping: {
      settings: "general",
      users: "general",
      accounting_invoice_proforma_purchase: "accounting",
      accounting_invoice_final_purchase: "accounting",
      accounting_invoice_proforma_sale: "accounting",
      accounting_invoice_final_sale: "accounting",
      accounting_payments: "accounting",
      accounting_settings: "accounting",
      contact_customers: "contact",
      contact_suppliers: "contact",
      contact_settings: "contact",
      inventory_products: "inventory",
      inventory_product_reserves: "inventory",
      inventory_product_re_packs: "inventory",
      inventory_settings: "inventory",
      logistic_shipments: "logistic",
      logistic_fulfillments: "logistic",
      logistic_transfers: "logistic",
      logistic_settings: "logistic"
    },
    preferredLanguageList: [
      {
        id: "en",
        label: i18n.t("pages.users.preferredLanguageList.en")
      },
      {
        id: "fr",
        label: i18n.t("pages.users.preferredLanguageList.fr")
      },
    ],
    cryptPassword: "pur350l**//w3ae8",

    //! For new user available Control
    usersNewResponse: {}
  },
  mutations: {
    setUsersNewResponse(state, payload) {
      state.usersNewResponse = payload
    }
  },
  actions: {
    fetchUsers({ state }) {
      securedAxios
        .get(state.endpoint, { params: { page_size: 1000 } })
        .then(user_response => {
          if (isObject(user_response)) {
            const usersObject = {
              expire: new Date(new Date().getTime() + 86400000),
              users: user_response?.data?.users
            };
            const cryptedUsers = CryptoJS.AES.encrypt(JSON.stringify(usersObject), state.cryptPassword);
            localforage.setItem("users", cryptedUsers.toString());
          }
        })
        .catch(e => this.createErrorNotification("read", e));
    },
    initModules({ dispatch, state }) {
      localforage.getItem("users").then(users => {
        if (!users) {
          dispatch("fetchUsers");
        } else {
          const savedUsers = JSON.parse(CryptoJS.AES.decrypt(users?.toString(), state.cryptPassword).toString(CryptoJS.enc.Utf8));
          if (new Date(savedUsers?.expire) <= new Date() || savedUsers?.templates?.length === 0) {
            dispatch("fetchUsers");
          }
        }
      });
    },
    checkNewUserAvailable({ state, commit }) {
      return securedAxios.get(`${state.endpoint}/new`)
        .then(({ data }) => {
          commit('setUsersNewResponse', data)
        })
    }
  },
  getters: {
    preferredLanguageList: state => state.preferredLanguageList,
    newUserAvailable: state => state.usersNewResponse?.new_allowed
  }
};
