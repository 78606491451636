<template>
  <b-row :class="{ 'mt-4 mb-5': !margin_none }">
    <b-colxx sm="12" md="12" lg="12">
      <h3 class="line-title text-muted">
        <span :style="{ backgroundColor: backgroundColor }">{{ title }}</span>
      </h3>
    </b-colxx>
  </b-row>
</template>
<script>
export default {
  props: {
    title: {
      type: [String, null],
      required: true,
    },
    margin_none: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#fff",
    },
  },
};
</script>
<style scoped>
.line-title {
  border-bottom: 1px solid #ddd;
  font-size: 20px;
  height: 12px;
  margin-bottom: 10px;
}

.line-title span {
  background: #fff;
  padding-right: 10px;
}
</style>
