import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    statusList: [
      {
        id: "not_submitted",
        label: i18n.t("pages.maestro.statuses.not_submitted"),
        available_in: ["job"]
      },
      {
        id: "waiting_for_approval",
        label: i18n.t("pages.maestro.statuses.waiting_for_approval"),
        available_in: ["job"]
      },
      {
        id: "refused",
        label: i18n.t("pages.maestro.statuses.refused")
      },
      {
        id: "error",
        label: i18n.t("pages.maestro.statuses.error")
      },
      {
        id: "ready",
        label: i18n.t("pages.maestro.statuses.ready")
      },
      {
        id: "processing",
        label: i18n.t("pages.maestro.statuses.processing")
      },
      {
        id: "waiting_processing",
        label: i18n.t("pages.maestro.statuses.waiting_processing")
      },
      {
        id: "waiting_internal_approval",
        label: i18n.t("pages.maestro.statuses.waiting_internal_approval")
      },
      {
        id: "waiting_external_approval",
        label: i18n.t("pages.maestro.statuses.waiting_external_approval")
      },
      {
        id: "waiting",
        label: i18n.t("pages.maestro.statuses.waiting")
      },
      {
        id: "submitted",
        label: i18n.t("pages.maestro.statuses.submitted")
      },
      {
        id: "ready_for_layout",
        label: i18n.t("pages.maestro.statuses.ready_for_layout")
      },
      {
        id: "processing_error",
        label: i18n.t("pages.maestro.statuses.processing_error")
      },
      {
        id: "preflighted",
        label: i18n.t("pages.maestro.statuses.preflighted")
      },
      {
        id: "canceled",
        label: i18n.t("pages.maestro.statuses.canceled")
      },
      {
        id: "active",
        label: i18n.t("pages.maestro.statuses.active")
      },
      {
        id: "transferring",
        label: i18n.t("pages.maestro.statuses.transferring")
      }
    ],
    hasLayoutList: [
      {
        id: "all",
        label: i18n.t("pages.maestro.hasLayoutList.all"),
      },
      {
        id: "true",
        label: i18n.t("pages.maestro.hasLayoutList.yes"),
      },
      {
        id: "false",
        label: i18n.t("pages.maestro.hasLayoutList.no"),
      }
    ],
    approvalStatusList: [
      {
        id: "not_required",
        label: i18n.t("pages.maestro.approvalStatuses.not_required")
      },
      {
        id: "required",
        label: i18n.t("pages.maestro.approvalStatuses.required")
      },
      {
        id: "verification_ready",
        label: i18n.t("pages.maestro.approvalStatuses.verification_ready")
      },
      {
        id: "pending_approval",
        label: i18n.t("pages.maestro.approvalStatuses.pending_approval")
      },
      {
        id: "rejected",
        label: i18n.t("pages.maestro.approvalStatuses.rejected")
      },
      {
        id: "approved",
        label: i18n.t("pages.maestro.approvalStatuses.approved")
      },
      {
        id: "skipped",
        label: i18n.t("pages.maestro.approvalStatuses.skipped")
      },
      {
        id: "expired",
        label: i18n.t("pages.maestro.approvalStatuses.expired")
      }
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    statusList: state => state.statusList,
    jobStatusList: state => state.statusList?.filter(s => [
      "not_submitted",
      "waiting_for_approval",
      "refused",
      "error",
      "ready",
      "processing"
    ].includes(s.id)),
    layoutStatusList: state => state.statusList?.filter(s => [
      "not_submitted",
      "error",
      "ready"
    ].includes(s.id)),
    agentStatusList: state => state.statusList?.filter(s => [
      "not_submitted",
      "transferring",
      "submitted"
    ].includes(s.id)),
    approvalStatusList: state => state.approvalStatusList,
    hasLayoutList: state => state.hasLayoutList
  }
};
