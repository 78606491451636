import { i18n } from "@/utils/i18n";
import { store } from "../index";
import { securedAxios } from "@/utils/securedAxios";

export default {
  namespaced: true,
  state: {
    endpoint: "/taxons/",
    key: "taxon",
    fetchKey: "taxons",
    taxonTypes: [
      {
        name: "inventory_product_group",
        id: "inventory_product_group",
        text: i18n.t("pages.taxons.typeList.inventory_product_group"),
        label: i18n.t("pages.taxons.typeList.inventory_product_group"),
        module: "inventory_product_group",
        belong_to: "puresol"
      },
      {
        name: "inventory_product_type_group",
        id: "inventory_product_type_group",
        text: i18n.t("pages.taxons.typeList.inventory_product_type_group"),
        label: i18n.t("pages.taxons.typeList.inventory_product_type_group"),
        module: "inventory_product_type_group",
        belong_to: "puresol"
      },
      {
        name: "user_group",
        id: "user_group",
        text: i18n.t("pages.taxons.typeList.user_group"),
        label: i18n.t("pages.taxons.typeList.user_group"),
        module: "user_group",
        belong_to: "puresol"
      },
      {
        name: "user_representative_role",
        id: "user_representative_role",
        text: i18n.t("pages.taxons.typeList.user_representative_role"),
        label: i18n.t("pages.taxons.typeList.user_representative_role"),
        module: "user_representative_role",
        belong_to: "puresol"
      },
      {
        name: "contact_customer_group",
        id: "contact_customer_group",
        text: i18n.t("pages.taxons.typeList.contact_customer_group"),
        label: i18n.t("pages.taxons.typeList.contact_customer_group"),
        module: "customer",
        belong_to: "puresol"
      },
      {
        name: "contact_contact_person_type",
        id: "contact_contact_person_type",
        text: i18n.t("pages.taxons.typeList.contact_contact_person_type"),
        label: i18n.t("pages.taxons.typeList.contact_contact_person_type"),
        module: "contact_contact_person_type",
        belong_to: "puresol"
      },
      {
        name: "contact_customer_rating",
        id: "contact_customer_rating",
        text: i18n.t("pages.taxons.typeList.contact_customer_rating"),
        label: i18n.t("pages.taxons.typeList.contact_customer_rating"),
        module: "contact_customer_rating",
        belong_to: "puresol"
      },
      {
        name: "contact_supplier_group",
        id: "contact_supplier_group",
        text: i18n.t("pages.taxons.typeList.contact_supplier_group"),
        label: i18n.t("pages.taxons.typeList.contact_supplier_group"),
        module: "supplier",
        belong_to: "puresol"
      },
      {
        name: "karavan_product_group",
        id: "karavan_product_group",
        text: i18n.t("pages.taxons.typeList.karavan_product_group"),
        label: i18n.t("pages.taxons.typeList.karavan_product_group"),
        module: "karavan_product_group",
        belong_to: "puresol"
      },
      {
        name: "user_representative",
        id: "user_representative",
        text: i18n.t("pages.taxons.typeList.user_representative"),
        label: i18n.t("pages.taxons.typeList.user_representative"),
        module: "user_representative",
        belong_to: "puresol"
      },
      {
        name: "hive_project_job",
        id: "hive_project_job",
        text: i18n.t("pages.taxons.typeList.hive_project_job"),
        label: i18n.t("pages.taxons.typeList.hive_project_job"),
        module: "hive_project_job",
        belong_to: "puresol"
      },
      {
        name: "hive_job_field_group",
        id: "hive_job_field_group",
        text: i18n.t("pages.taxons.typeList.hive_job_field_group"),
        label: i18n.t("pages.taxons.typeList.hive_job_field_group"),
        module: "hive_job_field_group",
        belong_to: "puresol"
      },
      {
        name: "hive_job_template_category",
        id: "hive_job_template_category",
        text: i18n.t("pages.taxons.typeList.hive_job_template_category"),
        label: i18n.t("pages.taxons.typeList.hive_job_template_category"),
        module: "hive_job_template_category",
        belong_to: "puresol"
      },
      {
        name: "lamcom_devices",
        id: "lamcom_devices",
        text: i18n.t("pages.taxons.typeList.lamcom_devices"),
        label: i18n.t("pages.taxons.typeList.lamcom_devices"),
        module: "lamcom_devices",
        belong_to: "lamcom_v2"
      },
      {
        name: "hive_work_order_redo_reason",
        id: "hive_work_order_redo_reason",
        text: i18n.t("pages.taxons.typeList.hive_work_order_redo_reason"),
        label: i18n.t("pages.taxons.typeList.hive_work_order_redo_reason"),
        module: "hive_work_order_redo_reason",
        belong_to: "puresol"
      },
      {
        name: "accounting_payment_term",
        id: "accounting_payment_term",
        text: i18n.t("pages.taxons.typeList.accounting_payment_term"),
        label: i18n.t("pages.taxons.typeList.accounting_payment_term"),
        module: "accounting_payment_term",
        belong_to: "puresol"
      },
      {
        name: "accounting_payment_method",
        id: "accounting_payment_method",
        text: i18n.t("pages.taxons.typeList.accounting_payment_method"),
        label: i18n.t("pages.taxons.typeList.accounting_payment_method"),
        module: "accounting_payment_method",
        belong_to: "puresol"
      },
      {
        name: "accounting_gl_account",
        id: "accounting_gl_account",
        text: i18n.t("pages.taxons.typeList.accounting_gl_account"),
        label: i18n.t("pages.taxons.typeList.accounting_gl_account"),
        module: "accounting_gl_account",
        belong_to: "puresol"
      },
      {
        name: "custom_field_group",
        id: "custom_field_group",
        text: i18n.t("pages.taxons.typeList.custom_field_group"),
        label: i18n.t("pages.taxons.typeList.custom_field_group"),
        module: "custom_field_group",
        belong_to: "puresol"
      },
      {
        name: "custom_field_smart_ddl_group",
        id: "custom_field_smart_ddl_group",
        text: i18n.t("pages.taxons.typeList.custom_field_smart_ddl_group"),
        label: i18n.t("pages.taxons.typeList.custom_field_smart_ddl_group"),
        module: "custom_field_smart_ddl_group",
        belong_to: "puresol"
      },
      {
        name: "user_permission",
        id: "user_permission",
        text: i18n.t("pages.taxons.typeList.user_permission"),
        label: i18n.t("pages.taxons.typeList.user_permission"),
        module: "user_permission",
        belong_to: "puresol"
      },
      {
        name: "permission_groups",
        id: "permission_groups",
        text: i18n.t("pages.taxons.typeList.permission_groups"),
        label: i18n.t("pages.taxons.typeList.permission_groups"),
        module: "permission_groups",
        belong_to: "puresol"
      },
      {
        name: "tax_group",
        id: "tax_group",
        text: i18n.t("pages.taxons.typeList.tax_group"),
        label: i18n.t("pages.taxons.typeList.tax_group"),
        module: "tax_group",
        belong_to: "puresol"
      },
      {
        name: "carrier",
        id: "carrier",
        text: i18n.t("pages.taxons.typeList.carrier"),
        label: i18n.t("pages.taxons.typeList.carrier"),
        module: "carrier",
        belong_to: "puresol"
      },
      {
        name: "inventory_product_brand",
        id: "inventory_product_brand",
        text: i18n.t("pages.taxons.typeList.inventory_product_brand"),
        label: i18n.t("pages.taxons.typeList.inventory_product_brand"),
        module: "inventory_product_brand",
        belong_to: "puresol"
      },
      {
        name: "prepress_tag",
        id: "prepress_tag",
        text: i18n.t("pages.taxons.typeList.prepress_tag"),
        label: i18n.t("pages.taxons.typeList.prepress_tag"),
        module: "prepress_tag",
        belong_to: "puresol"
      },
    ],
    // Accounting Payment Method...
    availableFor: [
      {
        id: "advance_payment",
        name: "advance_payment",
        text: i18n.t("pages.taxons.accountingPaymentMethod.available_for.items.advance_payment"),
        label: i18n.t("pages.taxons.accountingPaymentMethod.available_for.items.advance_payment")
      },
      {
        id: "applied_payment",
        name: "applied_payment",
        text: i18n.t("pages.taxons.accountingPaymentMethod.available_for.items.applied_payment"),
        label: i18n.t("pages.taxons.accountingPaymentMethod.available_for.items.applied_payment")
      },
      {
        id: "all",
        name: "all",
        text: i18n.t("pages.taxons.accountingPaymentMethod.available_for.items.all"),
        label: i18n.t("pages.taxons.accountingPaymentMethod.available_for.items.all"),
      }
    ],
    availableForTrade: [
      {
        id: "sale",
        name: "sale",
        text: i18n.t("pages.taxons.accountingPaymentMethod.available_for_trade.items.sale"),
        label: i18n.t("pages.taxons.accountingPaymentMethod.available_for_trade.items.sale")
      },
      {
        id: "purchase",
        name: "purchase",
        text: i18n.t("pages.taxons.accountingPaymentMethod.available_for_trade.items.purchase"),
        label: i18n.t("pages.taxons.accountingPaymentMethod.available_for_trade.items.purchase")
      },
      {
        id: "all",
        name: "all",
        text: i18n.t("pages.taxons.accountingPaymentMethod.available_for_trade.items.all"),
        label: i18n.t("pages.taxons.accountingPaymentMethod.available_for_trade.items.all")
      }
    ],
    availableForPlatform: [
      {
        id: "frontend",
        name: "frontend",
        text: i18n.t("pages.taxons.accountingPaymentMethod.available_for_platform.items.frontend"),
        label: i18n.t("pages.taxons.accountingPaymentMethod.available_for_platform.items.frontend")
      },
      {
        id: "backend",
        name: "backend",
        text: i18n.t("pages.taxons.accountingPaymentMethod.available_for_platform.items.backend"),
        label: i18n.t("pages.taxons.accountingPaymentMethod.available_for_platform.items.backend")
      },
      {
        id: "all",
        name: "all",
        text: i18n.t("pages.taxons.accountingPaymentMethod.available_for_platform.items.all"),
        label: i18n.t("pages.taxons.accountingPaymentMethod.available_for_platform.items.all")
      }
    ],
    paymentProviderType: [
      {
        id: "puresol",
        name: "puresol",
        text: i18n.t("pages.taxons.accountingPaymentMethod.payment_provider_type.items.puresol"),
        label: i18n.t("pages.taxons.accountingPaymentMethod.payment_provider_type.items.puresol"),
      },
      {
        id: "gift_card",
        name: "gift_card",
        text: i18n.t("pages.taxons.accountingPaymentMethod.payment_provider_type.items.gift_card"),
        label: i18n.t("pages.taxons.accountingPaymentMethod.payment_provider_type.items.gift_card"),
      },
      {
        id: "other",
        name: "other",
        text: i18n.t("pages.taxons.accountingPaymentMethod.payment_provider_type.items.other"),
        label: i18n.t("pages.taxons.accountingPaymentMethod.payment_provider_type.items.other"),
      }
    ],
    puresol_payment_provider: [
      {
        id: "authorizenet",
        name: "authorizenet",
        text: i18n.t("pages.taxons.accountingPaymentMethod.puresol_payment_provider.items.authorizenet"),
        label: i18n.t("pages.taxons.accountingPaymentMethod.puresol_payment_provider.items.authorizenet"),
      },
      {
        id: "paypal",
        name: "paypal",
        text: i18n.t("pages.taxons.accountingPaymentMethod.puresol_payment_provider.items.paypal"),
        label: i18n.t("pages.taxons.accountingPaymentMethod.puresol_payment_provider.items.paypal"),
      }
    ],
    // Accounting Payment Term
    term_length_type: [
      {
        name: "day",
        id: "day",
        text: i18n.t("pages.taxons.accountingPaymentTerm.term_length_type.items.day"),
        label: i18n.t("pages.taxons.accountingPaymentTerm.term_length_type.items.day")
      },
      {
        name: "week",
        id: "week",
        text: i18n.t("pages.taxons.accountingPaymentTerm.term_length_type.items.week"),
        label: i18n.t("pages.taxons.accountingPaymentTerm.term_length_type.items.week")
      },
      {
        name: "month",
        id: "month",
        text: i18n.t("pages.taxons.accountingPaymentTerm.term_length_type.items.month"),
        label: i18n.t("pages.taxons.accountingPaymentTerm.term_length_type.items.month")
      }
    ],
    applicable_to: [
      {
        name: "all",
        id: "all",
        text: i18n.t("pages.taxons.accountingPaymentTerm.applicable_to.items.all"),
        label: i18n.t("pages.taxons.accountingPaymentTerm.applicable_to.items.all"),
        default: true
      },
      {
        name: "purchases",
        id: "purchases",
        text: i18n.t("pages.taxons.accountingPaymentTerm.applicable_to.items.purchases"),
        label: i18n.t("pages.taxons.accountingPaymentTerm.applicable_to.items.purchases")
      },
      {
        name: "sales",
        id: "sales",
        text: i18n.t("pages.taxons.accountingPaymentTerm.applicable_to.items.sales"),
        label: i18n.t("pages.taxons.accountingPaymentTerm.applicable_to.items.sales")
      }
    ],
    before_after: [
      {
        name: "before",
        id: "before",
        text: i18n.t("pages.taxons.accountingPaymentTerm.before_after.items.before"),
        label: i18n.t("pages.taxons.accountingPaymentTerm.before_after.items.before")
      },
      {
        name: "after",
        id: "after",
        text: i18n.t("pages.taxons.accountingPaymentTerm.before_after.items.after"),
        label: i18n.t("pages.taxons.accountingPaymentTerm.before_after.items.after")
      }
    ],
    term_starter: [
      {
        name: "invoice_date",
        id: "invoice_date",
        text: i18n.t("pages.taxons.accountingPaymentTerm.term_starter.items.invoice_date"),
        label: i18n.t("pages.taxons.accountingPaymentTerm.term_starter.items.invoice_date")
      }
      // {
      //   name: "shipment_sent_date",
      //   text: i18n.t("pages.taxons.accountingPaymentTerm.term_starter.items.shipment_sent_date")
      // },
      // {
      //   name: "shipment_received_date",
      //   text: i18n.t("pages.taxons.accountingPaymentTerm.term_starter.items.shipment_received_date")
      // }
    ],
    accounting_gl_account_type: [
      {
        name: "asset",
        id: "asset",
        text: i18n.t("pages.taxons.accounting_gl_account.accounting_gl_account_type.asset"),
        label: i18n.t("pages.taxons.accounting_gl_account.accounting_gl_account_type.asset")
      },
      {
        name: "equity",
        id: "equity",
        text: i18n.t("pages.taxons.accounting_gl_account.accounting_gl_account_type.equity"),
        label: i18n.t("pages.taxons.accounting_gl_account.accounting_gl_account_type.equity")
      },
      {
        name: "expense",
        id: "expense",
        text: i18n.t("pages.taxons.accounting_gl_account.accounting_gl_account_type.expense"),
        label: i18n.t("pages.taxons.accounting_gl_account.accounting_gl_account_type.expense")
      },
      {
        name: "liability",
        id: "liability",
        text: i18n.t("pages.taxons.accounting_gl_account.accounting_gl_account_type.liability"),
        label: i18n.t("pages.taxons.accounting_gl_account.accounting_gl_account_type.liability")
      },
      {
        name: "revenue",
        id: "revenue",
        text: i18n.t("pages.taxons.accounting_gl_account.accounting_gl_account_type.revenue"),
        label: i18n.t("pages.taxons.accounting_gl_account.accounting_gl_account_type.revenue")
      }
    ],
    type_for: [
      {
        id: "all",
        name: "all",
        text: i18n.t("pages.taxons.contact_contact_person_type.type_for.all"),
        label: i18n.t("pages.taxons.contact_contact_person_type.type_for.all"),
      },
      {
        id: "customers",
        name: "customers",
        text: i18n.t("pages.taxons.contact_contact_person_type.type_for.customers"),
        label: i18n.t("pages.taxons.contact_contact_person_type.type_for.customers"),
      },
      {
        id: "suppliers",
        name: "suppliers",
        text: i18n.t("pages.taxons.contact_contact_person_type.type_for.suppliers"),
        label: i18n.t("pages.taxons.contact_contact_person_type.type_for.suppliers"),
      }
    ],
    enable_for_permit_list: [
      {
        name: "all",
        id: "all",
        text: i18n.t("pages.taxons.user_permission.enable_for_permit_list.items.all"),
        label: i18n.t("pages.taxons.user_permission.enable_for_permit_list.items.all")
      },
      {
        name: "only_by_team",
        id: "only_by_team",
        text: i18n.t("pages.taxons.user_permission.enable_for_permit_list.items.only_by_team"),
        label: i18n.t("pages.taxons.user_permission.enable_for_permit_list.items.only_by_team")
      },
      {
        name: "only_by_me",
        id: "only_by_me",
        text: i18n.t("pages.taxons.user_permission.enable_for_permit_list.items.only_by_me"),
        label: i18n.t("pages.taxons.user_permission.enable_for_permit_list.items.only_by_me")
      }
    ],
    accounting_payment_term_has_discount_list: [
      {
        name: "none",
        id: "none",
        text: i18n.t("pages.taxons.accountingPaymentTerm.has_discount_list.none"),
        label: i18n.t("pages.taxons.accountingPaymentTerm.has_discount_list.none")
      },
      {
        name: "early_payment",
        id: "early_payment",
        text: i18n.t("pages.taxons.accountingPaymentTerm.has_discount_list.early_payment"),
        label: i18n.t("pages.taxons.accountingPaymentTerm.has_discount_list.early_payment")
      }
    ],
    require_payment_before_ship_list : [
      {
        id: "none",
        label: i18n.t("pages.taxons.accountingPaymentTerm.require_payment_before_ship_list.none")
      },
      {
        id: "full_payment",
        label: i18n.t("pages.taxons.accountingPaymentTerm.require_payment_before_ship_list.full_payment")
      },
    ],
    activeFieldType: "",
    permissionKey: "",
    taxonList: [],
    expiryDate: new Date()
  },
  mutations: {
    setActiveTaxonType(state, activeTaxonType) {
      let index = state.fieldTypes.indexOf(activeTaxonType);
      if (index > -1) {
        state.activeTaxonType = state.taxonType[index];
      }
    },
    setTaxons(state, taxons) {
      state.taxonList = taxons;
    },
    setExpiryDate: state => {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      state.expiryDate = date;
    }
  },
  actions: {
    init({ state, commit, getters }) {
      if (getters.isExpired || forceToRefresh) {
        params.securedAxios.get(state.endpoint, { params: { page_size: 999999 } }).then(taxons => {
          commit("setTaxons", taxons?.data?.taxons || []);
          commit("setExpiryDate");
        });
      }
    }
  },
  getters: {
    taxonTypes(state) {
      const clientName = store.getters["activeUser"]?.client?.name;
      return state.taxonTypes.filter(t => ["puresol", clientName].indexOf(t.belong_to) > -1).sort((a, b) => ("" + a.text).localeCompare(b.text));
    },
    isExpired: state => new Date(state.expiryDate) < new Date(),
    availableFor: state => state.availableFor,
    availableForTrade: state => state.availableForTrade,
    availableForPlatform: state => state.availableForPlatform,
    paymentProviderType: state => state.paymentProviderType,
    puresolPaymentProvider: state => state.puresol_payment_provider,
    termLengthType: state => state.term_length_type,
    applicable_to: state => state.applicable_to,
    beforeAfter: state => state.before_after,
    termStarter: state => state.term_starter,
    accounting_gl_account_type: state => state.accounting_gl_account_type,
    type_for: state => state.type_for,
    enable_for_permit_list: state => state.enable_for_permit_list,
    accountingPaymentTermHasDiscountOptions: state => state.accounting_payment_term_has_discount_list,
    accounting_payment_term_require_payment_before_list : state => state.require_payment_before_ship_list
  }
};
