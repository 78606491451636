<script>
import VueTreeselect from '@riophae/vue-treeselect'
import { isNull } from "lodash";

export default {
  name: "PTreeselect",
  extends: VueTreeselect,
  props: {
    state: {
      type: [Boolean, null],
      default: null
    }
  },
  watch: {
    state(v) {
      this.$el.querySelector('.vue-treeselect__control').classList.remove('is-valid', 'is-invalid')
      if(!isNull(v)) {
        this.$el.querySelector('.vue-treeselect__control').classList.add(v ? 'is-valid' : 'is-invalid')
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .vue-treeselect__control {
  &.is-valid {
    border-color: #28a745 !important;
  }
  &.is-invalid {
    border-color: #dc3545 !important;
  }
}
</style>
