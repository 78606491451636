<script>
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import moment from 'moment'

export default {
  components: {
    DatePicker
  },
  props: {
    value: {
      required: true
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  }
}
</script>

<template>
  <date-picker v-model="model" v-bind="$attrs" v-on="$attrs.on" />
</template>
