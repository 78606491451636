<template>
  <div class="d-inline-block" v-if="!showSaveAndClose">
    <b-button v-if="!useShortKey" :size="size" :block="block" class="default" :type="type" :variant="variant" :disabled="disabled">
      <i class="fa fa-check mr-1"></i>
      <span>{{ buttonText }}</span>
    </b-button>
    <b-button
      v-else
      :size="size"
      :block="block"
      class="default"
      :type="type"
      :variant="variant"
      :disabled="disabled"
      v-shortkey="{ mac: ['meta', 's'], win: ['ctrl', 's'] }"
      @shortkey="callback()"
    >
      <i class="fa fa-check mr-1"></i>
      <span>{{ buttonText }}</span>
    </b-button>
  </div>
  <div class="d-inline-block" v-else>
    <template v-if="!useShortKey">
      <b-dropdown
        no-caret
        class="default"
        split
        offset="0"
        size="sm"
        variant="primary"
        @click="$emit('save')"
      >
        <template #button-content>
          <span v-html="buttonText"></span>
        </template>
        <b-dropdown-item class="cursorPointer" @click="$emit('saveAndClose')">
          {{ $t('general.save_and_close') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <template v-else>
      <b-dropdown
        no-caret
        class="default"
        split
        offset="0"
        size="sm"
        variant="primary"
        @click="$emit('save')"
      >
        <template #button-content>
          <span v-html="buttonText" v-shortkey="{ mac: ['meta', 's'], win: ['ctrl', 's'] }" @shortkey="callback()"></span>
        </template>
        <b-dropdown-item class="cursorPointer" @click="$emit('saveAndClose')">
          {{ $t('general.save_and_close') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: "button"
    },
    variant: {
      type: String,
      required: false,
      default: "primary"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    text: {
      type: String,
      required: false,
      default: ""
    },
    size: {
      type: String,
      required: false,
      default: ""
    },
    block: {
      type: Boolean,
      required: false,
      default: false
    },
    callback: {
      type: Function,
      required: false,
      default: () => {}
    },
    useShortKey: {
      type: Boolean,
      required: false,
      default: false
    },
    showSaveAndClose: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonText() {
      return this.text == "" ? this.$t("controls.save_button") : this.text;
    }
  },
  methods: {
    test() {
      this.$emit('save')
      // this.$emit("save");
    }
  }
};
</script>
