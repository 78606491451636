<script>
export default {
  name: "p-switch",
  data: () => ({
    switchValue: false
  }),
  props: {
    value: {
      type: Boolean,
      required: true
    },
    width: {
      type: String,
      required: false,
      default: "55"
    },
    height: {
      type: String,
      required: false,
      default: "25"
    },
    checkedText: {
      type: String,
      required: false,
      default: "general.yes"
    },
    uncheckedText: {
      type: String,
      required: false,
      default: "general.no"
    },
    checkedBg: {
      type: String,
      required: false,
      default: "#28a745"
    },
    uncheckedBg: {
      type: String,
      required: false,
      default: "lightgrey"
    },
    disabled : {
      type : Boolean,
      required : false,
      default : false
    }
  },
  mounted() {
    this.switchValue = this.value;
  },
  watch: {
    value(value){
      this.switchValue = value;
    },
    switchValue(value) {
      this.$emit("input", value);
    }
  }
};
</script>
<template>
  <vue-toggle
    :disabled="disabled"
    :height="height"
    :width="width"
    :checkedText="$t(checkedText)"
    :uncheckedText="$t(uncheckedText)"
    :checkedBg="checkedBg"
    :uncheckedBg="uncheckedBg"
    :value="switchValue"
    @click="switchValue = !switchValue"
  />
</template>
