/* eslint-disable */
<template>
  <div class="h-100">
    <template v-if="$route.name !== 'PrepressJobListPage'">
      <router-view :key="$route.fullPath" />
    </template>
    <template v-else>
      <router-view />
    </template>
    <!-- Print Container -->
    <iframe v-show="false" id="printMe" v-html="print_body"></iframe>
    <!-- !Print Container -->
    <!-- Send Email Modal -->
    <mailing-modal :email_informations="email_informations" :show="send_email_modal_show"
                   @hideEvent="send_email_modal_show = false" />
    <template v-if="isLoading">
      <div class="loading-container">
        <div class="loading"></div>
      </div>
    </template>
    <div class="refresh-container" v-if="hashChanged && isProductionEnv">
      <div class="notification-header">
        <div class="text-center">
          <img class="m-0 p-0" height="20" src="/assets/img/puresol-logo-new.png" alt="">
        </div>
      </div>
      <div class="notification-body mt-3">
        <div class="notification-button">
          <p class="text-center font12" v-html="$t('notification.version.update_available')"></p>
          <p class="text-center"><span
            class="font10 text-danger font-weight-bold">{{ $t("notification.version.not_updating_warning") }}</span></p>
        </div>
        <div class="refresh-button text-center">
          <b-button class="default" block variant="light" size="sm" @click="reloadApp">{{ $t("general.update") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import printBus from "@/utils/printBus";
import { versionMixin } from "@/mixins/versionMixin";

export default {
  components: {
    MailingModal: () => import("@/components/Mailing")
    // VersionModal : () => import("@/components/Common/VersionModal")
  },
  mixins: [versionMixin],
  data() {
    return {
      send_email_modal_show: false,
      email_informations: null
    };
  },
  mounted() {
    this.seti18nConfiguration();
    if (location.href.indexOf("/hive/jobs/templates") === -1) {
      delete localStorage.keep_browser;
    }

    printBus.$on("printEvent", event => {
      this.renderTemplate(event.object_id, event.template_id, event.for, event.additional_params);
    });

    printBus.$on("emailEvent", event => {
      this.email_informations = event;
      this.send_email_modal_show = true;
    });
  }
};

if (!Event.prototype.hasOwnProperty("path")) {
  Object.defineProperty(Event.prototype, "path", {
    get() {
      return this.composedPath();
    }
  });
}
</script>
<style lang="scss">
.modal-backdrop {
  opacity: 0.5 !important;
}

.modal-dialog {
  box-shadow: none !important;
}

.tooltip-inner {
  width: auto !important;
}

.refresh-container {
  width: 16%;
  position: fixed;
  bottom: 10px;
  right: 15px;
  background-color: #fff;
  padding: 25px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
}

.close-refresh-modal {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
  cursor: pointer;
}

.refresh-button {
  cursor: pointer;
}
</style>
