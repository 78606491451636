<template>
  <span>
    <span class="printable_buttons">
      <b-dropdown
        v-b-tooltip="{ title: defaultTemplate !== null ? defaultTemplate.name : '', placement: tooltip_placement, disabled: tooltip_disabled }"
        @click="printTemplate(defaultTemplate.id)"
        split
        class="default"
        offset="40"
        size="sm"
        :no-caret="linkButton"
        :variant="variantSelector"
        v-if="templates_ !== null && templates_.length > 1"
      >
        <template slot="button-content">
          <i v-if="emailable" class="fa fa-envelope"></i>
          <i v-else class="simple-icon-printer font-weight-bold"></i>
          <span v-if="includeTitle" class="ml-1">{{ buttonTitle }}</span>
        </template>

        <b-dropdown-item v-for="template_item in templates_" :key="template_item.id"
                         @click="printTemplate(template_item.id)">
          <i v-if="emailable" class="fa fa-envelope"></i>
          <i v-else class="simple-icon-printer font-weight-bold"></i>
          {{ template_item.name }}
        </b-dropdown-item>
      </b-dropdown>
      <b-button
        v-else-if="defaultTemplate !== null && !linkButton"
        v-b-tooltip="{ title: defaultTemplate.name, placement: tooltip_placement, disabled: tooltip_disabled }"
        class="default"
        size="sm"
        :variant="variantSelector"
        @click="printTemplate(defaultTemplate.id)"
      >
        <i v-if="emailable" class="fa fa-envelope"></i>
        <i v-else class="simple-icon-printer font-weight-bold"></i>
        <span v-if="includeTitle" class="ml-1">{{ buttonTitle }}</span>
      </b-button>

      <b-link
        v-else-if="defaultTemplate !== null && linkButton"
        v-b-tooltip="{ title: defaultTemplate.name, placement: tooltip_placement, disabled: tooltip_disabled }"
        class="print-btn"
        size="sm"
        :variant="variantSelector"
        @click="printTemplate(defaultTemplate.id)"
      >
        <i v-if="emailable" class="fa fa-envelope"></i>
        <i v-else class="simple-icon-printer"></i>
        <span v-if="includeTitle" class="ml-1">{{ buttonTitle }}</span>
      </b-link>
    </span>
  </span>
</template>
<script>
import { isObject, isArray } from "lodash";
import printBus from "@/utils/printBus";

export default {
  props: {
    // Will Remove...
    templates: {
      type: [Array, null],
      required: false,
      default: null
    },
    // Will Remove...
    default_template: {
      type: [String, null],
      required: false,
      default: null
    },
    printable: {
      type: Boolean,
      required: false,
      default: true
    },
    emailable: {
      type: Boolean,
      required: false,
      default: false
    },
    object_type: {
      type: String,
      required: false,
      default: null
    },
    objectIds: {
      type: [Array, null],
      required: false,
      default: () => ([])
    },
    object_id: {
      type: [String, null],
      required: true
    },
    document_id: {
      type: [String, null],
      required: false
    },
    email_informations: {
      type: Object,
      required: false
    },
    variant: {
      type: String,
      required: false
    },
    includeTitle: {
      type: Boolean,
      required: false
    },
    linkButton: {
      type: Boolean,
      required: false,
      default: false
    },
    print_title: {
      type: String,
      required: false,
      default: "PureSOL"
    },
    additional_params: {
      type: Object,
      required: false
    },
    connect_to: {
      type: String,
      default: ""
    },
    tooltip_placement: {
      type: String,
      required: false,
      default: "top"
    },
    tooltip_disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    byPassObjectId : {
      type : Boolean,
      required : false,
      default : false
    }
  },
  data() {
    return {
      templates_: []
    };
  },
  mounted() {
    if (localStorage.print_templates) {
      try {
        const print_templates = JSON.parse(localStorage?.print_templates);
        if (
          isObject(print_templates) &&
          new Date() < new Date(print_templates?.expire) &&
          isArray(print_templates?.templates) &&
          print_templates?.templates.length > 0
        ) {
          this.templates_ =
            print_templates.templates
              .filter(t => t.connect_to == this.connect_to && t[this.is_template_printable_or_emailable] === true)
              ?.map(c => {
                return {
                  id: c.id,
                  connect_to: c.connect_to,
                  name: c.name,
                  emailable: c.emailable,
                  printable: c.printable,
                  primary: c.primary,
                  email_subject: c.email_subject
                };
              }) || [];
        } else {
          this.fetchTemplates();
        }
      } catch (error) {
        this.fetchTemplates();
      }
    } else {
      this.fetchTemplates();
    }
  },
  methods: {
    printTemplate(template_id) {
      if (template_id !== "" && template_id !== null) {
        if (this.emailable) {
          let template = this.templates_.find(t => t.id === template_id);
          let emailData = { document_id: this.document_id, object_id: this.object_id, template_id, for: "email" };
          if (this.object_type === "logistic") {
            emailData = { object_list : this.objectIds, document_id: this.document_id, template_id, for: "email" };
          }
          if (isObject(this.additional_params)) {
            emailData = {
              ...emailData,
              additional_params: this.additional_params
            };
          }
          if (isObject(this.email_informations)) {
            emailData = {
              ...emailData, ...this.email_informations,
              mail_subject: template.email_subject || this.email_informations.mail_subject
            };
          }
          printBus.$emit("emailEvent", emailData);
        } else {
          this.$nextTick(() => {
            localStorage.print_title = this.print_title;
            let printData = { object_id: this.object_id, template_id, for: "print" };

            if (isObject(this.additional_params)) {
              printData = {
                ...printData,
                additional_params: this.additional_params
              };
            }

            if (this.object_type === "logistic"){
              printData = {
                ...printData,
                additional_params: {
                  object_list: this.objectIds
                }
              };
            }

            if(this.object_type === "inventory_product_var" && this.objectIds.length > 0 && this.byPassObjectId) {
              printData = {
                ...printData,
                additional_params: {
                  object_list: this.objectIds
                }
              };
            }

            if(this.byPassObjectId){
              delete printData.object_id
            }

            printBus.$emit("printEvent", printData);
          });
        }
      }
    },
    fetchTemplates() {
      this.getCryptedData("print_templates").then(items => {
        this.templates_ =
          items.templates
            .filter(t => t.connect_to === this.connect_to && t[this.is_template_printable_or_emailable] === true)
            ?.map(c => {
              return {
                id: c.id,
                connect_to: c.connect_to,
                name: c.name,
                emailable: c.emailable,
                printable: c.printable,
                primary: c.primary,
                email_subject: c.email_subject
              };
            }) || [];
      });
    }
  },
  computed: {
    is_template_printable_or_emailable() {
      return this.emailable === true ? "emailable" : "printable";
    },
    buttonTitle() {
      if (this.emailable) {
        return this.$t("general.send_email");
      }
      return this.$t("general.print");
    },
    variantSelector() {
      if (this.linkButton) {
        return "link";
      }
      if (this.variant) {
        return this.variant;
      }
      return this.emailable ? "outline-primary" : "outline-secondary";
    },
    filteredTemplates() {
      return this.templates_.filter(t => !t?.primary) || null;
    },
    defaultTemplate() {
      if (this.templates_?.length === 1) {
        return this.templates_[0] || null;
      }
      return this.templates_.find(t => t?.primary) || this.templates_[0] || null;
    }
  }
};
</script>
<style>
.printable_buttons .b-dropdown > button.btn-link:first-child {
  /* padding: 0px !important; */
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
