const routes = [
  {
    name: "WebMastersListPage",
    path: "/webmasters",
    meta: { listPage: "webmasters", listPageTitleKey: "webmasters" },
    component: () => import("@/views/app/pages/webmasters/list")
  },
  {
    name: "WebMastersNewPage",
    path: "/webmasters/new",
    component: () => import("@/views/app/pages/webmasters/new")
  },
  {
    name: "WebMastersUpdatePage",
    path: "/webmasters/:id",
    component: () => import("@/views/app/pages/webmasters/update")
  }
];

export default routes;
