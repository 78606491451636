import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/clients/",
    key: "client",
    fetchKey: "clients", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: "",
    sold_as_one_pricing: [
      {
        name: "per_unit",
        text: i18n.t("pages.clients.properties.inventory_settings.product.sold_as_one_pricing-items.per_unit")
      },
      {
        name: "per_bundle",
        text: i18n.t("pages.clients.properties.inventory_settings.product.sold_as_one_pricing-items.per_bundle")
      }
    ],
    measurements: [
      {
        name: "area",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.area"),
        units: ["km2", "m2", "acre", "sqmi", "cm2", "sqft", "sqin", "sqyd", "mm2"]
      },
      {
        name: "height",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.height"),
        units: ["ft", "mi", "m", "mm", "in", "cm"]
      },
      {
        name: "width",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.width"),
        units: ["ft", "mi", "m", "mm", "in", "cm"]
      },
      {
        name: "length",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.length"),
        units: ["ft", "mi", "m", "mm", "in", "cm"]
      },
      {
        name: "diameter",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.diameter"),
        units: ["ft", "m", "mm", "in", "cm"]
      },
      {
        name: "core_diameter",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.core_diameter"),
        units: ["mm", "in", "cm"]
      },
      {
        name: "weight",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.weight"),
        units: ["kg", "g", "ston", "lb", "mton"]
      },
      {
        name: "basis_weight",
        text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.basis_weight"),
        units: ["oz", "gsm", "lbl"]
      }
    ],
    activeMeasurement: [],
    filter_items: {
      fieldTypes: [
        {
          name: "field",
          text: i18n.t("pages.clients.properties.filter_items_attributes.field_types.field")
        },
        {
          name: "measurement",
          text: i18n.t("pages.clients.properties.filter_items_attributes.field_types.measurement")
        },
        {
          name: "custom_field",
          text: i18n.t("pages.clients.properties.filter_items_attributes.field_types.custom_field")
        }
      ],
      filterTypes: [
        {
          name: "equal",
          text: i18n.t("pages.clients.properties.filter_items_attributes.filter_types.equal")
        },
        {
          name: "range",
          text: i18n.t("pages.clients.properties.filter_items_attributes.filter_types.range")
        },
        {
          name: "includes",
          text: i18n.t("pages.clients.properties.filter_items_attributes.filter_types.includes")
        },
        {
          name: "checkbox",
          text: i18n.t("pages.clients.properties.filter_items_attributes.filter_types.checkbox")
        }
      ],
      placements: [
        {
          name: "basic",
          text: i18n.t("pages.clients.properties.filter_items_attributes.placements.basic")
        },
        {
          name: "advanced",
          text: i18n.t("pages.clients.properties.filter_items_attributes.placements.advanced")
        }
      ],
      measurements: [
        {
          name: "area",
          text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.area")
        },
        {
          name: "height",
          text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.height")
        },
        {
          name: "width",
          text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.width")
        },
        {
          name: "length",
          text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.length")
        },
        {
          name: "diameter",
          text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.diameter")
        },
        {
          name: "core_diameter",
          text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.core_diameter")
        },
        {
          name: "weight",
          text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.weight")
        },
        {
          name: "basis_weight",
          text: i18n.t("pages.clients.properties.filter_items_attributes.measurements.basis_weight")
        }
      ],
      fieldTypeList: [
        {
          name: "status",
          text: i18n.t("pages.clients.properties.filter_items_attributes.field_type_list.status")
        },
        {
          name: "type",
          text: i18n.t("pages.clients.properties.filter_items_attributes.field_type_list.type")
        },
        {
          name: "text",
          text: i18n.t("pages.clients.properties.filter_items_attributes.field_type_list.text")
        },
        {
          name: "location",
          text: i18n.t("pages.clients.properties.filter_items_attributes.field_type_list.location")
        },
        {
          name: "is_reserved",
          text: i18n.t("pages.clients.properties.filter_items_attributes.field_type_list.is_reserved")
        },
        {
          name: "reserved_contact",
          text: i18n.t("pages.clients.properties.filter_items_attributes.field_type_list.reserved_contact")
        },
        {
          name: "reserved_invoice",
          text: i18n.t("pages.clients.properties.filter_items_attributes.field_type_list.reserved_invoice")
        },
        {
          name: "reserved_shipment",
          text: i18n.t("pages.clients.properties.filter_items_attributes.field_type_list.reserved_shipment")
        },
        {
          name: "tpa_source_id",
          text: i18n.t("pages.clients.properties.filter_items_attributes.field_type_list.tpa_source_id")
        },
        {
          name: "tpa_source_reference_id",
          text: i18n.t("pages.clients.properties.filter_items_attributes.field_type_list.tpa_source_reference_id")
        }
      ],
      customFieldTypes: [],
      customFields: [],
      activeCustomFieldType: {},
      activeCustomField: {},
      activeFieldType: {},
      activePlacement: {},
      activeFilterType: {},
      activeMeasurement: {},
      activeFieldTypeListItem: {}
    },
    invoice_settings: {
      billingAddressList: [
        {
          name: "required",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.required")
        },
        {
          name: "optional",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.optional")
        },
        {
          name: "none",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.none")
        }
      ],
      shippingAddressList: [
        {
          name: "required",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.required")
        },
        {
          name: "optional",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.optional")
        },
        {
          name: "none",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.none")
        }
      ],
      billingLocationList: [
        {
          name: "required",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.required")
        },
        {
          name: "optional",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.optional")
        },
        {
          name: "none",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.none")
        }
      ],
      shippingLocationList: [
        {
          name: "required",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.required")
        },
        {
          name: "optional",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.optional")
        },
        {
          name: "none",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.none")
        }
      ],
      shipmentsList: [
        {
          name: "required",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.required")
        },
        {
          name: "optional",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.optional")
        },
        {
          name: "none",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.none")
        }
      ],
      transfersList: [
        {
          name: "required",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.required")
        },
        {
          name: "optional",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.optional")
        },
        {
          name: "none",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.none")
        }
      ],
      activeBillingAddress: {},
      activeShippingAddress: {},
      activeBillingLocation: {},
      activeShippingLocation: {},
      activeShipment: {},
      activeTransfer: {}
    },
    catalog: {
      inventorySettingsFieldNameList: [
        {
          name: "id",
          text: i18n.t("pages.clients.properties.catalog.field_names.id")
        },
        {
          name: "product_type",
          text: i18n.t("pages.clients.properties.catalog.field_names.product_type")
        },
        {
          name: "quantity",
          text: i18n.t("pages.clients.properties.catalog.field_names.quantity")
        },
        {
          name: "title",
          text: i18n.t("pages.clients.properties.catalog.field_names.title")
        },
        {
          name: "price",
          text: i18n.t("pages.clients.properties.catalog.field_names.price")
        },
        {
          name: "status",
          text: i18n.t("pages.clients.properties.catalog.field_names.status")
        },
        {
          name: "sku",
          text: i18n.t("pages.clients.properties.catalog.field_names.sku")
        },
        {
          name: "barcode",
          text: i18n.t("pages.clients.properties.catalog.field_names.barcode")
        }
      ],
      logisticSettingsFieldNameList: [
        {
          name: "id",
          text: i18n.t("pages.clients.properties.catalog.field_names.id")
        },
        {
          name: "contact",
          text: i18n.t("pages.clients.properties.catalog.field_names.contact")
        },
        {
          name: "status",
          text: i18n.t("pages.clients.properties.catalog.field_names.status")
        },
        {
          name: "shipped_date",
          text: i18n.t("pages.clients.properties.catalog.field_names.shipped_date")
        },
        {
          name: "location",
          text: i18n.t("pages.clients.properties.catalog.field_names.location")
        },
        {
          name: "location_to",
          text: i18n.t("pages.clients.properties.catalog.field_names.location_to")
        },
        {
          name: "invoice_id",
          text: i18n.t("pages.clients.properties.catalog.field_names.invoice_id")
        }
      ],
      accountingSettingsFieldNameList: [
        {
          name: "id",
          text: i18n.t("pages.clients.properties.catalog.field_names.id")
        },
        {
          name: "invoice_date",
          text: i18n.t("pages.clients.properties.catalog.field_names.invoice_date")
        },
        {
          name: "status",
          text: i18n.t("pages.clients.properties.catalog.field_names.status")
        },
        {
          name: "price",
          text: i18n.t("pages.clients.properties.catalog.field_names.price")
        },
        {
          name: "status",
          text: i18n.t("pages.clients.properties.catalog.field_names.status")
        },
        {
          name: "status_payment",
          text: i18n.t("pages.clients.properties.catalog.field_names.status_payment")
        }
      ],
      linkList: [
        {
          name: "none",
          text: i18n.t("pages.clients.properties.catalog.link_list.none")
        },
        {
          name: "edit",
          text: i18n.t("pages.clients.properties.catalog.link_list.edit")
        },
        {
          name: "to_object",
          text: i18n.t("pages.clients.properties.catalog.link_list.to_object")
        }
      ],
      linkTargetList: [
        {
          name: "none",
          text: i18n.t("pages.clients.properties.catalog.link_target_list.none")
        },
        {
          name: "_blank",
          text: i18n.t("pages.clients.properties.catalog.link_target_list._blank")
        }
      ],
      customFieldTypes: [],
      customFields: [],
      inventorySettingsFieldTypeList: [
        {
          name: "static_field",
          text: i18n.t("pages.clients.properties.catalog.field_types.static_field")
        },
        {
          name: "custom_field",
          text: i18n.t("pages.clients.properties.catalog.field_types.custom_field")
        },
        {
          name: "measurement",
          text: i18n.t("pages.clients.properties.catalog.field_types.measurement")
        },
        {
          name: "price",
          text: i18n.t("pages.clients.properties.catalog.field_types.price")
        }
      ],
      logisticSettingsFieldTypeList: [
        {
          name: "static_field",
          text: i18n.t("pages.clients.properties.catalog.field_types.static_field")
        },
        {
          name: "custom_field",
          text: i18n.t("pages.clients.properties.catalog.field_types.custom_field")
        },
        {
          name: "measurement",
          text: i18n.t("pages.clients.properties.catalog.field_types.measurement")
        },
        {
          name: "price",
          text: i18n.t("pages.clients.properties.catalog.field_types.price")
        }
      ],
      accountingSettingsFieldTypeList: [
        {
          name: "static_field",
          text: i18n.t("pages.clients.properties.catalog.field_types.static_field")
        },
        {
          name: "custom_field",
          text: i18n.t("pages.clients.properties.catalog.field_types.custom_field")
        },
        {
          name: "price",
          text: i18n.t("pages.clients.properties.catalog.field_types.price")
        }
      ],
      activeCustomFieldType: {},
      activeCustomField: {},
      activeInventorySettingsFieldName: {},
      activeLogisticSettingsFieldName: {},
      activeAccountingSettingsFieldName: {},
      activeInventorySettingsFieldType: {},
      activeLogisticSettingsFieldType: {},
      activeAccountingSettingsFieldType: {},
      activeLink: {},
      activeLinkTarget: {}
    },
    invoice_proforma_invoice_options: {
      invoiceList: [
        {
          name: "required",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.required")
        },
        {
          name: "optional",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.optional")
        },
        {
          name: "none",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.none")
        }
      ],
      proformaInvoiceList: [
        {
          name: "required",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.required")
        },
        {
          name: "optional",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.optional")
        },
        {
          name: "none",
          text: i18n.t("pages.clients.properties.accounting_settings.invoice_settings_items.options.none")
        }
      ],
      activeInvoiceProformaInvoice: {},
      activeProformaInvoiceInvoice: {},
      activePurchaseOrderInvoice: {},
      activeBillProformaInvoice: {}
    },

    activeSoldAsOnePricing: {}
  },
  mutations: {
    // Global V-Select ActiveItem Selector
    setActiveItem(state, { active, sub_state_object_name, state_property, activeObject }) {
      if (sub_state_object_name) {
        let index = state[sub_state_object_name][state_property].findIndex(item => {
          return item.name == active;
        });
        if (index > -1) {
          state[sub_state_object_name][activeObject] = state[sub_state_object_name][state_property][index];
        }
      } else {
        let index = state[state_property].findIndex(item => {
          return item.name == active;
        });
        if (index > -1) {
          state[activeObject] = state[state_property][index];
        }
      }
    }
  },
  actions: {},
  getters: {}
};
