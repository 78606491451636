import { i18n } from '@/utils/i18n'
const state = () => ({
  processes: [],
  printServerToken: null,
  isTokenRequested: false
})

const getters = {
  backendProcesses: state => state.processes.filter(process => process.type === 'switch_backend' && process.enabled),
  layoutProcesses: state => state.processes.filter(process => process.type === 'switch_layout' && process.enabled),
  oneUpProcesses: state => state.processes.filter(process => process.type === 'switch_1up' && process.enabled),
  allProcesses: state => state.processes?.filter(p => p.enabled),
  oneUpProcessData: state => state.processes.filter(process => process.type === 'switch_1up' && process.enabled),
  printServerToken: state => state.printServerToken,
  isTokenRequested: state => state.isTokenRequested
}

const mutations = {
  SET_PROCESSES (state, payload) {
    state.processes = payload
  },
  SET_PRINT_SERVER_TOKEN (state, payload) {
    state.printServerToken = payload
  },
  SET_IS_TOKEN_REQUESTED (state, payload) {
    state.isTokenRequested = payload
  }
}

const actions = {
  getAllProcesses ({ commit, dispatch, rootGetters }) {
    if (rootGetters.activeUserIsCustomer) {
      return commit('SET_PROCESSES', rootGetters.activeUser?.processes || [])
    }
    return dispatch('fetch', {
      endpoint: '/maestro/processes/get_client_process/',
      params: {
        params: {
          lng: i18n.locale
        }
      }
    })
      .then(data => {
        commit('SET_PROCESSES', data.processes)
      })
  }
}
export default {
  state, getters, mutations, actions
}
