const routes = [
  {
    name: "ClientsListPage",
    path: "/clients",
    meta: { listPage: "clients", listPageTitleKey: "clients" },
    component: () => import("@/views/app/pages/clients/list")
  },
  {
    name: "ClientsNewPage",
    path: "/clients/new",
    component: () => import("@/views/app/pages/clients/new")
  },
  {
    name: "ClientsUpdatePage",
    path: "/clients/:id",
    component: () => import("@/views/app/pages/clients/update")
  },
  {
    path: "/clients/properties/:id",
    component: () => import("@/views/app/pages/clients/properties")
  }
];

export default routes;
