export default {
  namespaced: true,
  state: {
    endpoint: "/karavan/orders",
    key: "karavan_order",
    fetchKey: "invoices",
    permissionKey: ""
  },
  actions: {},
  mutations: {},
  getters: {}
};
