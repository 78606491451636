const routes = [
  {
    name : "TPAListPage",
    path: "/tpaapps",
    // listPage (For Listing Component)
    // listPageTitleKey (For ListPage <h1> Title)
    meta: { listPage: "tpa", listPageTitleKey: "tpa" },
    component: () => import("@/views/app/pages/tpaapps/list")
  },
  {
    name : "TPANewPage",
    path: "/tpaapps/new",
    component: () => import("@/views/app/pages/tpaapps/new")
  },
  {
    name : "TPAUpdatePage",
    path: "/tpaapps/:id",
    component: () => import("@/views/app/pages/tpaapps/update")
  }
];

export default routes;
