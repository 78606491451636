<template>
  <div>
    <vue-date-picker
      :clear-button="show_clear_button"
      :disabled="disabled"
      :value="computedDate"
      @input="dateChangeEvent($event)"
      :bootstrap-styling="true"
      input-class="form-control bg-white"
      calendar-class="date_picker_calendar_container"
      :disabledDates="disabledDates"
      :placeholder="placeholder"
      ref="date_picker"
    />
  </div>
  <!-- $emit('focusout') -->
</template>
<script>
import VueDatePicker from "vuejs-datepicker";

import moment from "moment";

export default {
  components: {
    VueDatePicker
  },
  props: {
    value: {
      require: true
    },
    disabledDates: {
      require: false,
      type: Object
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false
    },
    placeholder: {
      type: String,
      require: false
    },
    show_clear_button: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      date_picker_value: null,
      disabledTo: {}
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs?.date_picker?.$el.addEventListener("click", () => {
        this.$emit("focus");
      });

      this.$refs?.date_picker?.$el.addEventListener("focusout", () => {
        this.$emit("focusout");
      });
    });
  },
  methods: {
    dateChangeEvent(date) {
      if (date === null) {
        this.$emit("input", null);
      } else if (this.value !== null) {
        this.$emit("input", moment(date).format("YYYY-MM-DDTHH:mm:ss"));
      } else {
        this.$emit(
          "input",
          moment(date)
            .tz("America/New_York")
            .format("YYYY-MM-DDTHH:mm:ss")
        );
      }
    }
  },
  computed: {
    computedDate() {
      return this.value !== null ? moment(this.value).format("YYYY-MM-DDTHH:mm:ss") : null;
    }
  }
};
</script>
<style>
.vdp-datepicker .input-group-text {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
