import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/custom/calculators/",
    key: "custom_calculator",
    fetchKey: "custom_calculators",
    resultTypeList: [
      {
        name: "number",
        text: i18n.t("pages.custom_calculators.resultTypeList.number")
      },
      {
        name: "currency",
        text: i18n.t("pages.custom_calculators.resultTypeList.currency")
      },
      {
        name: "measurement",
        text: i18n.t("pages.custom_calculators.resultTypeList.measurement")
      }
    ],
    typeList: [
      {
        name: "value",
        text: i18n.t("pages.custom_calculators.typeList.value")
      },
      {
        name: "function",
        text: i18n.t("pages.custom_calculators.typeList.function")
      },
      {
        name: "calculator",
        text: i18n.t("pages.custom_calculators.typeList.calculator")
      }
    ],
    valueTypeList: [
      {
        name: "custom_field",
        text: i18n.t("pages.custom_calculators.valueTypeList.custom_field")
      },
      {
        name: "measurement",
        text: i18n.t("pages.custom_calculators.valueTypeList.measurement")
      },
      {
        name: "inventory_product_price",
        text: i18n.t("pages.custom_calculators.valueTypeList.inventory_product_price")
      }
    ],
    functionTypeList: [
      {
        name: "add",
        text: i18n.t("pages.custom_calculators.functionTypeList.add")
      },
      {
        name: "subtract",
        text: i18n.t("pages.custom_calculators.functionTypeList.subtract")
      },
      {
        name: "multiply",
        text: i18n.t("pages.custom_calculators.functionTypeList.multiply")
      },
      {
        name: "divide",
        text: i18n.t("pages.custom_calculators.functionTypeList.divide")
      },
      {
        name: "parentheses_open",
        text: i18n.t("pages.custom_calculators.functionTypeList.parentheses_open")
      },
      {
        name: "parentheses_close",
        text: i18n.t("pages.custom_calculators.functionTypeList.parentheses_close")
      },
      {
        name: "round_up",
        text: i18n.t("pages.custom_calculators.functionTypeList.round_up")
      },
      {
        name: "round_down",
        text: i18n.t("pages.custom_calculators.functionTypeList.round_down")
      }
    ],
    valueSourceList: [
      {
        name: "inventory_product",
        text: i18n.t("pages.custom_calculators.valueSourceList.inventory_product")
      },
      {
        name: "hive_job",
        text: i18n.t("pages.custom_calculators.valueSourceList.hive_job")
      },
      {
        name: "hive_job_field",
        text: i18n.t("pages.custom_calculators.valueSourceList.hive_job_field")
      }
    ],

    measurementTypeList: ["ft", "in", "cm"],
    permissionKey: ""
  },
  mutations: {},
  actions: {},
  getters: {
    resultTypeList: state => state.resultTypeList,
    typeList: state => state.typeList,
    valueTypeList: state => state.valueTypeList,
    valueSourceList: state => state.valueSourceList,
    functionTypeList: state => state.functionTypeList,
    measurementTypeList: state => state.measurementTypeList
  }
};
